<section class="login-sec d-flex align-items-center justify-content-center p-4">
    <div class="content-box">
        <button _ngcontent-rqr-c132="" class="backbtn" tabindex="0" [routerLink]="['/studio-forgot-password']" ><svg _ngcontent-rqr-c132="" width="34" height="24" viewBox="0 0 34 24" fill="none"><path _ngcontent-rqr-c132="" d="M32 12L3.42857 12" stroke="white" stroke-width="3" stroke-linecap="round"></path><path _ngcontent-rqr-c132="" d="M10.5714 2L2 12L10.5714 22" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"></path></svg></button>
       <div class=" mx-auto text-center">
        <div class="img-wrap mb-5">
            <img src="assets/images/verify-email-img.svg">
        </div>
        <h4 class="fw-medium mb-3">Check Your Email</h4>
        <p class="fw-light color_grey mb-4 pb-3">Please enter the code we sent to <span class="color_purple fw-medium"> {{email}} </span> to help us to protect your account</p>
       
        <form [formGroup]="verifyForm">
            <div class="otp-outer mb-5">
                <ngx-otp-input [config]="otpInputConfig" formContolName="otp"(otpChange)="handeOtpChange($event)" (fill)="handleFillEvent($event)"></ngx-otp-input>
            </div>
            <p class="text-center mb-3 pb-1 lh1 pt-3"><span class="color_red resend-code fw-bold" role="button " (click)="resendCode()">Resend Code?</span></p>
            <button type="submit" class="btn w-100 purple_btn btn70 fw-bold" (click)="submit()" [disabled]="verifyForm.invalid" (keyup.enter)="submit()"> Submit code</button>
          
        </form>
    </div>
    </div>   


</section>



