<section class="login-sec d-flex align-items-center justify-content-center p-4">
     <form [formGroup]="forgotForm">
    <div class="content-box">
      <button class="backbtn" [routerLink]="['/user-login']"><svg width="34" height="24" viewBox="0 0 34 24" fill="none">
          <path d="M32 12L3.42857 12" stroke="white" stroke-width="3" stroke-linecap="round"/>
          <path d="M10.5714 2L2 12L10.5714 22" stroke="white" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          </button>
      <h2 class="ff_kalam font-normal text-white mb-4 pb-1">Forgot Password?</h2>
       <div class="mb-5">
         <label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Email address</label>
         <input type="email" formControlName="email" class="form-control fs18 cmn-input input_60 ff_Roboto"
         placeholder="Enter email address" (keydown)="checkEvent($event)">
         <small *ngIf="!forgotForm.controls?.['email']?.valid &&
         forgotForm.controls?.['email']?.dirty">
             <small
                 *ngIf="forgotForm.controls?.['email']?.hasError('invalidEmail') && forgotForm.controls?.['email']?.value"
                 class="error_message">Invalid email !
             </small>
             <small class="error_message"
                 *ngIf="forgotForm.controls?.['email']?.hasError('required')">This field is
                 Mandatory !
             </small>
         </small>
         <!-- <input type="email" class="form-control fs18 cmn-input input_60 ff_Roboto" placeholder="Enter email address"> -->
       </div>

       <!-- [routerLink]="['/studio-verify-otp']" -->
       <button type="submit" class="btn w-100 purple_btn btn70 fw-bold" (click)="forgotPassword()" [disabled]="forgotForm.invalid" (keyup.enter)="forgotPassword()">Send</button>
  </div>  
   </form>
</section>



