import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/common/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/message/messagehandling.service';
declare const $: any;

@Component({
  selector: 'app-studios',
  templateUrl: './studios.component.html',
  styleUrls: ['./studios.component.scss']
})
export class StudiosComponent implements OnInit {
  studioData: any = []
  imageUrl: any;
  constructor(private router: Router, private api: ApiService,private message:MessagehandlingService) { }

  ngOnInit(): void {
    $('.commonVideo_slider').slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 3000,
      dots: true,
      infinite: false,
      // centerMode: true,
      // variableWidth: true,
      prevArrow: $('.prev'),
      nextArrow: $('.next'),
      responsive: [
        {
          breakpoint: 1400,
          settings: {
            slidesToShow: 3,

          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });
    this.studios()
    this.imageUrl = this.api.licenceAndLogoUrl
  }

  // fav(id: any, isFav: any) {
  //   
  //   let fd = new FormData

  //   fd.append('CID', id)
  //   if (isFav == 1) {
  //     fd.append('status', "0")
  //     $(".likeContainer").toggleClass("heart_filled");
  //   }
  //   else {
  //     fd.append('status', "1")

  //     $(".likeContainer").removeClass("heart_filled");

  //   }
  //   this.api.addFavourite(fd).subscribe((res: any) => {
  //     this.message.sucessMessage(res.message, 1000)
  //     this.studios()



  //   })
  //   // $(".likeContainer").toggleClass("heart_filled");
  // }



  studios() {
    this.api.studios().subscribe((res: any) => {
      this.studioData = res.data
    })
  }

  studioDetail(id: any) {
    this.router.navigate([`/studio-details/${id}`])
  }
}
