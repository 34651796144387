<app-header></app-header>

<section class="breadcrumb-banner">
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item ps-0 active" [routerLink]="['/home']">Home</li>
        <li class="breadcrumb-item">Studios</li>
      </ol>
    </nav>
  </div>
</section>

<section class="studios-top sec_padding_bottom">
  <div class="container">
    <div class="row" *ngIf="studioData?.length!=0">
      <div class="col-md-4" *ngFor="let studio of studioData">
        <div class="studtio-itm active d-flex align-items-center justify-content-center p-2"
          (click)="studioDetail(studio.id)">
          <img class="fit-img" [src]="imageUrl +studio.logo">
        </div>
      </div>
    </div>
    <!-- no data found -->
    <div class="no_data_found text-center" *ngIf="studioData?.length==0">
      <h1>No Data</h1>
    </div>
  </div>
</section>

<!-- Studio info -->
<!-- <section class="studio-info pt-5 sec_padding_bottom">
  <div class="container">
    <button class="btn closebtn p-0 mb-5 shadow-none mx-auto d-block"><img src="assets/images/closebtn.svg"></button>

    <div class="row">
      <div class="col-md-3">
        <div class="studio-img">
          <img class="img-fluid" src="assets/images/studio1.png">
        </div>
      </div>
      <div class="col-md-9">
        <div class="text-sec pb-4">
          <h2 class="ff_kalam fw-normal mb-3 pb-1">GOATE Studios</h2>
          <p class="color_grey mb-4">GOATE is the Greatest of All Time Entertainment. The name says it all. Created by
            Ariel X in 2017 and managed by Ariel and her team, GOATE specializes in lesbian BDSM and sports-centric
            adult entertainment. With high production value for mixed wrestling matches and lesbian wrestling matches,
            GOATE has quickly become the go-to production company for anything fitness or sports related in the adult
            entertainment industry. Whether your fetish is FBB, muscle women, femdom, winner fucks loser style matches,
            workout fitness videos or lift and carry videos.
          </p>
          <p class="color_grey mb-4">GOATE has the best production value you will find. If Lesbian action is your thing,
            you will be able to find outstanding content with lesbian BDSM, bondage, strap-on fucking, tribadism,
            scissoring and pussy licking from the master of it all, Ariel X. Ariel has starred in lesbian videos since
            2003 and she continues to do so for GOATE as a submissive.</p>
        </div>
      </div>
    </div>

    <div class="relatedVideo_contaienr">
      <h4 class="fw-medium mb-2">GOATE Studios Clips</h4>

      <div class="commonVideo_container position-relative">
        <div class="commonVideo_slider">

          <div class="videoBox position-relative">
            <div class="videoCoverImg">
              <img class="img-fluid" src="assets/images/Vimg6.jpg" alt="">
            </div>
            <div class="absolute_box position-absolute h-100 w-100">
              <div class="likeContainer d-flex align-items-center justify-content-center ms-auto me-3 cursor_pointer"
                (click)="fav()">
                <svg xmlns="http://www.w3.org/2000/svg" width="26.927" height="24" viewBox="0 0 26.927 24">
                  <g id="Icon_feather-heart" data-name="Icon feather-heart" transform="translate(-0.327 -2.497)"
                    fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path
                      d="M23.485,6.265a6.033,6.033,0,0,0-8.535,0L13.788,7.428,12.625,6.265A6.035,6.035,0,1,0,4.091,14.8l1.163,1.163L13.788,24.5l8.535-8.535L23.485,14.8a6.033,6.033,0,0,0,0-8.535Z"
                      stroke="none" />
                    <path
                      d="M 19.218017578125 4.497261047363281 C 17.67348861694336 4.497261047363281 16.12896156311035 5.086641311645508 14.95075130462646 6.265401840209961 L 13.78795146942139 7.428211212158203 L 12.62515163421631 6.265401840209961 C 10.26840209960938 3.908658981323242 6.447360992431641 3.908660888671875 4.090621948242188 6.265401840209961 C 1.733880996704102 8.622150421142578 1.733880996704102 12.443190574646 4.090621948242188 14.79993057250977 L 13.78795146942139 24.49726104736328 L 23.48528099060059 14.79993057250977 C 25.84280204772949 12.44351100921631 25.84280204772949 8.621820449829102 23.48528099060059 6.265401840209961 C 22.30707168579102 5.086641311645508 20.76254463195801 4.497261047363281 19.218017578125 4.497261047363281 M 19.2180118560791 2.497261047363281 C 21.36453247070312 2.497261047363281 23.38237190246582 3.333351135253906 24.89982223510742 4.851520538330078 C 26.41733169555664 6.368310928344727 27.25342178344727 8.386150360107422 27.25342178344727 10.53266143798828 C 27.25342178344727 12.67918109893799 26.41733169555664 14.69702053070068 24.89916229248047 16.21446990966797 L 15.20216178894043 25.91147041320801 C 14.82709121704102 26.28655052185059 14.31838130950928 26.49726104736328 13.78795146942139 26.49726104736328 C 13.2575216293335 26.49726104736328 12.74881172180176 26.28655052185059 12.37374114990234 25.91147041320801 L 2.676410675048828 16.21414184570312 C -0.4563579559326172 13.08137130737305 -0.4563694000244141 7.983970642089844 2.676401138305664 4.851190567016602 C 4.193981170654297 3.333610534667969 6.211702346801758 2.497840881347656 8.357881546020508 2.497840881347656 C 10.38759994506836 2.497840881347656 12.30235290527344 3.245304107666016 13.78789329528809 4.610126495361328 C 15.27305603027344 3.245044708251953 17.18797302246094 2.497261047363281 19.2180118560791 2.497261047363281 Z"
                      stroke="none" fill="#fff" />
                  </g>
                </svg>
              </div>

              <div class="duration_box position-absolute">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                  <path
                    d="M6.99984 12.8332C10.2215 12.8332 12.8332 10.2215 12.8332 6.99984C12.8332 3.77818 10.2215 1.1665 6.99984 1.1665C3.77818 1.1665 1.1665 3.77818 1.1665 6.99984C1.1665 10.2215 3.77818 12.8332 6.99984 12.8332Z"
                    stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M9 9L7.39067 7.793C7.1406 7.60551 7.00008 7.35119 7 7.086V4" stroke="white"
                    stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <span class="small ms-1">1:15:30</span>
              </div>

              <div class="desBox d-flex position-absolute p-3 w-100">
                <div class="description">
                  <p class="small color_pink mb-1">Category One</p>
                  <h6 class="fs18 text-truncate mb-0 desHeading">Discipline for Mommy's Little Anal Slut</h6>
                </div>
                <div class="price_count text-end">
                  <p class="fs13 mb-1 opacity-50 text-uppercase">JUN 15, 2020</p>
                  <h6 class="mb-0 d-flex align-items-center justify-content-end"><i class="me-2"><img
                        src="assets/images/coin.svg" alt=""></i>
                    10</h6>
                </div>
              </div>
            </div>
          </div>


          <div class="videoBox position-relative">
            <div class="videoCoverImg">
              <img class="img-fluid" src="assets/images/Vimg7.jpg" alt="">
            </div>
            <div class="absolute_box position-absolute h-100 w-100">
              <div class="likeContainer d-flex align-items-center justify-content-center ms-auto me-3 cursor_pointer"
                (click)="fav()">
                <svg xmlns="http://www.w3.org/2000/svg" width="26.927" height="24" viewBox="0 0 26.927 24">
                  <g id="Icon_feather-heart" data-name="Icon feather-heart" transform="translate(-0.327 -2.497)"
                    fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path
                      d="M23.485,6.265a6.033,6.033,0,0,0-8.535,0L13.788,7.428,12.625,6.265A6.035,6.035,0,1,0,4.091,14.8l1.163,1.163L13.788,24.5l8.535-8.535L23.485,14.8a6.033,6.033,0,0,0,0-8.535Z"
                      stroke="none" />
                    <path
                      d="M 19.218017578125 4.497261047363281 C 17.67348861694336 4.497261047363281 16.12896156311035 5.086641311645508 14.95075130462646 6.265401840209961 L 13.78795146942139 7.428211212158203 L 12.62515163421631 6.265401840209961 C 10.26840209960938 3.908658981323242 6.447360992431641 3.908660888671875 4.090621948242188 6.265401840209961 C 1.733880996704102 8.622150421142578 1.733880996704102 12.443190574646 4.090621948242188 14.79993057250977 L 13.78795146942139 24.49726104736328 L 23.48528099060059 14.79993057250977 C 25.84280204772949 12.44351100921631 25.84280204772949 8.621820449829102 23.48528099060059 6.265401840209961 C 22.30707168579102 5.086641311645508 20.76254463195801 4.497261047363281 19.218017578125 4.497261047363281 M 19.2180118560791 2.497261047363281 C 21.36453247070312 2.497261047363281 23.38237190246582 3.333351135253906 24.89982223510742 4.851520538330078 C 26.41733169555664 6.368310928344727 27.25342178344727 8.386150360107422 27.25342178344727 10.53266143798828 C 27.25342178344727 12.67918109893799 26.41733169555664 14.69702053070068 24.89916229248047 16.21446990966797 L 15.20216178894043 25.91147041320801 C 14.82709121704102 26.28655052185059 14.31838130950928 26.49726104736328 13.78795146942139 26.49726104736328 C 13.2575216293335 26.49726104736328 12.74881172180176 26.28655052185059 12.37374114990234 25.91147041320801 L 2.676410675048828 16.21414184570312 C -0.4563579559326172 13.08137130737305 -0.4563694000244141 7.983970642089844 2.676401138305664 4.851190567016602 C 4.193981170654297 3.333610534667969 6.211702346801758 2.497840881347656 8.357881546020508 2.497840881347656 C 10.38759994506836 2.497840881347656 12.30235290527344 3.245304107666016 13.78789329528809 4.610126495361328 C 15.27305603027344 3.245044708251953 17.18797302246094 2.497261047363281 19.2180118560791 2.497261047363281 Z"
                      stroke="none" fill="#fff" />
                  </g>
                </svg>
              </div>

              <div class="duration_box position-absolute">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                  <path
                    d="M6.99984 12.8332C10.2215 12.8332 12.8332 10.2215 12.8332 6.99984C12.8332 3.77818 10.2215 1.1665 6.99984 1.1665C3.77818 1.1665 1.1665 3.77818 1.1665 6.99984C1.1665 10.2215 3.77818 12.8332 6.99984 12.8332Z"
                    stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M9 9L7.39067 7.793C7.1406 7.60551 7.00008 7.35119 7 7.086V4" stroke="white"
                    stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <span class="small ms-1">1:15:30</span>
              </div>

              <div class="desBox d-flex position-absolute p-3 w-100">
                <div class="description">
                  <p class="small color_pink mb-1">Category Two</p>
                  <h6 class="fs18 text-truncate mb-0 desHeading">Penelope Kay: Fucked in Tight Bondage</h6>
                </div>
                <div class="price_count text-end">
                  <p class="fs13 mb-1 opacity-50 text-uppercase">JUN 15, 2020</p>
                  <h6 class="mb-0 d-flex align-items-center justify-content-end"><i class="me-2"><img
                        src="assets/images/coin.svg" alt=""></i>
                    10</h6>
                </div>
              </div>
            </div>
          </div>


          <div class="videoBox position-relative">
            <div class="videoCoverImg">
              <img class="img-fluid" src="assets/images/Vimg8.jpg" alt="">
            </div>
            <div class="absolute_box position-absolute h-100 w-100">
              <div class="likeContainer d-flex align-items-center justify-content-center ms-auto me-3 cursor_pointer"
                (click)="fav()">
                <svg xmlns="http://www.w3.org/2000/svg" width="26.927" height="24" viewBox="0 0 26.927 24">
                  <g id="Icon_feather-heart" data-name="Icon feather-heart" transform="translate(-0.327 -2.497)"
                    fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path
                      d="M23.485,6.265a6.033,6.033,0,0,0-8.535,0L13.788,7.428,12.625,6.265A6.035,6.035,0,1,0,4.091,14.8l1.163,1.163L13.788,24.5l8.535-8.535L23.485,14.8a6.033,6.033,0,0,0,0-8.535Z"
                      stroke="none" />
                    <path
                      d="M 19.218017578125 4.497261047363281 C 17.67348861694336 4.497261047363281 16.12896156311035 5.086641311645508 14.95075130462646 6.265401840209961 L 13.78795146942139 7.428211212158203 L 12.62515163421631 6.265401840209961 C 10.26840209960938 3.908658981323242 6.447360992431641 3.908660888671875 4.090621948242188 6.265401840209961 C 1.733880996704102 8.622150421142578 1.733880996704102 12.443190574646 4.090621948242188 14.79993057250977 L 13.78795146942139 24.49726104736328 L 23.48528099060059 14.79993057250977 C 25.84280204772949 12.44351100921631 25.84280204772949 8.621820449829102 23.48528099060059 6.265401840209961 C 22.30707168579102 5.086641311645508 20.76254463195801 4.497261047363281 19.218017578125 4.497261047363281 M 19.2180118560791 2.497261047363281 C 21.36453247070312 2.497261047363281 23.38237190246582 3.333351135253906 24.89982223510742 4.851520538330078 C 26.41733169555664 6.368310928344727 27.25342178344727 8.386150360107422 27.25342178344727 10.53266143798828 C 27.25342178344727 12.67918109893799 26.41733169555664 14.69702053070068 24.89916229248047 16.21446990966797 L 15.20216178894043 25.91147041320801 C 14.82709121704102 26.28655052185059 14.31838130950928 26.49726104736328 13.78795146942139 26.49726104736328 C 13.2575216293335 26.49726104736328 12.74881172180176 26.28655052185059 12.37374114990234 25.91147041320801 L 2.676410675048828 16.21414184570312 C -0.4563579559326172 13.08137130737305 -0.4563694000244141 7.983970642089844 2.676401138305664 4.851190567016602 C 4.193981170654297 3.333610534667969 6.211702346801758 2.497840881347656 8.357881546020508 2.497840881347656 C 10.38759994506836 2.497840881347656 12.30235290527344 3.245304107666016 13.78789329528809 4.610126495361328 C 15.27305603027344 3.245044708251953 17.18797302246094 2.497261047363281 19.2180118560791 2.497261047363281 Z"
                      stroke="none" fill="#fff" />
                  </g>
                </svg>
              </div>

              <div class="duration_box position-absolute">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                  <path
                    d="M6.99984 12.8332C10.2215 12.8332 12.8332 10.2215 12.8332 6.99984C12.8332 3.77818 10.2215 1.1665 6.99984 1.1665C3.77818 1.1665 1.1665 3.77818 1.1665 6.99984C1.1665 10.2215 3.77818 12.8332 6.99984 12.8332Z"
                    stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M9 9L7.39067 7.793C7.1406 7.60551 7.00008 7.35119 7 7.086V4" stroke="white"
                    stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <span class="small ms-1">1:15:30</span>
              </div>

              <div class="desBox d-flex position-absolute p-3 w-100">
                <div class="description">
                  <p class="small color_pink mb-1">Category Three</p>
                  <h6 class="fs18 text-truncate mb-0 desHeading">Jessie Saint - Fresh Hot Meat 2022</h6>
                </div>
                <div class="price_count text-end">
                  <p class="fs13 mb-1 opacity-50 text-uppercase">JUN 15, 2020</p>
                  <h6 class="mb-0 d-flex align-items-center justify-content-end"><i class="me-2"><img
                        src="assets/images/coin.svg" alt=""></i>
                    10</h6>
                </div>
              </div>
            </div>
          </div>

          <div class="videoBox position-relative">
            <div class="videoCoverImg">
              <img class="img-fluid" src="assets/images/Vimg9.jpg" alt="">
            </div>
            <div class="absolute_box position-absolute h-100 w-100">
              <div class="likeContainer d-flex align-items-center justify-content-center ms-auto me-3 cursor_pointer"
                (click)="fav()">
                <svg xmlns="http://www.w3.org/2000/svg" width="26.927" height="24" viewBox="0 0 26.927 24">
                  <g id="Icon_feather-heart" data-name="Icon feather-heart" transform="translate(-0.327 -2.497)"
                    fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path
                      d="M23.485,6.265a6.033,6.033,0,0,0-8.535,0L13.788,7.428,12.625,6.265A6.035,6.035,0,1,0,4.091,14.8l1.163,1.163L13.788,24.5l8.535-8.535L23.485,14.8a6.033,6.033,0,0,0,0-8.535Z"
                      stroke="none" />
                    <path
                      d="M 19.218017578125 4.497261047363281 C 17.67348861694336 4.497261047363281 16.12896156311035 5.086641311645508 14.95075130462646 6.265401840209961 L 13.78795146942139 7.428211212158203 L 12.62515163421631 6.265401840209961 C 10.26840209960938 3.908658981323242 6.447360992431641 3.908660888671875 4.090621948242188 6.265401840209961 C 1.733880996704102 8.622150421142578 1.733880996704102 12.443190574646 4.090621948242188 14.79993057250977 L 13.78795146942139 24.49726104736328 L 23.48528099060059 14.79993057250977 C 25.84280204772949 12.44351100921631 25.84280204772949 8.621820449829102 23.48528099060059 6.265401840209961 C 22.30707168579102 5.086641311645508 20.76254463195801 4.497261047363281 19.218017578125 4.497261047363281 M 19.2180118560791 2.497261047363281 C 21.36453247070312 2.497261047363281 23.38237190246582 3.333351135253906 24.89982223510742 4.851520538330078 C 26.41733169555664 6.368310928344727 27.25342178344727 8.386150360107422 27.25342178344727 10.53266143798828 C 27.25342178344727 12.67918109893799 26.41733169555664 14.69702053070068 24.89916229248047 16.21446990966797 L 15.20216178894043 25.91147041320801 C 14.82709121704102 26.28655052185059 14.31838130950928 26.49726104736328 13.78795146942139 26.49726104736328 C 13.2575216293335 26.49726104736328 12.74881172180176 26.28655052185059 12.37374114990234 25.91147041320801 L 2.676410675048828 16.21414184570312 C -0.4563579559326172 13.08137130737305 -0.4563694000244141 7.983970642089844 2.676401138305664 4.851190567016602 C 4.193981170654297 3.333610534667969 6.211702346801758 2.497840881347656 8.357881546020508 2.497840881347656 C 10.38759994506836 2.497840881347656 12.30235290527344 3.245304107666016 13.78789329528809 4.610126495361328 C 15.27305603027344 3.245044708251953 17.18797302246094 2.497261047363281 19.2180118560791 2.497261047363281 Z"
                      stroke="none" fill="#fff" />
                  </g>
                </svg>
              </div>

              <div class="duration_box position-absolute">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                  <path
                    d="M6.99984 12.8332C10.2215 12.8332 12.8332 10.2215 12.8332 6.99984C12.8332 3.77818 10.2215 1.1665 6.99984 1.1665C3.77818 1.1665 1.1665 3.77818 1.1665 6.99984C1.1665 10.2215 3.77818 12.8332 6.99984 12.8332Z"
                    stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M9 9L7.39067 7.793C7.1406 7.60551 7.00008 7.35119 7 7.086V4" stroke="white"
                    stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <span class="small ms-1">1:15:30</span>
              </div>

              <div class="desBox d-flex position-absolute p-3 w-100">
                <div class="description">
                  <p class="small color_pink mb-1">Category Four</p>
                  <h6 class="fs18 text-truncate mb-0 desHeading">Asiatische Strassenhure abgeschleppt und vor der kamera
                    hart gefickt</h6>
                </div>
                <div class="price_count text-end">
                  <p class="fs13 mb-1 opacity-50 text-uppercase">JUN 15, 2020</p>
                  <h6 class="mb-0 d-flex align-items-center justify-content-end"><i class="me-2"><img
                        src="assets/images/coin.svg" alt=""></i>
                    10</h6>
                </div>
              </div>
            </div>
          </div>


          <div class="videoBox position-relative">
            <div class="videoCoverImg">
              <img class="img-fluid" src="assets/images/Vimg5.jpg" alt="">
            </div>
            <div class="absolute_box position-absolute h-100 w-100">
              <div class="likeContainer d-flex align-items-center justify-content-center ms-auto me-3 cursor_pointer"
                (click)="fav()">
                <svg xmlns="http://www.w3.org/2000/svg" width="26.927" height="24" viewBox="0 0 26.927 24">
                  <g id="Icon_feather-heart" data-name="Icon feather-heart" transform="translate(-0.327 -2.497)"
                    fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path
                      d="M23.485,6.265a6.033,6.033,0,0,0-8.535,0L13.788,7.428,12.625,6.265A6.035,6.035,0,1,0,4.091,14.8l1.163,1.163L13.788,24.5l8.535-8.535L23.485,14.8a6.033,6.033,0,0,0,0-8.535Z"
                      stroke="none" />
                    <path
                      d="M 19.218017578125 4.497261047363281 C 17.67348861694336 4.497261047363281 16.12896156311035 5.086641311645508 14.95075130462646 6.265401840209961 L 13.78795146942139 7.428211212158203 L 12.62515163421631 6.265401840209961 C 10.26840209960938 3.908658981323242 6.447360992431641 3.908660888671875 4.090621948242188 6.265401840209961 C 1.733880996704102 8.622150421142578 1.733880996704102 12.443190574646 4.090621948242188 14.79993057250977 L 13.78795146942139 24.49726104736328 L 23.48528099060059 14.79993057250977 C 25.84280204772949 12.44351100921631 25.84280204772949 8.621820449829102 23.48528099060059 6.265401840209961 C 22.30707168579102 5.086641311645508 20.76254463195801 4.497261047363281 19.218017578125 4.497261047363281 M 19.2180118560791 2.497261047363281 C 21.36453247070312 2.497261047363281 23.38237190246582 3.333351135253906 24.89982223510742 4.851520538330078 C 26.41733169555664 6.368310928344727 27.25342178344727 8.386150360107422 27.25342178344727 10.53266143798828 C 27.25342178344727 12.67918109893799 26.41733169555664 14.69702053070068 24.89916229248047 16.21446990966797 L 15.20216178894043 25.91147041320801 C 14.82709121704102 26.28655052185059 14.31838130950928 26.49726104736328 13.78795146942139 26.49726104736328 C 13.2575216293335 26.49726104736328 12.74881172180176 26.28655052185059 12.37374114990234 25.91147041320801 L 2.676410675048828 16.21414184570312 C -0.4563579559326172 13.08137130737305 -0.4563694000244141 7.983970642089844 2.676401138305664 4.851190567016602 C 4.193981170654297 3.333610534667969 6.211702346801758 2.497840881347656 8.357881546020508 2.497840881347656 C 10.38759994506836 2.497840881347656 12.30235290527344 3.245304107666016 13.78789329528809 4.610126495361328 C 15.27305603027344 3.245044708251953 17.18797302246094 2.497261047363281 19.2180118560791 2.497261047363281 Z"
                      stroke="none" fill="#fff" />
                  </g>
                </svg>
              </div>

              <div class="duration_box position-absolute">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                  <path
                    d="M6.99984 12.8332C10.2215 12.8332 12.8332 10.2215 12.8332 6.99984C12.8332 3.77818 10.2215 1.1665 6.99984 1.1665C3.77818 1.1665 1.1665 3.77818 1.1665 6.99984C1.1665 10.2215 3.77818 12.8332 6.99984 12.8332Z"
                    stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M9 9L7.39067 7.793C7.1406 7.60551 7.00008 7.35119 7 7.086V4" stroke="white"
                    stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <span class="small ms-1">1:15:30</span>
              </div>

              <div class="desBox d-flex position-absolute p-3 w-100">
                <div class="description">
                  <p class="small color_pink mb-1">Category Five</p>
                  <h6 class="fs18 text-truncate mb-0 desHeading">Brunette Asian wants some dick inside of her</h6>
                </div>
                <div class="price_count text-end">
                  <p class="fs13 mb-1 opacity-50 text-uppercase">JUN 15, 2020</p>
                  <h6 class="mb-0 d-flex align-items-center justify-content-end"><i class="me-2"><img
                        src="assets/images/coin.svg" alt=""></i>
                    10</h6>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="arrowSmall position-absolute w-100">
          <ul class="list-unstyled d-flex align-items-center justify-content-between m-0">
            <li class="prev"></li>
            <li class="next"></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section> -->



<app-footer></app-footer>