import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/common/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/message/messagehandling.service';
@Component({
  selector: 'app-artist-details',
  templateUrl: './artist-details.component.html',
  styleUrls: ['./artist-details.component.scss']
})
export class ArtistDetailsComponent implements OnInit {
  artistData: any
  imageUrl: any
  constructor(public dialogRef: MatDialogRef<ArtistDetailsComponent>, @Inject(MAT_DIALOG_DATA) public data: { block: any }, private api: ApiService, private router: Router, private message: MessagehandlingService) { }

  ngOnInit(): void {
    this.artistData = this.data.block
    this.imageUrl = this.api.modelImageUrl

  }
  closeDialog(): void {
    this.dialogRef.close();
  }

  artistStudio(id: any) {
    this.router.navigate([`/studio-details/${id}`])
    this.closeDialog()
  }
}
