import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/common/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/message/messagehandling.service';

@Component({
  selector: 'app-token-list',
  templateUrl: './token-list.component.html',
  styleUrls: ['./token-list.component.scss']
})
export class TokenListComponent implements OnInit {
  activityDetail: any;
  tokenDetail: any;
  constructor( private api: ApiService, private message: MessagehandlingService, private router: Router) {

  }
  ngOnInit(): void {
    this.tokenList()
  }

  tokenList(){
    this.api.tokenDetail().subscribe((res:any)=>{
      this.activityDetail=res.activity
      this.tokenDetail=res.data
      
    })
  }

}
