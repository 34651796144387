<app-header></app-header>

<section class="my-profile py-5">
    <form [formGroup]="changeAddress">
        <div class="container">
            <button class="backbtn" [routerLink]="['/studio-my-profile']"><img
                    src="assets/images/studiomodule/backbtn.svg"></button>
            <div class="row justify-content-center">
                <div class="col-md-7">
                    <div class="form-sec">
                        <h4 class="mb-4">Change Address</h4>
                        <!-- <form> -->
                        <div class="inner-sec ">
                            <div class="mb-4 pb-1">
                                <label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Legal Business Name</label>
                                <input type="number" class="form-control fs18 cmn-input input_60 ff_Roboto"
                                    placeholder="Fax" formControlName="fax">
                                <small *ngIf="!changeAddress.controls?.['fax']?.valid &&
                                        changeAddress.controls?.['fax']?.dirty" class="error_main_div">
                                    <div *ngIf="changeAddress.controls?.['fax']?.hasError('required')"
                                        class="error_message">This field is
                                        Mandatory !</div>
                                </small>
                            </div>

                            <div class="mb-4 pb-1">
                                <label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Address
                                </label>
                                <input type="text" class="form-control fs18 cmn-input input_60 ff_Roboto"
                                    placeholder="Enter address" formControlName="address" (keydown)="checkEvent($event)">
                                <small *ngIf="!changeAddress.controls?.['address']?.valid &&
                                        changeAddress.controls?.['address']?.dirty" class="error_main_div">
                                    <div *ngIf="changeAddress.controls?.['address']?.hasError('required')"
                                        class="error_message">This field is
                                        Mandatory !</div>
                                </small>
                            </div>

                            <div class="row g-4">
                                <div class="mb-4 pb-1 col-md-6">
                                    <label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Country
                                    </label>
                                    <input type="text" class="form-control fs18 cmn-input input_60 ff_Roboto"
                                        placeholder="Country" formControlName="country" (keydown)="checkEvent($event)">
                                    <small *ngIf="!changeAddress.controls?.['country']?.valid &&
                                        changeAddress.controls?.['country']?.dirty" class="error_main_div">
                                        <div *ngIf="changeAddress.controls?.['country']?.hasError('required')"
                                            class="error_message">This field is
                                            Mandatory !</div>
                                    </small>
                                </div>

                                <div class="mb-4 pb-1 col-md-6">
                                    <label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">State
                                    </label>
                                    <input type="text" class="form-control fs18 cmn-input input_60 ff_Roboto"
                                        placeholder="State" formControlName="state" (keydown)="checkEvent($event)">

                                        <small *ngIf="!changeAddress.controls?.['state']?.valid &&
                                        changeAddress.controls?.['state']?.dirty" class="error_main_div">
                                        <div *ngIf="changeAddress.controls?.['state']?.hasError('required')"
                                            class="error_message">This field is
                                            Mandatory !</div>
                                    </small>
                                </div>
                            </div>

                            <div class="row g-4">
                                <div class="mb-4 pb-1 col-md-6">
                                    <label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">City
                                    </label>
                                    <input type="text" class="form-control fs18 cmn-input input_60 ff_Roboto"
                                        placeholder="City" formControlName="city" (keydown)="checkEvent($event)">
                                        <small *ngIf="!changeAddress.controls?.['city']?.valid &&
                                        changeAddress.controls?.['city']?.dirty" class="error_main_div">
                                        <div *ngIf="changeAddress.controls?.['city']?.hasError('required')"
                                            class="error_message">This field is
                                            Mandatory !</div>
                                    </small>
                                        
                                </div>

                                <div class="mb-4 pb-1 col-md-6">
                                    <label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Zip
                                    </label>
                                    <input type="number" class="form-control fs18 cmn-input input_60 ff_Roboto"
                                        placeholder="Zip" formControlName="zipcode">
                                        <small *ngIf="!changeAddress.controls?.['zipcode']?.valid &&
                                        changeAddress.controls?.['zipcode']?.dirty" class="error_main_div">
                                        <div *ngIf="changeAddress.controls?.['zipcode']?.hasError('required')"
                                            class="error_message">This field is
                                            Mandatory !</div>
                                    </small>  
                                </div>
                            </div>

                            <div class="text-center mt-5">
                                <button type="submit" class="btn  purple_btn btn70 fw-bold px-5"
                                    (click)="updateAddress()" [disabled]="changeAddress.invalid">Change Address</button>
                            </div>
                        </div>
                        <!-- </form> -->
                    </div>
                </div>
            </div>
        </div>
    </form>
</section>

<app-studio-footer> </app-studio-footer>