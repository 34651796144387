<app-header></app-header>

<section class="revenue-sec">
    <div class="container">
        <div class="cmn-box mt-4 p-4">
            <div class="row ">
                <div class="col-md-8">
                    <div class="graph-sec p-4">
                        <div class="top-sec d-flex align-items-center justify-content-between pb-4">
                            <h5 class="text-white fw-semiBold mb-0">Studio Revenue</h5>
                            <select class="form-select text-white fs14 shadow-none" aria-label="Default select example" (change)="graphFilter($event)">
                                <option *ngFor="let year of yearsArray"  [value]="year">{{year}}</option>
                                <!-- <option value="previous">Previous Year</option> -->
                                <!-- <option value="2">Two</option> -->
                            </select>
                        </div>
                        <div class="graph-outer">
                            <apx-chart [series]="chartOptions?.series" [chart]="chartOptions?.chart"
                                [dataLabels]="chartOptions?.dataLabels" [plotOptions]="chartOptions?.plotOptions"
                                [yaxis]="chartOptions?.yaxis" [legend]="chartOptions?.legend" [fill]="chartOptions?.fill"
                                [stroke]="chartOptions?.stroke" [tooltip]="chartOptions?.tooltip"
                                [xaxis]="chartOptions?.xaxis" [grid]="chartOptions?.grid"></apx-chart>
                        </div>
                    </div>
                </div>

                <div class="col-md-4">
                    <div class="profit-sec">
                        <div class="top-sec text-center p-4 mb-4">
                            <img class="mb-5 mt-3" src="assets/images/studiomodule/growth.svg">
                            <p class="fs18 fw-normal mb-0 lh1">Earnings</p>
                            <h2 class="mt-3 text-white fw-bold">$ {{revenue}}</h2>
                        </div>
                        <button (click)="download()"
                            class="btn download-btn w-100 fs18 fw-medium d-flex align-items-center justify-content-center text-white shadow-none">
                            <svg style="margin-right: 10px;" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path
                                    d="M1.66669 14.1667L2.18419 16.2375C2.27431 16.5981 2.48237 16.9182 2.7753 17.1469C3.06822 17.3757 3.42919 17.4999 3.80085 17.5H16.1992C16.5708 17.4999 16.9318 17.3757 17.2247 17.1469C17.5177 16.9182 17.7257 16.5981 17.8159 16.2375L18.3334 14.1667M10 12.5V2.5V12.5ZM10 12.5L6.66669 9.16667L10 12.5ZM10 12.5L13.3334 9.16667L10 12.5Z"
                                    stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            Download CSV File</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="r-history">
    <div class="container">
        <div class="cmn-box my-4 p-4">
            <h5 class="text-white fw-semiBold mb-4">Revenue History</h5>
            <div class="table-responsive">
                
                <table id="revenueHistory" class="table cmn-table">
                    <thead>
                        <tr>
                            <th scope="col">S.No</th>
                            <th scope="col">Transcation Id</th>
                            <th scope="col">Purchased Video</th>
                            <th scope="col">Date</th>
                            <th scope="col">Price</th>
                            <!-- <th scope="col">Payout</th>
                        <th scope="col">User Details</th>
                        <th scope="col">Action</th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let clip of clipsData; let i=index">
                            <td>{{i}}</td>
                            <td>{{clip.orderNumber}}</td>
                            <td>
                                <span class="d-flex align-items-center videos-sec">
                                    <span class="img-wrapper position-relative">
                                        <!-- <img class="fit-img" src="assets/images/studiomodule/history1.png">
                                    <span class="video-overlay"></span>
                                    <span class="d-flex align-items-center duration">
                                        <svg class="me-1" width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.416626 0V5L4.58329 2.5L0.416626 0Z" fill="#F8F8F8"/>
                                        </svg>
                                        10:53
                                    </span> -->
                                        <video class="img-fluid" [src]="baseUrl+imageUrl+clip.trailer" height="100"
                                            controls controlsList="nodownload"></video>
                                    </span>
                                    <span class="ps-3 text fw-medium">{{clip.title}}</span>
                                </span>
                            </td>
                            <td>{{clip.created_at| date:'medium'}}</td>
                            <td>$ {{clip.total}}</td>
                            <td></td>
                            <!-- <td>30%</td>
                        <td>William Blake</td>
                        <td><span class="color_pink" role="button" [routerLink]="['/studio-revenue-details']">View Details</span></td> -->
                        </tr>

                        <!-- <tr>
                            <td>2</td>
                            <td>1533655</td>
                            <td>
                                <span class="d-flex align-items-center videos-sec">
                                    <span class="img-wrapper position-relative">
                                        <img class="fit-img" src="assets/images/studiomodule/history2.png">
                                        <span class="video-overlay"></span>
                                        <span class="d-flex align-items-center duration">
                                            <svg class="me-1" width="5" height="5" viewBox="0 0 5 5" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0.416626 0V5L4.58329 2.5L0.416626 0Z" fill="#F8F8F8" />
                                            </svg>
                                            9:10
                                        </span>
                                    </span>
                                    <span class="ps-3 text fw-medium">Innocent looking Asian devours...</span>
                                </span>
                            </td>
                            <td>May 18, 2022 | 1:30 PM</td>
                            <td>$16.80</td>

                        </tr>

                        <tr>
                            <td>3</td>
                            <td>155544655</td>
                            <td>
                                <span class="d-flex align-items-center videos-sec">
                                    <span class="img-wrapper position-relative">
                                        <img class="fit-img" src="assets/images/studiomodule/history3.png">
                                        <span class="video-overlay"></span>
                                        <span class="d-flex align-items-center duration">
                                            <svg class="me-1" width="5" height="5" viewBox="0 0 5 5" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0.416626 0V5L4.58329 2.5L0.416626 0Z" fill="#F8F8F8" />
                                            </svg>
                                            11:00
                                        </span>
                                    </span>
                                    <span class="ps-3 text fw-medium">Discipline for Mommy's Little An..</span>
                                </span>
                            </td>
                            <td>May 16, 2022 | 11:00 AM</td>
                            <td>$9.00</td>

                        </tr>

                        <tr>
                            <td>4</td>
                            <td>15444655</td>
                            <td>
                                <span class="d-flex align-items-center videos-sec">
                                    <span class="img-wrapper position-relative">
                                        <img class="fit-img" src="assets/images/studiomodule/history4.png">
                                        <span class="video-overlay"></span>
                                        <span class="d-flex align-items-center duration">
                                            <svg class="me-1" width="5" height="5" viewBox="0 0 5 5" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0.416626 0V5L4.58329 2.5L0.416626 0Z" fill="#F8F8F8" />
                                            </svg>
                                            8:00
                                        </span>
                                    </span>
                                    <span class="ps-3 text fw-medium">Penelope Kay: Fucked in Tight...</span>
                                </span>
                            </td>
                            <td>May 13, 2022 | 10:50 AM</td>
                            <td>$12.50</td>

                        </tr>

                        <tr>
                            <td>4</td>
                            <td>15444655</td>
                            <td>
                                <span class="d-flex align-items-center videos-sec">
                                    <span class="img-wrapper position-relative">
                                        <img class="fit-img" src="assets/images/studiomodule/history4.png">
                                        <span class="video-overlay"></span>
                                        <span class="d-flex align-items-center duration">
                                            <svg class="me-1" width="5" height="5" viewBox="0 0 5 5" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0.416626 0V5L4.58329 2.5L0.416626 0Z" fill="#F8F8F8" />
                                            </svg>
                                            8:00
                                        </span>
                                    </span>
                                    <span class="ps-3 text fw-medium">Penelope Kay: Fucked in Tight...</span>
                                </span>
                            </td>
                            <td>May 13, 2022 | 10:50 AM</td>
                            <td>$12.50</td>

                        </tr> -->





                    </tbody>
                  
                </table>
                <div class="total_price">
                  Total:  {{totalSum}}
                </div>
            </div>


            <div class="total_earning">
                <h5> Sales Summary</h5>
                <p class="mb-0"> Month : <span> {{monthData?.month}}</span></p>
                <p class="mb-0"> Total Sales : <span> $ {{monthData?.totalSales}}</span></p>
                <p class="mb-0"> Commission Rate : <span> {{monthData?.commissionRate}}%</span></p>
                <p class="mb-0"> Charges Backs : <span> $ 0</span></p>
                <p class="mb-0"> Total Earning : <span> $ {{monthData?.totalEarning}}</span></p>
                <p class="mb-0"> Total Payout for this month : <span> $ {{monthData?.totalPayout}}</span></p>
            </div>


            <div class="payout_policies mt-5">

                <p class="mb-3">Payout are sent on the 15th of each month for the previous months sales.</p>
                <p class="mb-3"> Please Note: There is a $200 minimum on payouts for Domestic Checks and International
                    Wire Transfers. If your Store does not process $200 in a month we will carry over the amount and
                    send payment, once sales have reached $200 or more.</p>
                <p>Thank You</p>
            </div>


        </div>
    </div>
</section>


<app-studio-footer> </app-studio-footer>