import { Component, OnInit } from '@angular/core';
import {  Router } from '@angular/router';
import { ApiService } from 'src/app/common/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/message/messagehandling.service';
declare const $: any;
@Component({
  selector: 'app-purchased-video',
  templateUrl: './purchased-video.component.html',
  styleUrls: ['./purchased-video.component.scss']
})
export class PurchasedVideoComponent implements OnInit {
  purchaseData: any;
  baseClipUrl: any
  constructor(private api: ApiService, private message: MessagehandlingService,private router: Router) { }

  ngOnInit(): void {
    this.baseClipUrl = this.api.clipsImageUrl
    this.purchaseList()
  }
  fav() {
    // $(".likeContainer").toggleClass("heart_filled");
    $(".likeContainer").toggleClass("heart_filled");
  }

  purchaseList() {
    let fd = new FormData
    fd.append('pageNo', '0')
    this.api.purchaseHistory(fd).subscribe((res: any) => {
      this.purchaseData = res.data
      this.message.sucessMessage(res.message, 2000)

    })
  }

  videoDetail(id:any){    
     this.router.navigate([`/purchased-video-detail/${id}`])

  }
}
