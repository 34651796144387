<app-header></app-header>

<section class="clip-details mb-4">
    <div class="container">
        <button class="backbtn" [routerLink]="['/studio-home']"><img src="assets/images/studiomodule/backbtn.svg"></button>

        <div class="row">
            <div class="col-md-7">
                <ngx-video-list-player [config]="config"></ngx-video-list-player>
                <h6 class="fs16 fw-normal mt-3 pt-1 pb-4 mb-1 d-flex align-items-center flex-wrap color_white_half">June 15, 2020<span class="px-3">|</span>1:15:30</h6>
            </div>
            <div class="col-md-5">
                <h4 class="fw-medium text-white mb-4 mt-3 pt-1">Alura Jenson in Love with Halle Hayes</h4>
                <h5 class="text-white fs18 fw-medium d-flex align-items-center mb-3 pb-1">Category:<span class="color_pink fw-normal ps-3 fs20">Bondage</span></h5>
                <h6 class="sa18 text-white d-flex align-items-center flex-wrap mb-4"><span class="me-3">Tags:</span> <span class="tag fs14 me-2">Brunette</span><span class="tag fs14 me-2">Muscle</span><span class="tag fs14 me-2"> Muscular</span><span class="tag fs14 me-2">Natural Boobs</span></h6>
                <h5 class="fs18 fw-medium d-flex align-items-center price-row">Price: <span class="fs20 fw-normal ps-3">$10.00</span></h5>


                <div class="d-flex mt-4 align-items-center">   <div>  <div class="user_img">  <img class="w-100 h-100" src="assets/images/studiomodule/history5.png" alt> </div>  </div>  <div class="ms-3 pt-3"> <h5 class="fs22 fw-medium mb-0 d-flex align-items-center">  William Blake</h5>
                    <p class="mt-2 emailtext fw-300 fs16">williamblake2@gmail.com</p>   </div>    </div> 


                <!-- <div class="d-flex align-items-start justify-content-between flex-wrap">
                    <div class="user-info">
                        <h4 class="mb-1 text-white fs22 fw-medium">William Blake</h4>
                        <p class="emailtext fw-light">williamblake2@gmail.com</p>
                    </div>
                    <h6 class="fw-normal fs14 mt-1">May 20, 2022 | 10:00 AM</h6>
                </div> -->
            </div>
        </div>
        
        <h4 class="fw-medium mb-2">Description</h4>
        <p class="description fw-light pt-1 mb-4">Today is my lucky fucking day! Halle Hayes is in the house! Just look at her there on her knees in the back of a car getting hotter by the second. Oh & I get to walk in on her, I'm so lucky, but you're lucky too, you get to jerk off to this hot exclusive scene! Watch as we play with each other, sucking each other tits, touching each others bodies & have a ton of fun with this awesome vibrating wand! I have to be honest it was bit cold in the garage we were in, I'd like to take Halle someplace warm & put my tongue in her asshole!</p>
    </div>
</section>

<app-studio-footer> </app-studio-footer> 