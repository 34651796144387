import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/common/api-service/api.service';

@Component({
  selector: 'app-my-wallet',
  templateUrl: './my-wallet.component.html',
  styleUrls: ['./my-wallet.component.scss']
})
export class MyWalletComponent implements OnInit {
  profileData: any;
  

  constructor(private api :ApiService,private router: Router) { }

  ngOnInit(): void {
    this.getProfile()
  }

  // getDetail() {
  //   this.api.getSubscriptionDetail().subscribe((res: any) => {
  //     this.subsDetail = res.data

  //   })
  // }

  getProfile() {
    this.api.profileUser().subscribe((res: any) => {
      this.profileData = res.data
    })

  }

  redirectToUrl(url: string) {
    // this.router.navigate(['/add-token'])
    let a = sessionStorage.getItem('membername')
    let b: any = localStorage.getItem('studioToken')
    console.log(a, b);
    const dataToPass = { key: b };
    const queryParams = new URLSearchParams(dataToPass).toString();
    console.log(`${url}?${queryParams}`);
    window.location.href = `${url}?${queryParams}`;

     
 }

}
