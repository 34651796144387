import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { Router } from '@angular/router';
import { IVideoConfig } from 'ngx-video-list-player';
import { forkJoin } from 'rxjs';
import { ApiService } from 'src/app/common/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/message/messagehandling.service';
import { HttpClient, HttpEventType } from '@angular/common/http';
@Component({
  selector: 'app-add-clip',
  templateUrl: './add-clip.component.html',
  styleUrls: ['./add-clip.component.scss']
})
export class AddClipComponent implements OnInit {
  categoryvalue: any = "Category"
  modelvalue: any = "Model"
  addClipForm!: FormGroup
  value: any;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  clipDetail: any[] = [];
  clip: any
  trailer: any
  image: any
  categories: any[] = []
  categoryID: any;
  modelArray: any[] = [];
  modelID: any;
  clipData: any;
  clipFinalData: any;
  trailerData: any;
  trailerFinalData: any;
  imageData: any;
  imageFinalData: any;
  videoClipDuration: any;
  videoTrailerDuration: any;
  toppings = new FormControl('');
  selectedModels: any
  modalList: any[] = []
  // ['Modal1', 'Modal2', 'Modal3', 'Modal4', 'Modal5', 'Modal6'];
  categoryList: any[] = []
  // ['Category1', 'Category2', 'Category3', 'Category4', 'Category5', 'Category6'];

  apiUrl = 'https://admin.chokechamber.com/api/v1/studio';
  uploadProgress: number = 0
  cumulativeUploadProgress: number = 0

  constructor(private api: ApiService, private message: MessagehandlingService, private router: Router, private http: HttpClient) {
    this.addClipForm = new FormGroup({
      uploadVideo: new FormControl('', Validators.required),
      uploadTrailer: new FormControl('', Validators.required),
      uploadCoverImage: new FormControl('', Validators.required),
      title: new FormControl('', Validators.required),
      price: new FormControl('', Validators.required),
      categoryvalue: new FormControl('', Validators.required),
      duration: new FormControl('', [Validators.required, this.durationFormatValidator()]),
      tags: new FormControl(''),
      modelvalue: new FormControl('', Validators.required),
      description: new FormControl(''),
    })

  }
  ngOnInit(): void {

    this.categoryData()
    this.modelData()
    // setTimeout(() => {
    //   this.message.errorMessage(' fill all  fields', 2000)

    // }, 2000);
  }



  durationFormatValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const validFormat = /^[0-9]+(\s*(minute|min|minutes|second|sec|seconds))(\s*[0-9]+\s*(second|sec|seconds))?$/i.test(control.value);
      return validFormat ? null : { invalidFormat: { value: control.value } };
    };
  }

  categoryData() {
    this.api.modelCategories().subscribe((res: any) => {
      this.categories = res.data
    })
  }

  categoryClass(event: any) {
    this.categoryvalue = event;
    this.categoryID = event.id;
  }

  modelData() {
    let fd = new FormData();
    fd.append('pageno', '0');
    this.api.modelData(fd).subscribe((res: any) => {
      this.modelArray = res.data;
      setTimeout(() => {
        if (this.modelArray.length == 0) {
          if (confirm('You have not added any models yet. Please add a model which is required to add clips.')) {
            this.router.navigate(['/studio-models']);
          }
          // this.message.errorMessage('Please add model from model section ..', 3000)
        }
      }, 3000);
    });
  }


  modelClass(event: any) {
    this.modelvalue = event;
    this.modelID = event.id
  }

  selectedCategory(data: any) {
    this.categoryID = data
  }


  selectedModel(data: any) {
    this.modelID = data
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    this.value = event.value;
    if ((this.value || '').trim()) {
      this.clipDetail.push({ id: '0', name: this.value.trim() });
      // this.clipDetail.push(this.value);
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(model: any): void {
    const index = this.clipDetail.indexOf(model);
    if (index >= 0) {
      this.clipDetail.splice(index, 1);
    }
  }

  onFileChange(event: any, type: string): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      let reader = new FileReader();
      const video = document.createElement('video');
      video.src = window.URL.createObjectURL(file);
      video.preload = 'metadata';

      reader.onload = e => {
        switch (type) {
          case 'clip':
            this.clip = reader.result
            this.uploadClipVideo(file)
            // this.initiateUpload(file.name).subscribe((initiateResponse: any) => {

            //   const uploadId = initiateResponse.uploadId;

            //   let reader = new FileReader();
            //   reader.onload = () => {
            //     debugger
            //     // Step 2: Split the video into smaller chunks
            //     const chunkSize = 1024 * 1024; // 1 MB chunk size
            //     const fileSize = file.size;
            //     let offset = 0;
            //     let chunkIndex = 0;
            //     const chunkObservables = [];

            //     while (offset < fileSize) {
            //       const chunk = file.slice(offset, offset + chunkSize);
            //       offset += chunkSize;

            //       // Step 3: Create an observable for each chunk upload
            //       chunkObservables.push(this.uploadChunk(uploadId, file.name, chunkIndex, chunk));

            //       chunkIndex++;
            //     }

            //     // Step 3: Upload all chunks in parallel
            //     forkJoin(chunkObservables).subscribe((uploadResponses: any) => {
            //       // All chunks uploaded successfully, proceed to step 4

            //       // Step 4: Complete the upload
            //       this.completeUpload(file.name, uploadId).subscribe((completeResponse: any) => {
            //         // Upload is complete, handle the response as needed
            //       });
            //     });
            //   };
            // })
            // this.clipData = file
            // video.onloadedmetadata = () => {
            //   this.videoClipDuration = video.duration;
            //   // alert(this.videoClipDuration)
            // }
            // let fd = new FormData
            // fd.append('file', this.clipData)

            // this.api.upload(fd).subscribe((res: any) => {
            //   this.clipFinalData = res.data
            // })
            break;
          case 'trailer':
            this.trailer = reader.result
            this.trailerData = file
            video.onloadedmetadata = () => {
              this.videoTrailerDuration = video.duration;
            }
            let fd1 = new FormData
            fd1.append('file', this.trailerData)
            this.api.upload(fd1).subscribe((res: any) => {
              this.trailerFinalData = res.data
            })
            break;
          case 'image':
            this.image = reader.result
            this.imageData = file
            let fd2 = new FormData
            fd2.append('file', this.imageData)
            this.api.upload(fd2).subscribe((res: any) => {
              this.imageFinalData = res.data
            })
            break;
        }

      };
      reader.readAsDataURL(file);

    }

  }


  // uploadClipVideo(file: File): void {
  //   this.initiateUpload(file.name).subscribe((initiateResponse: any) => {
  //     const uploadId = initiateResponse.uploadId;
  //     const fileName = initiateResponse.fileName
  //     const chunkSize = 10 * 1024 * 1024; 
  //     const fileSize = file.size;
  //     let offset = 0;
  //     let chunkIndex = 0;

  //     const readNextChunk = () => {
  //       const chunkEnd = Math.min(offset + chunkSize, fileSize);
  //       const chunk = file.slice(offset, chunkEnd);
  //       offset += chunkSize;

  //       if (chunk.size > 0) {
  //         const reader = new FileReader();
  //         // reader.onload = (e) => {
  //           // const chunkData = (e.target as FileReader).result as any;
  //           // const chunkData = file
  //           // Upload the chunk
  //           this.uploadChunk(uploadId, fileName, chunkIndex, chunk).subscribe(() => {
  //             chunkIndex++;
  //             if (offset < fileSize) {
  //               readNextChunk();
  //             } else {

  //               this.completeUpload(fileName, uploadId).subscribe((completeResponse: any) => {
  //                 this.clipFinalData = completeResponse.data
  //                 this.message.sucessMessage(completeResponse.message, 1000)
  //               });
  //             }
  //           });
  //         // };
  //         reader.readAsDataURL(chunk);
  //       }
  //     };
  //     // const readNextChunk = () => {
  //     //   const chunkEnd = Math.min(offset + chunkSize, fileSize);
  //     //   const chunk = file.slice(offset, chunkEnd);
  //     //   offset += chunkSize;

  //     //   if (chunk.size > 0) {
  //     //     // Upload the chunk
  //     //     this.uploadChunk(uploadId, fileName, chunkIndex, chunk).subscribe(() => {
  //     //       chunkIndex++;
  //     //       if (offset < fileSize) {
  //     //         readNextChunk();
  //     //       } else {
  //     //         this.completeUpload(fileName, uploadId).subscribe((completeResponse: any) => {
  //     //           this.clipFinalData = completeResponse.data;
  //     //           this.message.sucessMessage(completeResponse.message, 1000);
  //     //         });
  //     //       }
  //     //     });
  //     //   }
  //     // };

  //     // Start the chunking process
  //     readNextChunk();
  //   });
  // }


  uploadClipVideo(file: File): void {
    this.uploadProgress = 0
    this.initiateUpload(file.name).subscribe((initiateResponse: any) => {
      const uploadId = initiateResponse.uploadId;
      const fileName = initiateResponse.fileName;
      const chunkSize = 10 * 1024 * 1024;
      const fileSize = file.size;
      let offset = 0;
      let chunkIndex = 0;

      const readNextChunk = () => {
        const chunkEnd = Math.min(offset + chunkSize, fileSize);
        const chunk = file.slice(offset, chunkEnd);
        offset += chunkSize;

        if (chunk.size > 0) {
          this.uploadChunk(uploadId, fileName, chunkIndex, chunk).subscribe((event: any) => {
            if (event.type === HttpEventType.UploadProgress) {
              if (offset < fileSize) {
                const chunkPercent = Math.round((100 * event.loaded) / event.total);
                this.cumulativeUploadProgress = Math.min(100, Math.round((chunkIndex * 100 + chunkPercent) / (fileSize / chunkSize)));
                this.uploadProgress = this.cumulativeUploadProgress;
              }
            } else if (event.type === HttpEventType.Response) {
              chunkIndex++;
              if (offset < fileSize) {
                readNextChunk();
              } else {
                this.completeUpload(fileName, uploadId).subscribe((completeResponse: any) => {
                  this.clipFinalData = completeResponse.data;
                  this.message.sucessMessage(completeResponse.message, 1000);
                  this.cumulativeUploadProgress = 100;
                  this.uploadProgress = this.cumulativeUploadProgress;
                });
              }
            }
          });
        }
      };

      readNextChunk();
    });
  }

  initiateUpload(fileName: string) {
    const url = `${this.apiUrl}/initiateUpload`;
    return this.http.post(url, { fileName });
  }

  uploadChunk(uploadId: string, fileName: string, index: number, chunk: Blob) {
    const url = `${this.apiUrl}/uploadChunk`;
    const formData = new FormData();
    formData.append('uploadId', uploadId);
    formData.append('fileName', fileName);
    formData.append('index', index.toString());
    formData.append('file', chunk);
    // return this.http.post(url, formData);
    return this.http.post(url, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  completeUpload(fileName: string, uploadId: string) {
    const url = `${this.apiUrl}/completeUpload`;
    return this.http.post(url, { fileName, uploadId });

  }

  checkEvent(event: any) {
    if (event.target.value == '' && event.which == 32) {
      event.preventDefault();
    }
  }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }


  addClip() {
    // setTimeout(() => {
      if (this.uploadProgress !== 0 && this.uploadProgress !== 100) {
        this.message.errorMessage('Please wait, clip upload is in progress', 1000);
        return;
      }
      
    if (this.modelArray.length == 0) {
      alert('Please add model from model section...')
      this.router.navigate(['/studio-models'])
    }
    else {
      let fd = new FormData
      fd.append('MID', this.modelID)
      fd.append('CID', this.categoryID)
      fd.append('title', this.addClipForm.value.title)
      fd.append('description', this.addClipForm.value.description)
      fd.append('price', this.addClipForm.value.price)
      fd.append('image', this.imageFinalData)
      fd.append('trailer', this.trailerFinalData)
      fd.append('video', this.clipFinalData)
      // fd.append('duration', this.videoClipDuration)
      fd.append('duration', this.addClipForm.value.duration)

      fd.append('tags', JSON.stringify(this.clipDetail))

      this.api.addClip(fd).subscribe((res: any) => {
        if (res.status == 200) {
          this.message.sucessMessage(res.message, 2000)
          this.router.navigate(['/studio-clips'])
        }
        else {
          this.message.errorMessage('Please fill all details', 2000)

        }
      })
      // }, 3000);

    }
  }

  removeImage(type: any) {
    switch (type) {
      case 'clip':
        this.clip = ''
        this.uploadProgress = 0
        break;
      case 'trailer':
        this.trailer = ''
        break;
      case 'image':
        this.image = ''
        break;
      default:
        break;
    }
  }
}
