import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/common/api-service/api.service';

@Component({
  selector: 'app-purchased-video-detail',
  templateUrl: './purchased-video-detail.component.html',
  styleUrls: ['./purchased-video-detail.component.scss']
})
export class PurchasedVideoDetailComponent implements OnInit {
  clipDetail: any;
  videoData: any;
  clipUrl: any;
  id:any
  baseClipUrl='https://chokechambernew.s3.amazonaws.com/'
  constructor(private router: Router, public activeroute: ActivatedRoute, private api: ApiService) {
    this.activeroute.params.subscribe((params: any) => {
      this.id = params['id'];
      if (this.id) {
        // this.getDetail(this.id)
        this.videoDetail(this.id)
      } else {
        this.router.navigate(['/']);
      }
    })
  }
  ngOnInit(): void {
  }

  videoDetail(id: any) {
    this.api.clipDetailUser(id).subscribe((res: any) => {
      this.clipUrl = this.api.clipsImageUrl
      this.videoData = this.baseClipUrl+ res.data.video
      this.clipDetail = res.data

    })
  }

}
