import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/common/api-service/api.service';

@Component({
  selector: 'app-model-details',
  templateUrl: './model-details.component.html',
  styleUrls: ['./model-details.component.scss']
})
export class ModelDetailsComponent implements OnInit {
  id: any;
  modelData: any;
  image: any;
  modalImageUrl: any;
  tags: any=[]

  constructor(private api: ApiService, private router: Router, private activeroute: ActivatedRoute) {
    this.activeroute.params.subscribe((params: any) => {
      this.id = params['id'];
      if (this.id) {
        this.modelDetails(this.id)
      } else {
        this.router.navigate(['/']);
      }
    })
  }
  ngOnInit(): void {
  }

  modelDetails(id:any) {
    this.modalImageUrl = this.api.modelImageUrl
    this.api.getModelDetail(id).subscribe((ele: any) => {
      this.modelData=ele.data
      this.tags=ele.data.tags
      if (ele.data.profile == '' || ele.data.profile == null || ele.data.profile == undefined) {
        this.image = '';
      }
      else {
        setTimeout(() => {
          this.image = this.modalImageUrl + ele.data.profile
        }, 2000);
      }
    })
   
  }

}
