import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/common/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/message/messagehandling.service';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {
  editProfile: FormGroup
  userData: any
  profileData: any
  logoData: any
  licenceData: any
  logo: any
  licence: any
  disable =  true;

  constructor(private router: Router, private api: ApiService, private message: MessagehandlingService) {

    this.editProfile = new FormGroup({
      description: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required]),
      phoneNumber: new FormControl('', [Validators.required]),
      email: new FormControl([Validators.required, this.emailValidator.bind(this)]),
      ssn: new FormControl(''),
      fax: new FormControl(''),
      logo: new FormControl(''),
      licence: new FormControl(''),
      website: new FormControl(''),

    })
  }

  ngOnInit(): void {
    // this.userData = sessionStorage.getItem('studioProfile')
    // this.userInfo = (sessionStorage.getItem('userinformation'))

    this.getProfileData()
    // this.profileData = JSON.parse(this.userData)

  }


  checkEvent(event:any){
    if(event.target.value=='' && event.which==32){
      event.preventDefault();
    }
  }

  getProfileData() {
    this.api.getProfile().subscribe((res: any) => {
      this.profileData = res.data
      setTimeout(() => {
        this.editProfile.patchValue({
          description: res.data.description,
          name: this.profileData.name,
          phoneNumber: this.profileData.phoneNumber,
          email: this.profileData.email,
          ssn: this.profileData.ssn,
          fax: this.profileData.fax,
          website: this.profileData?.website

        })
        this.logoData = `${this.api.licenceAndLogoUrl}${this.profileData.logo}`;
        this.licenceData = `${this.api.licenceAndLogoUrl}${this.profileData.licence}`;
      }, 1000);
    })
  }


  emailValidator(control: FormControl) {
    if (!(control.value.toLowerCase().match('^[a-z0-9]+(\.[_a-z0-9]+)+(\.[a-z0-9-]+)+([.{1}])(\.[a-z]{1,15})$'))) {
      return { invalidEmail: true };
    }
    return
  }


  onFileChange(event: any, type: string): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      let reader = new FileReader();
      reader.onload = e => {
        switch (type) {
          case 'logo':
            this.logoData = reader.result
            break;
        }

      };
      reader.readAsDataURL(file);

      if (type == 'licence') {
        this.licence = file
      }
      if (type == 'logo') {
        this.logo = file
      }


    }

  }

  saveProfile() {
    let fd = new FormData
    fd.append('description', this.editProfile.value.description)
    fd.append('name', this.editProfile.value.name)
    fd.append('email', this.editProfile.value.email)
    fd.append('phoneNumber', this.editProfile.value.phoneNumber)
    fd.append('ssn', this.editProfile.value.ssn)
    fd.append('fax', this.editProfile.value.fax)
    fd.append('website', this.editProfile.value.website)
    fd.append('logo', this.logo)
    fd.append('licence', this.licence)
    this.api.updateProfile(fd).subscribe((ele: any) => {
      if (ele.status = 200) {
        this.message.sucessMessage(ele.message, 2000)
        this.getProfileData()
        // sessionStorage.setItem('studioDetail',JSON.stringify(ele.data))

        this.router.navigate(['/studio-my-profile'])
        sessionStorage.setItem('studioname', this.editProfile.value.name)
      }
    })
  }

}
