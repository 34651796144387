<section class="login-sec d-flex align-items-center justify-content-center p-4">
    <div class="content-box">
       <h2 class="ff_kalam font-normal text-white mb-4">Complete Profile</h2>

       <form>
         <div class="image-sec d-flex align-items-center mb-4 pb-1">
            <div class="img-wrap d-flex align-items-center justify-content-center flex-shrink-0">
                <img class="" src="assets/images/dummyimg.svg">
            </div>
            <div class="uploadbtn fs20 text-white position-relative">
                Upload Image
                <input type="file" class="uploadinput">
            </div>
         </div>

         <div class="mb-4 pb-1">
           <label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Studio Name</label>
           <input type="text" class="form-control fs18 cmn-input input_60 ff_Roboto" placeholder="Enter studio name">
         </div>

         <div class="mb-4 pb-1">
           <label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Email Address</label>
           <input type="email" class="form-control fs18 cmn-input input_60 ff_Roboto" readonly placeholder="Enter email" value="example@gmail.com">
         </div>

         <div class="mb-5">
            <label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Phone Number</label>
            <input type="tel" class="form-control fs18 cmn-input input_60 ff_Roboto" placeholder="Enter phone number">
          </div>

         <button class="btn w-100 purple_btn btn70 fw-bold"  [routerLink]="['/studio-home']">Save</button>
       </form>
    </div>   
</section>