<!-- <app-header></app-header> -->

<!-- <section class="breadcrumb-banner">
    <div class="container">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item ps-0 active" [routerLink]="['/home']">Home</li>
                <li class="breadcrumb-item">Contact Us</li>
            </ol>
        </nav>
    </div>
</section> -->

<section class="contact-us pt-1 sec_padding_bottom">
    <div class="container">
        <div class="row">
            <div class="col-md-7">
                <div class="form-sec pe-0 pe-md-3 py-4">
                    <h4 class="fw-medium text-uppercase mb-3 pb-1">Contact us</h4>
                    <p class="fs18 fw-light color_grey mb-4 pb-3">For technical support, and missing download links, email us</p>

                    <form [formGroup]="contactForm">
                        <div class="mb-4 pb-1">
                            <input type="text" class="form-control fs18 cmn-input input_60 ff_Roboto"
                                formControlName="name" placeholder="Enter your full name"
                                (keydown)="checkEvent($event)">
                        </div>

                        <div class="mb-4 pb-1">
                            <input type="email" class="form-control fs18 cmn-input input_60 ff_Roboto" formControlName="email" placeholder="Enter email address" (keydown)="checkEvent($event)">
                            <small *ngIf="!contactForm.controls?.['email']?.valid &&
                            contactForm.controls?.['email']?.dirty">
                                <small *ngIf="contactForm.controls?.['email']?.hasError('invalidEmail') && contactForm.controls?.['email']?.value" class="error_message">Invalid email ! </small>
                                <small class="error_message" *ngIf="contactForm.controls?.['email']?.hasError('required')">This field is Mandatory ! </small>
                            </small>
                        </div>

                        <div class="mb-4 pb-3">
                            <textarea class="cmn-textarea form-control fs18" rows="4" formControlName="message" placeholder="Write message..." (keydown)="checkEvent($event)"></textarea>
                            <small *ngIf="!contactForm.controls?.['message']?.valid && contactForm.controls?.['message']?.dirty">
                                <small class="error_message" *ngIf="contactForm.controls?.['message']?.hasError('required')">This field is
                                    Mandatory !
                                </small>
                            </small>
                        </div>
                        <button role="button" type="button" class="btn w-100 purple_btn btn70 fw-bold" (click)="sendMessage()">Send Message</button>
                    </form>
                </div>
            </div>
            <div class="col-md-5">
                <div class="img-wrap d-flex align-items-center ps-0 ps-md-3">
                    <img src="assets/images/contact-us-img.svg">
                </div>
            </div>
        </div>
    </div>
</section>


<!-- <app-footer></app-footer> -->