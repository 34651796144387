import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/common/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/message/messagehandling.service';

@Component({
  selector: 'app-change-address',
  templateUrl: './change-address.component.html',
  styleUrls: ['./change-address.component.scss']
})
export class ChangeAddressComponent implements OnInit {
  changeAddress: FormGroup
  profileData: any
  constructor(private api: ApiService, private message: MessagehandlingService, private route: Router) {
    this.changeAddress = new FormGroup({
      address: new FormControl(''),
      fax: new FormControl(''),
      country: new FormControl(''),
      state: new FormControl(''),
      city: new FormControl(''),
      zipcode: new FormControl('')

    })
  }

  ngOnInit(): void {
    this.getProfile()
  }

  checkEvent(event:any){
    if(event.target.value=='' && event.which==32){
      event.preventDefault();
    }
  }

  getProfile() {
    this.api.getProfile().subscribe((res: any) => {
      this.profileData = res.data

      this.changeAddress.patchValue({
        fax: this.profileData.fax,
        country: this.profileData.country,
        state: this.profileData.state,
        address: this.profileData.address,
        city: this.profileData.city,
        zipcode: this.profileData.zipcode
      })
    })
  }


  updateAddress() {
    let fd = new FormData
    fd.append('fax', this.changeAddress.value.fax)
    fd.append('city', this.changeAddress.value.city)
    fd.append('country', this.changeAddress.value.country)
    fd.append('address', this.changeAddress.value.address)
    fd.append('state', this.changeAddress.value.state)
    fd.append('zipcode', this.changeAddress.value.zipcode)

    this.api.updateAddress(fd).subscribe((res: any) => {
      this.message.sucessMessage(res.message, 2000)
      this.getProfile()
      this.route.navigate(['/studio-my-profile'])

    })



  }

}
