import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/common/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/message/messagehandling.service';

@Component({
  selector: 'app-edit-name',
  templateUrl: './edit-name.component.html',
  styleUrls: ['./edit-name.component.scss']
})
export class EditNameComponent implements OnInit {
  editName: FormGroup
  profileData: any;
  constructor(private router: Router, private api: ApiService, private message: MessagehandlingService) {

    this.editName = new FormGroup({
      name: new FormControl('', [Validators.required]),
    })
  }
  ngOnInit(): void {
    this.getProfile()
  }

  getProfile() {
    this.api.profileUser().subscribe((res: any) => {
      this.profileData = res.data
      this.editName.patchValue({
        name: res.data.name
      })
    })
  }

  checkEvent(event: any) {
    if (event.target.value == '' && event.which == 32) {
      event.preventDefault();
    }
  }

  save() {
    let fd = new FormData
    fd.append('name', this.editName.value.name)
    this.api.updateProfileUser(fd).subscribe((res: any) => {
      this.message.sucessMessage(res.message, 2000)
      this.router.navigate(['/my-profile'])
    })

  }

}
