import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NavigationExtras, Router } from '@angular/router';
import { ApiService } from 'src/app/common/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/message/messagehandling.service';
import { DeleteModelComponent } from '../model/delete-model/delete-model.component';

@Component({
  selector: 'app-models',
  templateUrl: './models.component.html',
  styleUrls: ['./models.component.scss']
})
export class ModelsComponent implements OnInit {

  constructor(public dialog: MatDialog, private api: ApiService, private message: MessagehandlingService, private router: Router) { }
  modalData: any[] = []
  modalImageUrl: any
  ngOnInit(): void {
    this.modelList();
  }


  modelList() {
    let fd = new FormData
    fd.append('pageNo', "0")
    this.api.modelData(fd).subscribe((ele: any) => {
      if (ele) {
        this.modalData = ele.data
        // this.message.sucessMessage(ele.message, 2000)
        this.modalImageUrl = this.api.modelImageUrl
      }
    })
  }


  deleteModel(id: any) {
    this.dialog.open(DeleteModelComponent, {
      panelClass: ['', 'delete_model'],
      disableClose: true,
      width: '500px',
      data: id
      // height: '100%',
    });
  }

  editModel(id: any) {
    sessionStorage.setItem('id',id)

     this.router.navigate([`/studio-edit-model/${id}`])
    // let navigationExtras: NavigationExtras = {
    //   queryParams: { 'id': id },
    // };
    // this.router.navigate(['/studio-edit-model'], navigationExtras);
    // [routerLink]="['/studio-edit-model']"
  }

  // [routerLink]="['/studio-model-details']"
  modelDetail(id:any){
    this.router.navigate([`/studio-model-details/${id}`])

  }
}

