import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/common/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/message/messagehandling.service';
declare const $: any;

@Component({
  selector: 'app-studio-details',
  templateUrl: './studio-details.component.html',
  styleUrls: ['./studio-details.component.scss']
})
export class StudioDetailsComponent implements OnInit {
  id: any;
  studioData: any;
  clips: any;
  models: any;
  baseUrl: any;
  modelUrl: any;
  clipUrl: any;

  constructor(private api: ApiService, private router: Router, private activeroute: ActivatedRoute,private message:MessagehandlingService) {
    this.activeroute.params.subscribe((params: any) => {
      this.id = params['id'];
      if (this.id) {
        this.studioDetails(this.id)
      } else {
        this.router.navigate(['/']);
      }
    })
  }

  ngOnInit(): void {
    this.baseUrl = this.api.licenceAndLogoUrl
    this.modelUrl = this.api.modelImageUrl
    this.clipUrl = this.api.clipsImageUrl
  }

  studioDetails(id: any) {
    ;
    // let fd = new FormData
    this.api.studioDetailUser(id).subscribe((res: any) => {
      this.studioData = res?.data
      this.clips = res.data?.clips
      this.models = res.data?.models
      setTimeout(() => {
        $('.commonVideo_slider').not('.slick-initialized').slick({
          slidesToShow: 3,
          slidesToScroll: 1,
          autoplay: false,
          autoplaySpeed: 3000,
          dots: true,
          infinite: false,
          // centerMode: true,
          // variableWidth: true,
          prevArrow: $('.prev'),
          nextArrow: $('.next'),
          responsive: [
            {
              breakpoint: 1400,
              settings: {
                slidesToShow: 3,

              }
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 2,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        });
        $('.commonmodel_slider').not('.slick-initialized').slick({
          slidesToShow: 3,
          slidesToScroll: 1,
          autoplay: false,
          autoplaySpeed: 3000,
          dots: true,
          infinite: false,
          // centerMode: true,
          // variableWidth: true,
          prevArrow: $('.prev'),
          nextArrow: $('.next'),
          responsive: [
            {
              breakpoint: 1400,
              settings: {
                slidesToShow: 3,

              }
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 2,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        });
      }, 100);
    })
  }



  modelDetail(id: any) {
    this.router.navigate([`/model-details/${id}`])

  }

  videoDetail(id: any) {
    this.router.navigate([`/video-detail/${id}`])

  }

  
  allmodelDetail(id:any){
    this.router.navigate([`/see-all-models/${id}`])

  }

  allClipDetail(id:any){
    this.router.navigate([`/see-all-videos/${id}`])

  }
  // [routerLink]="['/see-all-models']"

  fav(id: any, isFav: any) {
    
    let fd = new FormData
   
    fd.append('CID', id)
    if (isFav==1) {
      fd.append('status', "0")
      $(".likeContainer").toggleClass("heart_filled");
    }
    else {
      fd.append('status', "1")

      $(".likeContainer").removeClass("heart_filled");

    }
    this.api.addFavourite(fd).subscribe((res: any) => {
      this.message.sucessMessage(res.message, 1000)
      this.studioDetails(this.id)

      


    })
    // $(".likeContainer").toggleClass("heart_filled");
  }
}
