<section class="login-sec d-flex align-items-center justify-content-center p-4">
  <div class="content-box">
    <div class="top-sec d-block lg-d-flex align-items-center justify-content-between mb-4">
      <h2 class="ff_kalam font-normal text-white mb-0">Sign Up</h2>
      <div class="right-sec">
        <p class="opacity-50 mb-2 fw-normal">Already have an account? </p>
        <h5 role="button" class="fs18 fw-medium mb-0 d-flex align-items-center" [routerLink]="['/user-login']">
          <span class="pe-2">Login</span> <svg class="ms-1" width="20" height="14" viewBox="0 0 20 14" fill="none">
            <path d="M1 7L18.1429 7" stroke="white" stroke-width="2" stroke-linecap="round" />
            <path d="M13.8571 13L19 7L13.8571 1" stroke="white" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </h5>
      </div>
    </div>
    <form [formGroup]="memberForm">
      <div class="mb-4 pb-1">
        <ng-container><label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Full Name <span
              class="color_danger">*</span></label>
          <input type="text" class="form-control fs18 cmn-input input_60 ff_Roboto" formControlName="name"
            placeholder="Enter your full name" (keydown)="checkEvent($event)"></ng-container>
        <small *ngIf="!memberForm.controls?.['name']?.valid &&
              memberForm.controls?.['name']?.dirty" class="error_main_div">
          <small *ngIf="memberForm.controls?.['name']?.hasError('required')" class="error_message">This field is
            Mandatory !</small>
        </small>
      </div>

      <div class="mb-4">
        <ng-container> <label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Email Address
            <span class="color_danger">*</span></label>
          <input type="text" class="form-control fs18 cmn-input input_60 ff_Roboto" placeholder="Enter email address"
            formControlName="email" (keydown)="checkEvent($event)"></ng-container>
        <small *ngIf="!memberForm.controls?.['email']?.valid &&
              memberForm.controls?.['email']?.dirty">
          <small
            *ngIf="memberForm.controls?.['email']?.hasError('invalidEmail') && memberForm.controls?.['email']?.value"
            class="error_message">Invalid email !
          </small>
          <small class="error_message" *ngIf="memberForm.controls?.['email']?.hasError('required')">This field
            is Mandatory !
          </small>
        </small>
      </div>
      <div class="mb-4">
        <ng-container>
          <label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Date of birth</label>
        <!-- Regular text input with date picker -->
        <input type="text" class="form-control fs18 cmn-input input_60 ff_Roboto"
            placeholder="MM-DD-YYYY" formControlName="dob" bsDatepicker>
  
          <!-- <label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Date of birth</label>
          <input type="date" class="form-control fs18 cmn-input input_60 ff_Roboto" formControlName="dob"
            placeholder="DD/MM/YYYY" [ngModel]="theDate | date:'yyyy-MM-dd'" name="theDate"
            (ngModelChange)="theDate = $event"> -->
        </ng-container>
        <small *ngIf="!memberForm.controls?.['dob']?.valid &&
            memberForm.controls?.['dob']?.dirty" class="error_main_div">
          <small *ngIf="memberForm.controls?.['dob']?.hasError('required')" class="error_message">This field is Mandatory !</small>
        </small>
      </div>

      <div class="mb-4 position-relative">
        <ng-container>
          <label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Password <span
              class="color_danger">*</span></label>
          <input [type]="showPassword ? 'text' : 'password'" class="form-control fs18 cmn-input input_60 ff_Roboto"
            placeholder="Enter password" formControlName="password" (keydown)="checkEvent($event)">
        </ng-container>
        <small *ngIf="!memberForm.controls?.['password']?.valid &&
              memberForm.controls?.['password']?.dirty" class="error_main_div">
          <small *ngIf="memberForm.controls?.['password']?.hasError('required')" class="error_message">This
            field
            is
            Mandatory !
          </small>
          <small *ngIf="memberForm.controls?.['password']?.hasError('pattern')" class="error_message">Password must
            contain uppercase, lowercase, number and special characters and min length should be 10.
          </small>
        </small>
        <span class="eye-icon" (click)="togglePassword()">
          <img [src]="showPassword ? 'assets/images/eye-open.png' : 'assets/images/eye-close.png'" 
            alt="Toggle Password"/>
        </span>
      </div>

      <div class="mb-4 position-relative">
        <ng-container>
          <label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Confirm Password <span
              class="color_danger">*</span></label>
          <input [type]="showPassword1 ? 'text' : 'password'" class="form-control fs18 cmn-input input_60 ff_Roboto"
            placeholder="Enter confirm password" formControlName="confirmPassword" (keydown)="checkEvent($event)">
        </ng-container>
        <small *ngIf="!memberForm.controls?.['confirmPassword']?.valid &&
           memberForm.controls?.['confirmPassword']?.dirty" class="error_main_div">
          <small
            *ngIf="memberForm.controls?.['confirmPassword']?.invalid && memberForm.controls?.['confirmPassword']?.errors?.['ConfirmPassword']"
            class="error_message">Passwords must match!</small>
          <small *ngIf="memberForm.controls?.['confirmPassword']?.hasError('required')" class="error_message">This field
            is Mandatory !
          </small>
        </small>
        <span class="eye-icon" (click)="togglePassword1()">
          <img [src]="showPassword1 ? 'assets/images/eye-open.png' : 'assets/images/eye-close.png'" 
            alt="Toggle Password"/>
        </span>
      </div>

      <div class="terms-sec d-flex align-items-center mb-4 pb-2">
        <!-- <mat-checkbox class="me-2" color="primary"></mat-checkbox> -->
        <mat-checkbox class="me-2" color="primary" formControlName="acceptCheckbox">
        </mat-checkbox>
        <p class="h6 mb-0 fw-normal" role="button">Accept our <span class="text-decoration-underline"
            (click)="termsConditions()">terms and
            conditions</span></p>
      </div>



      <button class="btn w-100 purple_btn btn70 fw-bold" type="submit" (click)="becomeMember()"
        [disabled]="!memberForm.valid">Become A
        Member</button>
    </form>
  </div>
</section>