import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/common/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/message/messagehandling.service';

@Component({
  selector: 'app-all-categories',
  templateUrl: './all-categories.component.html',
  styleUrls: ['./all-categories.component.scss']
})
export class AllCategoriesComponent implements OnInit {
  categoriesData: any = []
  imageUrl: any

  constructor(private router: Router, private api: ApiService, private message: MessagehandlingService) { }


  ngOnInit(): void {
    this.categories()
    this.imageUrl = this.api.userCatFilePath
  }


  categories() {
    this.api.categories().subscribe((res: any) => {
      this.categoriesData = res.data

    })
  }

  categoryDetail(data: any) {
    
    this.router.navigate([`/category-list/${data.id}`])
  }


}
