import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/common/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/message/messagehandling.service';

@Component({
  selector: 'app-edit-email',
  templateUrl: './edit-email.component.html',
  styleUrls: ['./edit-email.component.scss']
})
export class EditEmailComponent implements OnInit {
  editEmail: FormGroup
  profileData: any;
  constructor(private formBuilder: FormBuilder,private router: Router, private api: ApiService, private message: MessagehandlingService) {

    this.editEmail = this.formBuilder.group({
      email: ['', [Validators.required, this.emailValidator.bind(this)]]
    })
  }
  ngOnInit(): void {
    this.getProfile()
  }

  emailValidator(control: FormControl) {
    if (!(control.value.toLowerCase().match('^[a-z0-9]+(\.[_a-z0-9]+)+(\.[a-z0-9-]+)+([.{1}])(\.[a-z]{1,15})$'))) {
      return { invalidEmail: true };
    }
    return
  }

  checkEvent(event: any) {
    if (event.target.value == '' && event.which == 32) {
      event.preventDefault();
    }
  }

  getProfile() {
    setTimeout(() => {
      this.api.profileUser().subscribe((res: any) => {
        this.profileData = res.data
        // this.logoData = `${this.api.uerFilePath}${this.profileData.logo}`;

        this.editEmail.patchValue({
          email: res.data.email
        })
      })
    }, 2000);
  }

  save() {
    let fd = new FormData
    fd.append('email', this.editEmail.value.email)
    this.api.updateProfileUser(fd).subscribe((res: any) => {
      this.message.sucessMessage(res.message, 2000)
      this.router.navigate(['/my-profile'])
    })

  }

}
