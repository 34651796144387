import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/common/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/message/messagehandling.service';

@Component({
  selector: 'app-delete-model',
  templateUrl: './delete-model.component.html',
  styleUrls: ['./delete-model.component.scss']
})
export class DeleteModelComponent implements OnInit {
  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<DeleteModelComponent>, @Inject(MAT_DIALOG_DATA) public data: { block: any }, private api: ApiService, 
  private router: Router, private message: MessagehandlingService) {
  }

  

  ngOnInit(): void {
  }

  delete(){
    this.api.deleteModel(this.data).subscribe((res:any)=>{
      if(res){
        this.message.sucessMessage(res.message,2000)
        window.location.reload()
      }
      
    })
  }


  cancel(){
    this.dialogRef.close()
  }
}
