import { Component, OnInit, ViewChild } from '@angular/core';
import { ApexAxisChartSeries, ApexChart, ApexTitleSubtitle, ChartComponent, ApexPlotOptions, ApexDataLabels, ApexYAxis, ApexXAxis, ApexFill, ApexTooltip, ApexStroke, ApexLegend } from 'ng-apexcharts';
import { ApiService } from 'src/app/common/api-service/api.service';
declare const $: any;

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
};

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  @ViewChild("chart") chart: ChartComponent | any;
  public chartOptions: Partial<ChartOptions> | any;
  clipsData: any;
  currentYearSale: any;
  previousYearSale: any;
  currentData: any = []
  previousData: any = []
  revenue: any;
  baseUrl = 'https://admin.chokechamber.com/'
  imageUrl = 'storage/app/public/uploads/clips/'
  monthData: any=[]
  totalSum: any;
  constructor(private api: ApiService) {
    // this.chartOptions = {
    //   series: [
    //     {
    //       name: "Revenue",
    //       data: [20, 80, 40, 150, 130, 105, 91, 60, 100, 180, 98, 87]
    //     },

    //   ],
    //   chart: {
    //     type: "bar",
    //     height: 330,
    //     width: '100%',

    //     toolbar: {
    //       show: false,
    //     },
    //     parentHeightOffset: 0,
    //   },

    //   grid: {
    //     show: false
    //   },

    //   plotOptions: {
    //     bar: {
    //       horizontal: false,
    //       columnWidth: "40%",
    //       distributed: false,
    //     }
    //   },
    //   dataLabels: {
    //     enabled: false
    //   },

    //   stroke: {
    //     show: false,
    //     width: 0,
    //   },

    //   xaxis: {
    //     categories: ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"],
    //     tickPlacement: 'left',
    //     labels: {
    //       show: true,
    //       style: {
    //         colors: ['#949494', '#949494', '#949494', '#949494', '#949494', '#949494', '#949494', '#949494', '#949494', '#949494', '#949494', '#949494'],
    //         fontSize: '11px',
    //         fontFamily: "'Roboto', sans-serif",
    //         fontWeight: 500,
    //         cssClass: 'apexcharts-xaxis-label',
    //       },
    //       offsetX: 0,
    //       offsetY: 0,
    //     },
    //     axisBorder: {
    //       show: true,
    //       color: '#272727',
    //       height: 1,
    //       width: '100%',
    //       offsetX: 0,
    //       offsetY: 0
    //     },
    //     axisTicks: {
    //       show: true,
    //       borderType: 'solid',
    //       color: '#272727',
    //       height: 9,
    //       offsetX: 0,
    //       offsetY: 0
    //     },
    //   },

    //   yaxis: {
    //     show: true,
    //     axisBorder: {
    //       show: true,
    //       color: '#272727',
    //       offsetX: 0,
    //       offsetY: 0
    //     },
    //     axisTicks: {
    //       show: true,
    //       borderType: 'solid',
    //       color: '#272727',
    //       width: 9,
    //       offsetX: 0,
    //       offsetY: 0
    //     },
    //     labels: {
    //       show: true,
    //       align: 'right',
    //       minWidth: 0,
    //       maxWidth: 160,
    //       style: {
    //         colors: ['#949494'],
    //         fontSize: '11px',
    //         fontWeight: 500,
    //         fontFamily: "'Roboto', sans-serif",
    //         cssClass: 'apexcharts-yaxis-label',
    //       },
    //       offsetX: 0,
    //       offsetY: 0,
    //       rotate: 0,
    //       formatter: (val: any) => { return val },
    //     },
    //   },

    //   fill: {
    //     opacity: 1,
    //     colors: ['#776ACF']
    //   },
    //   tooltip: {

    //     style: {
    //       fontSize: '15px',
    //     },
    //     y: {
    //       formatter: function (val: any) {
    //         return "$ " + val + " thousands";
    //       }
    //     },
    //   }
    // };
  }
  yearsArray: number[] = [];
  startYear: number = 2025;
  endYear: number = 2050;



  ngOnInit(): void {
    this.revenueDetail()
    this.yearData()

  }


  yearData() {
    for (let year = this.startYear; year <= this.endYear; year++) {
      this.yearsArray.push(year);
    }
  }

  revenueDetail() {
    let fd = new FormData
    fd.append('year', '2025')
    this.api.revenue(fd).subscribe((res: any) => {
      this.clipsData = res.data.clips
      this.calculateTotalSum()
      this.currentYearSale = res.data.currentYearSale
      this.monthData = res.data.monthData
      this.revenue = res.data.revenue
      this.currentYearSale.forEach((element: any) => {
        this.currentData.push(element.value)

      });
      this.chartData(this.currentData)
      $(document).ready(function () {
        $('#revenueHistory').DataTable();
      });


    })
  }


  calculateTotalSum(): void {
    this.totalSum = this.clipsData.reduce((accumulator: any, currentItem: any) => {
      return accumulator + currentItem.total;
    }, 0);
  }

  graphFilter(event: Event) {
    this.currentData = [];
    const selectedYear = (event.target as HTMLSelectElement).value;
    if (selectedYear) {
      let fd = new FormData();
      fd.append('year', selectedYear);
      this.api.revenue(fd).subscribe((res: any) => {
        this.currentYearSale = res.data.currentYearSale;

        this.currentYearSale.forEach((element: any) => {
          this.currentData.push(element.value);
        });

        this.chartOptions.series[0].data = this.currentData;
        this.chartData(this.currentData);
      });
    }
  }

  chartData(data: any) {

    this.chartOptions = {
      series: [
        {
          name: "Revenue",
          data: data
        },

      ],
      chart: {
        type: 'bar',
        height: 330,
        width: '100%',

        toolbar: {
          show: false,
        },
        parentHeightOffset: 0,
      },

      grid: {
        show: false
      },

      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "40%",
          distributed: false,
        }
      },
      dataLabels: {
        enabled: false
      },

      stroke: {
        show: false,
        width: 0,
      },

      xaxis: {
        categories: ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"],
        tickPlacement: 'left',
        labels: {
          show: true,
          style: {
            colors: ['#949494', '#949494', '#949494', '#949494', '#949494', '#949494', '#949494', '#949494', '#949494', '#949494', '#949494', '#949494'],
            fontSize: '11px',
            fontFamily: "'Roboto', sans-serif",
            fontWeight: 500,
            cssClass: 'apexcharts-xaxis-label',
          },
          offsetX: 0,
          offsetY: 0,
        },
        axisBorder: {
          show: true,
          color: '#272727',
          height: 1,
          width: '100%',
          offsetX: 0,
          offsetY: 0
        },
        axisTicks: {
          show: true,
          borderType: 'solid',
          color: '#272727',
          height: 9,
          offsetX: 0,
          offsetY: 0
        },
      },

      yaxis: {
        min: 0,

        show: true,
        axisBorder: {
          show: true,
          color: '#272727',
          offsetX: 0,
          offsetY: 0
        },
        axisTicks: {
          show: true,
          borderType: 'solid',
          color: '#272727',
          width: 9,
          offsetX: 0,
          offsetY: 0
        },
        labels: {
          show: true,
          align: 'right',
          minWidth: 0,
          maxWidth: 160,
          style: {
            colors: ['#949494'],
            fontSize: '11px',
            fontWeight: 500,
            fontFamily: "'Roboto', sans-serif",
            cssClass: 'apexcharts-yaxis-label',
          },
          offsetX: 0,
          offsetY: 0,
          rotate: 0,
          step: 5,
          formatter: (val: any) => { return Math.floor(val) },
        },
      },

      fill: {
        opacity: 1,
        colors: ['#776ACF']
      },
      tooltip: {

        style: {
          fontSize: '15px',
        },
        y: {
          formatter: function (val: any) {
            return "$ " + val;
          }
        },
      }
    };
  }


  // download() {
  //   let headers: any = []
  //   let dynamicData: any = []
  //   let fileName = 'transaction-detail.csv';
  //   let a = this.clipsData
  //   a.forEach((element: any) => {
  //     headers.push(element.field)
  //     dynamicData.push(element.value)
  //   });
  //   let columnNames = ["TransactionId", "Title", "Date", "Price($)"];

  //   let header = columnNames.join(',');
  //   let csv = header;

  //   csv += '\r\n';
  //   var alldata: any = []
  //   alldata.push(this.clipsData)

  //   alldata[0].map((c: any) => {
  //     // let valueData: any = dynamicData
  //     // csv += valueData.join(',');
  //     csv += [c["orderNumber"], c["title"], c["created_at"], c["total"]].join(',');
  //     csv += '\r\n';
  //   })
  //   var blob = new Blob([csv], { type: "text/csv" });
  //   var link = document.createElement("a");
  //   if (link.download !== undefined) {
  //     var url = URL.createObjectURL(blob);
  //     link.setAttribute("href", url);
  //     link.setAttribute("download", fileName);
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   }
  // }
  download() {
    let headers: any = [];
    let dynamicData: any = [];
    let fileName = 'transaction-detail.csv';
    let a = this.clipsData;
    a.forEach((element: any) => {
      headers.push(element.field);
      dynamicData.push(element.value);
    });
    let columnNames = ["TransactionId", "Title", "Date", "Price($)"];

    let header = columnNames.join(',');
    let csv = header;

    csv += '\r\n';
    var alldata: any = [];
    alldata.push(this.clipsData);

    let totalPrice = 0; // Initialize a variable to calculate the total price

    alldata[0].map((c: any) => {
      csv += [c["orderNumber"], c["title"], c["created_at"], c["total"]].join(',');
      totalPrice += parseFloat(c["total"]); // Add the price to the total
      csv += '\r\n';
    });

    // Add the total row
    csv += ',,Total,' + totalPrice.toFixed(2) + '\r\n';

    var blob = new Blob([csv], { type: "text/csv" });
    var link = document.createElement("a");
    if (link.download !== undefined) {
      var url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }


}
