import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import {MatDialogModule} from '@angular/material/dialog';
import { HomeComponent } from '../studio/components/home/home.component';
import { HeaderComponent } from '../studio/components/header/header.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgxOtpInputModule } from 'ngx-otp-input';
import { LoginComponent } from '../studio/components/login/login.component';
import { ClipsComponent } from '../studio/components/clips/clips.component';
import { ModelsComponent } from '../studio/components/models/models.component';
import { ClipDetailsComponent } from '../studio/components/clip-details/clip-details.component';
import { AddClipComponent } from '../studio/components/add-clip/add-clip.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NgxVideoListPlayerModule } from 'ngx-video-list-player';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AddModelComponent } from '../studio/components/add-model/add-model.component';
import { ModelDetailsComponent } from '../studio/components/model-details/model-details.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RevenueHistoryDetailsComponent } from '../studio/components/revenue-history-details/revenue-history-details.component';
import { CompleteProfileComponent } from '../studio/components/complete-profile/complete-profile.component';
import { MyProfileComponent } from '../studio/components/my-profile/my-profile.component';
import { EditClipComponent } from '../studio/components/edit-clip/edit-clip.component';
import { EditModelComponent } from '../studio/components/edit-model/edit-model.component';
import { EditProfileComponent } from '../studio/components/edit-profile/edit-profile.component';
import { ChangePasswordComponent } from '../studio/components/change-password/change-password.component';
import { ChangeAddressComponent } from '../studio/components/change-address/change-address.component';
import { DeleteModelComponent } from '../studio/components/model/delete-model/delete-model.component';
import { DeleteClipComponent } from '../studio/components/model/delete-clip/delete-clip.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { StudioRoutingModule } from './studio-routing.module';
import { StudioFooterComponent } from './components/studio-footer/studio-footer.component';
import { FooterComponent } from '../member/components/footer/footer.component';
import { CoreModule } from 'src/app/common/interceptor/core.module';
import { BrowserModule } from '@angular/platform-browser';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { StudioVerifyOtpComponent } from './components/studio-verify-otp/studio-verify-otp.component';
import {MatChipsModule} from '@angular/material/chips';
import {MatSelectModule} from '@angular/material/select';
import { ImageCropperComponent } from './components/image-cropper/image-cropper.component';
import { ImageCropperModule } from 'ngx-image-cropper';

// import { AddmodelModule } from './components/add-model/add-model.mdule';

@NgModule({
  declarations: [
    HomeComponent,
    HeaderComponent,
    LoginComponent,
    ClipsComponent,
    ModelsComponent,
    ClipDetailsComponent,
   AddClipComponent,
     AddModelComponent,
    ModelDetailsComponent,
    RevenueHistoryDetailsComponent,
    CompleteProfileComponent,
    MyProfileComponent,
    EditClipComponent,
    EditModelComponent,
    EditProfileComponent,
    ChangePasswordComponent,
    ChangeAddressComponent,
    DeleteModelComponent,
    DeleteClipComponent,
    StudioFooterComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    StudioVerifyOtpComponent,
    ImageCropperComponent,
  
  ],
  imports: [
    StudioRoutingModule,
    NgApexchartsModule,
    NgxVideoListPlayerModule,
    BrowserAnimationsModule,
    FormsModule,
    BrowserModule,
    ReactiveFormsModule,
    CoreModule,
    MatDialogModule,
    NgxOtpInputModule,
    MatChipsModule,
    ImageCropperModule,
    MatSlideToggleModule,
    MatSelectModule,
    // AddmodelModule
  ],
  exports:[],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy}],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class StudioModule { }
