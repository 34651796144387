<app-header></app-header>

<section class="breadcrumb-banner">
    <div class="container">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item ps-0 active" [routerLink]="['/home']">Home</li>
                <li class="breadcrumb-item active" [routerLink]="['/token-list']">Token List</li>
                <li class="breadcrumb-item">Admin Token</li>
            </ol>
        </nav>
    </div>
</section>

<section class="add-token-sec sec_padding_bottom">
    <div class="container">
        <div class="main-box mt-4 p-5">
            <div class="row">

                <h2 class="ff_kalam fw-regular text-center mb-5"> Tokens </h2>


                <div class="col-md-6">
                    <div class="img-wrap d-flex align-items-center justify-content-center">
                        <img src="assets/images/add-token-img.svg">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-sec">
                        <form>
                            <div class="mb-4 pb-1 position-relative token_info">



                                <p>These tokens are Granted to you by the admin. you can use these tokens to purchase
                                    the respective studio token.</p>




                            </div>



                            <div class="card_lists mt-4">





                                <div class="debit-card d-flex align-items-center mt-2" *ngFor="let item of tokenDetail">
                                    <div class="left-sec studio_img">

                                        <img class="fit-img" [src]="baseLogoUrl + item?.logo ">

                                    </div>
                                    <div class="studio_info ms-3">

                                        <h4>{{item?.name}} </h4>
                                        <p> <span class="token_t"> Token Takens : </span> <span class="coin fs18"> <img
                                                    src=" assets/images/coin.svg" alt=""> {{item?.token}} </span> </p>

                                    </div>



                                </div>


                                <!-- <div class="debit-card d-flex align-items-center mt-2">
                                    <div class="left-sec studio_img">

                                        <img class="fit-img" src="assets/images/studio5.png">

                                    </div>
                                    <div class="studio_info ms-3">

                                        <h4> Studio Name</h4>
                                        <p> <span class="token_t"> Token Takens : </span> <span class="coin fs18"> <img
                                                    src=" assets/images/coin.svg" alt=""> 30 </span> </p>

                                    </div>



                                </div>
                                <div class="debit-card d-flex align-items-center mt-2">
                                    <div class="left-sec studio_img">

                                        <img class="fit-img" src="assets/images/studio5.png">

                                    </div>
                                    <div class="studio_info ms-3">

                                        <h4> Studio Name</h4>
                                        <p> <span class="token_t"> Token Takens : </span> <span class="coin fs18"> <img
                                                    src=" assets/images/coin.svg" alt=""> 30 </span> </p>

                                    </div>



                                </div> -->


                            </div>





                        </form>





                    </div>
                </div>
                <div class="notes">


                    <h2 class="ff_kalam fw-regular mb-0"> Note </h2>

                    <p class="mb-0"> These tokens can be used as follows:</p>

                    <ul class="p-0 fs14 color_grey">
                        <li>
                            (1) Common tokens can be used to purchase any video of any studio.
                        </li>
                        <li>
                            (2) Admin token can be used to purchase only the token given for the particular studio as
                            shown in below list.
                        </li>
                        <!-- <li>
                            (3) Bonus token can be used only to purchase the choke chamber videos.
                        </li> -->

                    </ul>
                </div>



            </div>
        </div>
    </div>
</section>

<app-footer></app-footer>