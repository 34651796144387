import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/common/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/message/messagehandling.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  showPassword: boolean = false;

  togglePassword() {
    this.showPassword = !this.showPassword;
  }
  loginForm: FormGroup

  constructor(private formBuilder: FormBuilder, private router: Router, private api: ApiService, private message: MessagehandlingService) {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, this.emailValidator.bind(this)]],
      password: ['', [Validators.required]],
    })

  }
  ngOnInit(): void {
  }


  emailValidator(control: FormControl) {
    if (!(control.value.toLowerCase().match('^[a-z0-9]+(\.[_a-z0-9]+)+(\.[a-z0-9-]+)+([.{1}])(\.[a-z]{1,15})$'))) {
      return { invalidEmail: true };
    }
    return
  }

  
  checkEvent(event:any){
    if(event.target.value=='' && event.which==32){
      event.preventDefault();
    }
  }

  login() {
    // alert("")
    let fd = new FormData
    fd.append('email', this.loginForm.value.email)
    fd.append('password', this.loginForm.value.password)
    this.api.login(fd).subscribe((ele: any) => {
      if (ele.status == 200) {
        this.message.sucessMessage(ele.message, 2000)
        sessionStorage.setItem('studioname', ele.data.name)
        localStorage.setItem('studioToken', ele.data.token)
        // sessionStorage.setItem('studioDetail',JSON.stringify(ele.data))
        this.router.navigate(['/studio-home'])
      } else {
        this.message.errorMessage(ele.message, 2000)
      }
    })
  }
}
