<app-header></app-header>

<section class="Model_details_section mb-4 pb-5">
    <div class="container">
        <button class="backbtn" [routerLink]="['/studio-models']"><img src="assets/images/studiomodule/backbtn.svg"></button>


        <div class="row align-items-start">
            <div class="col-lg-4">
              <div class="model_img">
                <img [src]="image" class="fit-img" />
              </div>
            </div>
            <div class="col-lg-8">
              <h3 class="fw-light mb-1 ">{{modelData?.name}}</h3>
              <div class="d-flex align-items-center gap-4 subtext mb-2">
                <p class="mb-2">Gender: <span>{{modelData?.gender}}</span></p>
              </div>
              <p class="fw-light color_grey">Measurements:<span> {{modelData?.username}}</span>
              </p>
             <div class="d-flex align-items-center gap-4 subtext">
               <p class="mb-2">Available for customs: <span *ngIf="modelData?.isCustom=='0'">No</span><span *ngIf="modelData?.isCustom=='1'">Yes</span></p>
             </div>
             <p class="fw-light color_grey bio" *ngIf="modelData?.bio">Bio:
              {{modelData?.bio}}
              <!-- <hr> -->
              <div *ngIf="tags.length!=0" class="artist_tags d-flex">
                <p class="mb-0" >Tags:</p>
                <div class="d-flex flex-wrap"><span class="color_pink" *ngFor="let tag of tags">{{tag.name}}</span>
                </div>
              </div>
            </div>
          </div>

<!-- 
        <div class="row align-items-center">
            <div class="col-lg-6">
               <div class="artist_img position-relative">
                   <img src="assets/images/model-1.jpg"/>
    
                   <div class="artist_personal_info position-relative d-flex">
                   <div class="info me-4">
                    <p class="mb-2 fw-bold">Age</p>
                    <h4 class="fw-light m-0">29</h4>
                  </div>
                </div>
               </div>
               
            </div>
            <div class="col-lg-6">
                <h3 class="fw-light mb-4">Erika Jordan</h3>
                <p class="fw-light text-white bio">
                    Erika Jordan is an amazing, strong woman. She is an amazing wrestler and referee for our wrestling site Ultimate Surrender. If you see her naked you will notice that her body is all rippling muscle - but not without softness and curves too. In bondage these muscles really stand out as she is straining to orgasm, if she is allowed. For the most part, she can be seen completely dominating on the wrestling mats. She even dominates when it comes to champion wrestlers like Izamar Guttierez. There have been many times when she has been so victorious that the champion fuck has had to be taken onto another channel like Everything Butt or Whipped Ass so she can get her full revenge.
                </p>
    
                <div class="artist_tags d-flex">
                    <p class="mb-0">Tags:</p>   
                    <div class="d-flex flex-wrap"><span class="color_pink">Brunette</span> <span class="color_pink">Muscle</span> <span class="color_pink"> Muscular</span> <span class="color_pink">Natural Boobs</span>   
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</section>

<app-studio-footer> </app-studio-footer> 