import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/common/api-service/api.service';

@Component({
  selector: 'app-bonus-token',
  templateUrl: './bonus-token.component.html',
  styleUrls: ['./bonus-token.component.scss']
})
export class BonusTokenComponent implements OnInit {
  activityDetail: any;
  tokenDetail: any;
  baseLogoUrl=this.api.licenceAndLogoUrl
  constructor(private api:ApiService) { }

  ngOnInit(): void {
    this.tokenList()
  }

  tokenList(){
    this.api.tokenDetail().subscribe((res:any)=>{
      this.activityDetail=res.activity
      this.tokenDetail=res.data.studioBasedTokens      
    })
  }

}
