import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/common/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/message/messagehandling.service';
declare const $: any;
@Component({
  selector: 'app-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.scss']
})
export class FavoritesComponent implements OnInit {
  favData: any;
  clipUrl: any
  red: boolean = false;
  constructor(private api: ApiService, private message: MessagehandlingService, private router: Router) { }

  ngOnInit(): void {
    this.clipUrl = this.api.clipsImageUrl
    this.favorite()
  }


  favor(id: any) {
    
    let fd = new FormData
    fd.append('status', "0")
    fd.append('CID', id)
 
    // $(".heart").removeClass("heart_filled");


    this.api.addFavourite(fd).subscribe((res: any) => {
      this.message.sucessMessage(res.message, 1000)
      this.red = !this.red
      this.favorite()
    })
  }

  videoDetail(id: any) {
    this.router.navigate([`/video-detail/${id}`])
  }

  favorite() {
    let fd = new FormData
    fd.append('pageNo', '0')
    this.api.favouriteList(fd).subscribe((res: any) => {
      this.favData = res.data
    })
  }






}
