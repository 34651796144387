import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/common/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/message/messagehandling.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  contactForm: FormGroup
  constructor(private formBuilder: FormBuilder, private router: Router, private api: ApiService, private message: MessagehandlingService) {
    this.contactForm = this.formBuilder.group({
      email: ['', [Validators.required, this.emailValidator.bind(this)]],
      name: ['', [Validators.required]],
      message: ['', [Validators.required]]
    })
  }
  ngOnInit(): void {
  }


  emailValidator(control: FormControl) {
    if (!(control.value.toLowerCase().match('^[a-z0-9]+(\.[_a-z0-9]+)+(\.[a-z0-9-]+)+([.{1}])(\.[a-z]{1,15})$'))) {
      return { invalidEmail: true };
    }
    return
  }

  checkEvent(event: any) {
    if (event.target.value == '' && event.which == 32) {
      event.preventDefault();
    }
  }


  sendMessage() {
    if (this.contactForm.invalid) {
      this.message.errorMessage('Please fill all details', 1000)
    } else {
      let fd = new FormData
      fd.append('name', this.contactForm.value.name)
      fd.append('email', this.contactForm.value.email)
      fd.append('message', this.contactForm.value.message)
      this.api.contactus(fd).subscribe((res: any) => {
        this.message.sucessMessage(res.message, 2000)
        this.contactForm.reset()
      })
    }
  }

}
