import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/common/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/message/messagehandling.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  memberName: any
  categoriesData: any = []


  constructor(private router: Router, private api: ApiService, private message: MessagehandlingService) { }

  ngOnInit(): void {
    this.memberName = sessionStorage.getItem('membername')
    this.categories()
  }



  categories() {
    this.api.categories().subscribe((res: any) => {
      this.categoriesData = res.data

    })
  }


  categoryDetail(id: any) {
    this.router.navigate([`/category-list/${id}`])
  }


  logOut() {
    let fd = new FormData
    this.api.logout(fd).subscribe((res: any) => {
      this.message.sucessMessage(res.message, 2000)
      localStorage.clear();
      sessionStorage.clear();
      this.router.navigate(['/landingpage'])
    })


  }
}
