<app-header></app-header>

<section class="models py-5">
    <div class="container">
        <button class="btn red_btn btn60 fw-bold mx-auto d-block w-100 shadow-none mb-5" [routerLink]="['/studio-add-model']">Add Models</button>

        <div class="row g-4">
            <div class="sol-sm-12 col-md-6 col-lg-4" *ngFor="let data of modalData">
                <div class="model-box">
                    <div class="img-wrap modal_img position-relative">
                        <img *ngIf="data?.profile" class="fit-img" [src]="modalImageUrl + data?.profile" alt="">

                        <!-- <img  class="fit-img" src="assets/image/models/dummy_img.png" alt="">  dummy image -->  



                        <!-- <img  class="img-fluid" src="assets/images/user-dummy.jpg" alt=""> -->
<!-- 
                        <img *ngIf="!(data?.profile)" class="img-fluid" src="assets/images/studiomodule/model1.png"
                          alt=""> -->
                        <!-- <img class="img-fluid fit-img" [src]="assets/images/studiomodule/model1.png"> -->
                        <div class="overlay"></div>
                        <div class="actions">
                            <button class="btn mb-3" (click)="editModel(data.id)"><img src="assets/images/studiomodule/edit.svg"></button>
                            <button class="btn" role="button" (click)="deleteModel(data.id)"><img src="assets/images/studiomodule/delete.svg"></button>
                        </div>
                    </div>
                    <h4 class="mb-0 text-white text-center mb-0 cursor_p" (click)="modelDetail(data.id)">{{data.name}}</h4>


                



                </div>
            </div>

            <!-- <div class="sol-sm-12 col-md-6 col-lg-4">
                <div class="model-box">
                    <div class="img-wrap position-relative">
                        <img class="img-fluid fit-img" src="assets/images/studiomodule/model2.png">
                        <div class="overlay"></div>
                        <div class="actions">
                            <button class="btn mb-3"[routerLink]="['/studio-edit-model']"><img src="assets/images/studiomodule/edit.svg"></button>
                            <button class="btn" role="button" (click)="deleteModel()" ><img src="assets/images/studiomodule/delete.svg"></button>
                        </div>
                    </div>
                    <h4 class="mb-0 text-white text-center mb-0">Christine Nguyen</h4>
                </div>
            </div>

            <div class="sol-sm-12 col-md-6 col-lg-4">
                <div class="model-box">
                    <div class="img-wrap position-relative">
                        <img class="img-fluid fit-img" src="assets/images/studiomodule/model3.png">
                        <div class="overlay"></div>
                        <div class="actions">
                            <button class="btn mb-3"[routerLink]="['/studio-edit-model']"><img src="assets/images/studiomodule/edit.svg"></button>
                            <button class="btn" role="button" (click)="deleteModel()"><img src="assets/images/studiomodule/delete.svg"></button>
                        </div>
                    </div>
                    <h4 class="mb-0 text-white text-center mb-0">Darcie D</h4>
                </div>
            </div>

            <div class="sol-sm-12 col-md-6 col-lg-4">
                <div class="model-box">
                    <div class="img-wrap position-relative">
                        <img class="img-fluid fit-img" src="assets/images/studiomodule/model4.png">
                        <div class="overlay"></div>
                        <div class="actions">
                            <button class="btn mb-3" [routerLink]="['/studio-edit-model']"><img src="assets/images/studiomodule/edit.svg"></button>
                            <button class="btn" role="button" (click)="deleteModel()"><img src="assets/images/studiomodule/delete.svg"></button>
                        </div>
                    </div>
                    <h4 class="mb-0 text-white text-center mb-0">Heather Hollows</h4>
                </div>
            </div>

            <div class="sol-sm-12 col-md-6 col-lg-4">
                <div class="model-box">
                    <div class="img-wrap position-relative">
                        <img class="img-fluid fit-img" src="assets/images/studiomodule/model5.png">
                        <div class="overlay"></div>
                        <div class="actions">
                            <button class="btn mb-3" [routerLink]="['/studio-edit-model']"><img src="assets/images/studiomodule/edit.svg"></button>
                            <button class="btn" role="button" (click)="deleteModel()"><img src="assets/images/studiomodule/delete.svg"></button>
                        </div>
                    </div>
                    <h4 class="mb-0 text-white text-center mb-0">Sarah Quinn</h4>
                </div>
            </div>

            <div class="sol-sm-12 col-md-6 col-lg-4">
                <div class="model-box">
                    <div class="img-wrap position-relative">
                        <img class="img-fluid fit-img" src="assets/images/studiomodule/model6.png">
                        <div class="overlay"></div>
                        <div class="actions">
                            <button class="btn mb-3" [routerLink]="['/studio-edit-model']"><img src="assets/images/studiomodule/edit.svg"></button>
                            <button class="btn" role="button" (click)="deleteModel()"><img src="assets/images/studiomodule/delete.svg"></button>
                        </div>
                    </div>
                    <h4 class="mb-0 text-white text-center mb-0">Naomy Monnem</h4>
                </div>
            </div>

            <div class="sol-sm-12 col-md-6 col-lg-4">
                <div class="model-box">
                    <div class="img-wrap position-relative">
                        <img class="img-fluid fit-img" src="assets/images/studiomodule/model7.png">
                        <div class="overlay"></div>
                        <div class="actions">
                            <button class="btn mb-3" [routerLink]="['/studio-edit-model']"><img src="assets/images/studiomodule/edit.svg"></button>
                            <button class="btn" role="button" (click)="deleteModel()"><img src="assets/images/studiomodule/delete.svg"></button>
                        </div>
                    </div>
                    <h4 class="mb-0 text-white text-center mb-0">Joanne Irwin</h4>
                </div>
            </div>

            <div class="sol-sm-12 col-md-6 col-lg-4">
                <div class="model-box">
                    <div class="img-wrap position-relative">
                        <img class="img-fluid fit-img" src="assets/images/studiomodule/model8.png">
                        <div class="overlay"></div>
                        <div class="actions">
                            <button class="btn mb-3" [routerLink]="['/studio-edit-model']"><img src="assets/images/studiomodule/edit.svg"></button>
                            <button class="btn" role="button" (click)="deleteModel()"><img src="assets/images/studiomodule/delete.svg"></button>
                        </div>
                    </div>
                    <h4 class="mb-0 text-white text-center mb-0">Rachel Smalders</h4>
                </div>
            </div>

            <div class="sol-sm-12 col-md-6 col-lg-4">
                <div class="model-box">
                    <div class="img-wrap position-relative">
                        <img class="img-fluid fit-img" src="assets/images/studiomodule/model9.png">
                        <div class="overlay"></div>
                        <div class="actions">
                            <button class="btn mb-3" [routerLink]="['/studio-edit-model']"><img src="assets/images/studiomodule/edit.svg"></button>
                            <button class="btn" role="button" (click)="deleteModel()"><img src="assets/images/studiomodule/delete.svg"></button>
                        </div>
                    </div>
                    <h4 class="mb-0 text-white text-center mb-0">Sonya Smith</h4>
                </div>
            </div> -->
        </div>
    </div>
</section>

<app-studio-footer> </app-studio-footer> 