import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/common/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/message/messagehandling.service';

@Component({
  selector: 'app-models',
  templateUrl: './models.component.html',
  styleUrls: ['./models.component.scss']
})
export class ModelsComponent implements OnInit {
  modelData: any;
  imageUrl: any
  constructor(private api: ApiService, private message: MessagehandlingService, private router: Router) { }

  ngOnInit(): void {
    this.getModels()
    this.imageUrl = this.api.modelImageUrl
  }

  getModels() {
    let fd = new FormData
    fd.append('page', '0')
    this.api.models(fd).subscribe((res: any) => {
      this.modelData = res.data
    })
  }

  modelDetail(data: any) {
    // [routerLink]="['/video-detail']"
    this.router.navigate([`/model-details/${data.id}`])
  }


}
