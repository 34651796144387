import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ApiService } from 'src/app/common/api-service/api.service';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {
  profileData: any
  // userData: any
  logoData!: any;
  // userInfo: any;
  constructor(private api: ApiService) {

  }

  ngOnInit(): void {
   
    // this.userData=sessionStorage.getItem('studioProfile')
    // this.profileData = JSON.parse(sessionStorage.getItem('studioDetail')|| '')
    // userInformation = JSON.parse(sessionStorage.getItem('userinfo') || '')
    //  this.userInfo=JSON.stringify(this.userData)
     this.getProfile()
      //  this.logoData = `${this.api.licenceAndLogoUrl}${this.profileData.logo}`;

    
  
  }


  getProfile() {
    setTimeout(() => {
      this.api.getProfile().subscribe((res: any) => {
        this.profileData = res.data
        this.logoData = `${this.api.licenceAndLogoUrl}${this.profileData.logo}`;
      })
    }, 2000);


  }

}
