import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MessagehandlingService } from 'src/app/common/message/messagehandling.service';
import { DisclaimerPolicyComponent } from '../modal/disclaimer-policy/disclaimer-policy.component';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss']
})
export class DisclaimerComponent implements OnInit {
  theDate: any
  myForm: FormGroup;

  constructor(public dialog: MatDialog, private message: MessagehandlingService,

    private fb: FormBuilder) {
    this.myForm = this.fb.group({
      theDate: ['', [Validators.required, Validators.pattern(/^(0[1-9]|1[0-2])\/\d{4}$/)]]
    });
  }

  ngOnInit(): void {
    sessionStorage.clear()
    localStorage.clear()
  }


  verifyAge(data: any) {
    var dob = new Date(data);
    var month_diff = Date.now() - dob.getTime();
    var age_dt = new Date(month_diff);
    var year = age_dt.getUTCFullYear();
    var age = Math.abs(year - 1970);
    if (age >= 18) {
      this.dialog.open(DisclaimerPolicyComponent, {
        panelClass: ['common_model', 'dialog_lg', 'policy_model'],
        disableClose: true,
      });
    } else {
      this.message.errorMessage(`You are atleast 18 years old.`, 2000)
    }

  }
}
