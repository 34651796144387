<app-header></app-header>

<section class="breadcrumb-banner">
  <div class="container">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item ps-0 active" [routerLink]="['/home']">Home</li>
        <li class="breadcrumb-item active" [routerLink]="['/studios']">Studios</li>
        <li class="breadcrumb-item">All Models</li>
      </ol>
    </nav>
  </div>
</section>

<!-- Studio info -->
<section class="studio-info pt-5 sec_padding_bottom">
  <div class="container">
    <div class="row">
      <div class="col-md-3">
        <div class="studio-img">
          <img class="img-fluid" [src]="logobaseUrl + studioDetail?.logo">
        </div>
      </div>
      <div class="col-md-9">
        <div class="text-sec pb-4">
          <h2 class="ff_kalam fw-normal mb-3 pb-1">{{studioDetail?.name}}</h2>
          <p class="color_grey mb-4">{{studioDetail?.description}}
          </p>
           <!-- <p class="color_grey mb-4">GOATE has the best production value you will find. If Lesbian action is your thing,
            you will be able to find outstanding content with lesbian BDSM, bondage, strap-on fucking, tribadism,
            scissoring and pussy licking from the master of it all, Ariel X. Ariel has starred in lesbian videos since
            2003 and she continues to do so for GOATE as a submissive.</p> -->
        </div> 
      </div>
    </div>
    <hr>


    <div class="relatedVideo_contaienr pb-5 " *ngIf="modelData?.length!=0">
      <h4 class="fw-medium mb-4">{{studioDetail?.name}} Models</h4>

      <div class="row g-4">
        <div class="sol-sm-12 col-md-6 col-lg-4" *ngFor="let model of modelData">
          <div class="model-box" (click)="modelDetail(model.id)">
            <div class="img-wrap position-relative">
              <img class="img-fluid fit-img" [src]="modelUrl + model.profile">
            </div>
            <h4 class="mb-0 text-white text-center mb-0 cursor_p">{{model?.name}}</h4>
          </div>
        </div>

        <!-- <div class="sol-sm-12 col-md-6 col-lg-4">
          <div class="model-box" [routerLink]="['/model-details']">
            <div class="img-wrap position-relative">
              <img class="img-fluid fit-img" src="assets/images/model3.png">
            </div>
            <h4 class="mb-0 text-white text-center mb-0 cursor_p">Darcie D</h4>
          </div>
        </div>

        <div class="sol-sm-12 col-md-6 col-lg-4">
          <div class="model-box" [routerLink]="['/model-details']">
            <div class="img-wrap position-relative">
              <img class="img-fluid fit-img" src="assets/images/model4.png">
            </div>
            <h4 class="mb-0 text-white text-center mb-0 cursor_p">Heather Hollows</h4>
          </div>
        </div>

        <div class="sol-sm-12 col-md-6 col-lg-4">
          <div class="model-box">
            <div class="img-wrap position-relative">
              <img class="img-fluid fit-img" src="assets/images/model5.png">
            </div>
            <h4 class="mb-0 text-white text-center mb-0 cursor_p">Sarah Quinn</h4>
          </div>
        </div>

        <div class="sol-sm-12 col-md-6 col-lg-4">
          <div class="model-box">
            <div class="img-wrap position-relative">
              <img class="img-fluid fit-img" src="assets/images/model6.png">
            </div>
            <h4 class="mb-0 text-white text-center mb-0 cursor_p">Naomy Monnem</h4>
          </div>
        </div>

        <div class="sol-sm-12 col-md-6 col-lg-4">
          <div class="model-box">
            <div class="img-wrap position-relative">
              <img class="img-fluid fit-img" src="assets/images/model7.png">
            </div>
            <h4 class="mb-0 text-white text-center mb-0 cursor_p">Joanne Irwin</h4>
          </div>
        </div>

        <div class="sol-sm-12 col-md-6 col-lg-4">
          <div class="model-box">
            <div class="img-wrap position-relative">
              <img class="img-fluid fit-img" src="assets/images/model8.png">
            </div>
            <h4 class="mb-0 text-white text-center mb-0 cursor_p">Rachel Smalders</h4>
          </div>
        </div>

        <div class="sol-sm-12 col-md-6 col-lg-4">
          <div class="model-box">
            <div class="img-wrap position-relative">
              <img class="img-fluid fit-img" src="assets/images/model9.png">
            </div>
            <h4 class="mb-0 text-white text-center mb-0 cursor_p">Sonya Smith</h4>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</section>



<app-footer></app-footer>