import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/common/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/message/messagehandling.service';

@Component({
  selector: 'app-delete-clip',
  templateUrl: './delete-clip.component.html',
  styleUrls: ['./delete-clip.component.scss']
})
export class DeleteClipComponent implements OnInit {

  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<DeleteClipComponent>,@Inject(MAT_DIALOG_DATA) public data: { block: any }, private api: ApiService, private router: Router, private message: MessagehandlingService) { }

  ngOnInit(): void {
  }

  deleteClip() {
    this.api.deleteClip(this.data).subscribe((res: any) => {
      if (res) {
        this.message.sucessMessage(res.message, 2000)
        // window.location.reload()
        this.dialogRef.close()
      }

    })
  }


  cancel() {
    this.dialogRef.close()
  }
}
