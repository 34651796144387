<app-header></app-header>
<div class="container">
<div class="col-md-12">
    <h4 _ngcontent-tcu-c126="" class="ff_kalam fw-regular mt-5"> Activity</h4>
    <div class="activity_table mt-5">
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Description</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Token</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let activity of tokenDetail">
                    <th scope="row">{{activity?.paymentDate | date}}</th>
                    <td>{{activity?.text }} </td>
                    <td>{{activity?.amount }}</td>
                    <td>{{activity?.token }}</td>
                </tr>
            </tbody>
        </table>
    </div>

</div>
</div>