import { Component, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';

@Component({
  selector: 'app-membership',
  templateUrl: './membership.component.html',
  styleUrls: ['./membership.component.scss']
})
export class MembershipComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  buyPlan() {
    // let url='https://pay.chokechamber.com/#/card-detail'
     this.router.navigate(['/add-token'])
  //   let a= sessionStorage.getItem('membername')
  //   let b:any= localStorage.getItem('studioToken')
  //   console.log(a,b);
  //   const dataToPass = { key: b };
  //   const queryParams = new URLSearchParams(dataToPass).toString();
  //   console.log(`${url}?${queryParams}`);
  //  window.location.href = `${url}?${queryParams}`;
  }

}
