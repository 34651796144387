import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/common/api-service/api.service';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss']
})
export class TermsConditionsComponent implements OnInit {
  termsData: any;

  constructor(private api:ApiService) { }

  ngOnInit(): void {
    this.terms()
  }

  
  terms() {
    let pageType = 'terms'
    this.api.page(pageType).subscribe((res: any) => {
      this.termsData = res.data

    })
  }




}
