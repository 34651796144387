import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiService } from 'src/app/common/api-service/api.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  privacyData: any;

  constructor(private api: ApiService) { }

  ngOnInit(): void {
    this.privacy()
  }


  privacy() {
    let pageType = 'privacy'
    this.api.page(pageType).subscribe((res: any) => {
      this.privacyData = res.data
    })

  }

}
