<app-header></app-header>

<section class="breadcrumb-banner">
    <div class="container">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item ps-0 active" [routerLink]="['/home']">Home</li>
              <li class="breadcrumb-item">My Profile</li>
              <li class="breadcrumb-item">Edit Payment</li>
              <li class="breadcrumb-item">Edit Card</li>
            </ol>
        </nav>
    </div>
</section>

<section class="edit-cmn-sec edit-card pt-4 sec_padding_bottom">
    <div class="container">
        <div class="content-sec mx-auto">
            <form>
                <div class="mb-4 pb-1">
                    <label class="form-label text-uppercase fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Card Number</label>
                    <input type="text" class="form-control fs18 cmn-input input_60 ff_Roboto" value="0123  4567  8901  5684" placeholder="Enter card number">
                </div>
                <div class="row">
                    <div class="col-8">
                        <div class="mb-4 pb-1">
                            <label class="form-label text-uppercase fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Valid Thru</label>
                            <input type="text" class="form-control fs18 cmn-input input_60 ff_Roboto" value="06/2025" placeholder="Enter date">
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="mb-4 pb-1">
                            <label class="form-label text-uppercase fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">CVV</label>
                            <input type="text" class="form-control fs18 cmn-input input_60 ff_Roboto" value="076" placeholder="Enter CVV">
                        </div>
                    </div>
                </div>
                <div class="mb-4 pb-3">
                    <label class="form-label text-uppercase fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Name on card</label>
                    <input type="text" class="form-control fs18 cmn-input input_60 ff_Roboto" value="Jack Daniels" placeholder="Enter name">
                </div>
                <button class="btn w-100 purple_btn btn70 fw-bold" [routerLink]="['/my-profile']">Save Details</button>
                <button class="btn go-back-btn fw-bold text-white shadow-none mx-auto d-block" [routerLink]="['/edit-payment-method']"><img class="me-3" src="assets/images/gobackbtn.svg">Go Back</button>
            </form>
        </div>
    </div>
</section>

<app-footer></app-footer>

