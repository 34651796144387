<app-header></app-header>

<section class="breadcrumb-banner">
    <div class="container">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item ps-0 active" [routerLink]="['/home']">Home</li>
                <li class="breadcrumb-item">Models</li>
            </ol>
        </nav>
    </div>
</section>

<section class="models sec_padding_bottom">
    <div class="container">
        <div class="row g-4" *ngIf="modelData?.length!=0">
            <div class="sol-sm-12 col-md-6 col-lg-4" *ngFor="let model of modelData">
                <div class="model-box" (click)="modelDetail(model)">
                    <div class="img-wrap modal_img position-relative">
                        <img *ngIf="model.profile" class="img-fluid fit-img" [src]="imageUrl + model.profile">
                        <!-- need to change 22 line code -->
                        <img *ngIf="!model.profile" class="img-fluid fit-img" src="assets/images/model2.png">

                    </div>
                    <h4 class="mb-0 text-white text-center mb-0 cursor_p">{{model.name}}</h4>
                </div>
            </div>

            <div class="no_data_found text-center" *ngIf="modelData?.length==0">
                <h1>No Data</h1>
            </div>

            <!-- <div class="sol-sm-12 col-md-6 col-lg-4">
                <div class="model-box">
                    <div class="img-wrap position-relative">
                        <img class="img-fluid fit-img" src="assets/images/model2.png">
                    </div>
                    <h4 class="mb-0 text-white text-center mb-0 cursor_p">Christine Nguyen</h4>
                </div>
            </div>

            <div class="sol-sm-12 col-md-6 col-lg-4">
                <div class="model-box">
                    <div class="img-wrap position-relative">
                        <img class="img-fluid fit-img" src="assets/images/model3.png">
                    </div>
                    <h4 class="mb-0 text-white text-center mb-0 cursor_p">Darcie D</h4>
                </div>
            </div>

            <div class="sol-sm-12 col-md-6 col-lg-4">
                <div class="model-box">
                    <div class="img-wrap position-relative">
                        <img class="img-fluid fit-img" src="assets/images/model4.png">
                    </div>
                    <h4 class="mb-0 text-white text-center mb-0 cursor_p">Heather Hollows</h4>
                </div>
            </div>

            <div class="sol-sm-12 col-md-6 col-lg-4">
                <div class="model-box">
                    <div class="img-wrap position-relative">
                        <img class="img-fluid fit-img" src="assets/images/model5.png">
                    </div>
                    <h4 class="mb-0 text-white text-center mb-0 cursor_p">Sarah Quinn</h4>
                </div>
            </div>

            <div class="sol-sm-12 col-md-6 col-lg-4">
                <div class="model-box">
                    <div class="img-wrap position-relative">
                        <img class="img-fluid fit-img" src="assets/images/model6.png">
                    </div>
                    <h4 class="mb-0 text-white text-center mb-0 cursor_p">Naomy Monnem</h4>
                </div>
            </div>

            <div class="sol-sm-12 col-md-6 col-lg-4">
                <div class="model-box">
                    <div class="img-wrap position-relative">
                        <img class="img-fluid fit-img" src="assets/images/model7.png">
                    </div>
                    <h4 class="mb-0 text-white text-center mb-0 cursor_p">Joanne Irwin</h4>
                </div>
            </div>

            <div class="sol-sm-12 col-md-6 col-lg-4">
                <div class="model-box">
                    <div class="img-wrap position-relative">
                        <img class="img-fluid fit-img" src="assets/images/model8.png">
                    </div>
                    <h4 class="mb-0 text-white text-center mb-0 cursor_p">Rachel Smalders</h4>
                </div>
            </div>

            <div class="sol-sm-12 col-md-6 col-lg-4">
                <div class="model-box">
                    <div class="img-wrap position-relative">
                        <img class="img-fluid fit-img" src="assets/images/model9.png">
                    </div>
                    <h4 class="mb-0 text-white text-center mb-0 cursor_p">Sonya Smith</h4>
                </div>
            </div> -->
        </div>
    </div>
</section>

<app-footer></app-footer>