<app-header></app-header>
<section class="breadcrumb-banner">
    <div class="container">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item ps-0 active" [routerLink]="['/home']">Home</li>
                <li class="breadcrumb-item"[routerLink]="['/my-profile']">My Profile</li>
                <li class="breadcrumb-item">Change Password</li>
            </ol>
        </nav>
    </div>
</section>

<section class="edit-cmn-sec change-pwd pt-4 sec_padding_bottom">
    <div class="container">
        <div class="content-sec mx-auto text-center">
            <div class="img-wrap mb-5">
                <img src="assets/images/change-pwd-img.svg">
            </div>
            <h4 class="fw-medium mb-4 pb-3">Change Your Password</h4>
            <form [formGroup]="changePasswordForm">
                <div class="mb-3 pb-1">
                    <input type="password" class="form-control fs18 cmn-input input_60 ff_Roboto"
                        formControlName="oldpassword" placeholder="Old Password">
                    <small *ngIf="!changePasswordForm.controls?.['oldpassword']?.valid &&
                    changePasswordForm.controls?.['oldpassword']?.dirty" class="error_main_div">
                        <small *ngIf="changePasswordForm.controls?.['oldpassword']?.hasError('required')"
                            class="error_message">This field is Mandatory !
                        </small>
                        <small *ngIf="changePasswordForm.controls?.['oldpassword']?.hasError('pattern')" 
                        class="error_message">Password must contain uppercase,lowercase,number and special characters and min length should be 10.
                      </small>
                    </small>
                    <span class="eye-icon" (click)="togglePassword1()">
                        <img [src]="showPassword1 ? 'assets/images/eye-open.png' : 'assets/images/eye-close.png'" 
                          alt="Toggle Password"/>
                      </span>
                </div>
                <div class="mb-3 pb-1">
                    <input type="password" class="form-control fs18 cmn-input input_60 ff_Roboto"
                        formControlName="password" placeholder="New Password">
                    <small *ngIf="!changePasswordForm.controls?.['password']?.valid &&
                        changePasswordForm.controls?.['password']?.dirty" class="error_main_div">
                        <small *ngIf="changePasswordForm.controls?.['password']?.hasError('required')"
                            class="error_message">This field is Mandatory !
                        </small>
                        <small *ngIf="changePasswordForm.controls?.['password']?.hasError('pattern')"
                        class="error_message">Password must contain uppercase,lowercase,number and special characters and min length should be 10.
                      </small>
                    </small>
                    <span class="eye-icon" (click)="togglePassword()">
                        <img [src]="showPassword ? 'assets/images/eye-open.png' : 'assets/images/eye-close.png'" 
                          alt="Toggle Password"/>
                      </span>
                </div>
                <div class="mb-4 pb-3 position-relative">
                    <input [type]="showPassword ? 'text' : 'password'" class="form-control fs18 cmn-input input_60 ff_Roboto"
                        formControlName="confirmPassword" placeholder="Confirm New Password">
                    <small *ngIf="!changePasswordForm.controls?.['confirmPassword']?.valid &&
                        changePasswordForm.controls?.['confirmPassword']?.dirty" class="error_main_div">
                        <small
                            *ngIf="changePasswordForm.controls?.['confirmPassword']?.invalid && changePasswordForm.controls?.['confirmPassword']?.errors?.['ConfirmPassword']"
                            class="error_message">New password and confirm password must same !</small>
                        <small *ngIf="changePasswordForm.controls?.['confirmPassword']?.hasError('required')"
                            class="error_message">This field is Mandatory !
                        </small>                       
                    </small>
                    <span class="eye-icon" (click)="togglePassword1()">
                        <img [src]="showPassword1 ? 'assets/images/eye-open.png' : 'assets/images/eye-close.png'" 
                          alt="Toggle Password"/>
                      </span>
                </div>
                <button class="btn w-100 purple_btn btn70 fw-bold" (click)="savePassword()">Update Password</button>
                <button class="btn go-back-btn fw-bold text-white shadow-none" [routerLink]="['/my-profile']"><img
                        class="me-3" src="assets/images/gobackbtn.svg">Go Back</button>
            </form>
        </div>
    </div>
</section>

<app-footer></app-footer>