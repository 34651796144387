import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { IVideoConfig } from "ngx-video-list-player";
import { ApiService } from 'src/app/common/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/message/messagehandling.service';
declare const $: any;
@Component({
  selector: 'app-video-detail',
  templateUrl: './video-detail.component.html',
  styleUrls: ['./video-detail.component.scss']
})
export class VideoDetailComponent implements OnInit {
  id: any
  config: IVideoConfig = {
    sources: [
      {
        src: 'assets/videos/demovedio.mp4',
      },
      {
        src: 'assets/videos/demovedio1.mp4',
      }]
  };
  clipDetail: any;
  tags: any;
  relatedClips: any;
  videoData: any;
  clipUrl: any;
  time = new Date()
  commonToken=0
  data: any;
  constructor(private router: Router, public activeroute: ActivatedRoute, private api: ApiService, private message: MessagehandlingService) {
    this.activeroute.params.subscribe((params: any) => {
      this.id = params['id'];
      if (this.id) {
        // this.getDetail(this.id)
        this.videoDetail(this.id)
      } 
      // else {
      //   this.router.navigate(['/']);
      // }
    })
  }

  ngOnInit(): void {
    this.tokenList()

  }
  fav(id: any, isFav: any) {
    
    let fd = new FormData

    fd.append('CID', id)
    if (isFav == 1) {
      fd.append('status', "0")
      $(".likeContainer").toggleClass("heart_filled");
    }
    else {
      fd.append('status', "1")

      $(".likeContainer").removeClass("heart_filled");

    }
    this.api.addFavourite(fd).subscribe((res: any) => {
      this.message.sucessMessage(res.message, 1000)
      this.videoDetail(this.id)


    })
    // $(".likeContainer").toggleClass("heart_filled");
  }

  ngAfterViewInit() {
    // Initialize Slick Slider
    $('.slick-carousel').slick({
      autoplay: true,
      autoplaySpeed: 2000,
      dots: true,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });
  }
  
  videoDetail(id: any) {
    this.api.clipDetailUser(id).subscribe((res: any) => {
      this.clipUrl = this.api.clipsImageUrl
      this.videoData = this.api.clipsImageUrl + res.data.trailer

      this.clipDetail = res.data
      this.tags = res.data.tags
      this.relatedClips = res.data.relatedClips
      
      setTimeout(() => {
        $('.commonVideo_slider').not('.slick-initialized').slick({
          slidesToShow: 3,
          slidesToScroll: 1,
          autoplay: false,
          autoplaySpeed: 3000,
          dots: true,
          infinite: false,
          // centerMode: true,
          // variableWidth: true,
          prevArrow: $('.prev'),
          nextArrow: $('.next'),
          responsive: [
            {
              breakpoint: 1400,
              settings: {
                slidesToShow: 3,

              }
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 2,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        });
      }, 1000);

    })
  }

  tokenList(){
    this.api.tokenDetail().subscribe((res:any)=>{
      this.commonToken=res.data?.totalToken
    })
  }

  detailTrailor(id: any) {
    this.router.navigate([`/video-detail/${id}`])
  }


  buyVideo() {
    // this.router.navigate(['/card-detail'])
    // this.router.navigate(['/add-token'])
    let url='https://pay.chokechamber.com/#/add-token'
    let a = sessionStorage.getItem('membername')
    let b: any = localStorage.getItem('studioToken')
   
    const dataToPass = { key: b };
    const queryParams = new URLSearchParams(dataToPass).toString();
  
    window.location.href = `${url}?${queryParams}`;
  //   let Subscription:any = sessionStorage.getItem('isSubscription')
   this.data = JSON.parse(sessionStorage.getItem('studioDetail') || '')
  
  //  this.data = JSON.parse(sessionStorage.getItem('studioDetail') || '')
  
   
  //   if (Subscription === '1' || this.data.isSubscription === 1) {
      // let fd = new FormData
      // fd.append('CID', this.clipDetail.id)
      // fd.append('SID', this.clipDetail.SID)
      // fd.append('title', this.clipDetail.title)
      // fd.append('image', this.clipDetail.image)
      // fd.append('trailer', this.clipDetail.trailer)
      // fd.append('video', this.clipDetail.video)
      // fd.append('total', this.clipDetail.price)
      // fd.append('paymentDate', moment(this.time).format('YYYY-MM-DD'))
      // this.api.buynow(fd).subscribe((res: any) => {
      //   this.message.sucessMessage(res.message, 2000)
      //   if(res.status==200){
      //   this.router.navigate(['/purchased-video'])
      //   }
      // })
    // }
    // else {
    //   this.router.navigate(['/membership'])
    // }
  }

  buy(){
    let fd = new FormData
    fd.append('CID', this.clipDetail.id)
    fd.append('SID', this.clipDetail.SID)
    fd.append('title', this.clipDetail.title)
    fd.append('image', this.clipDetail.image)
    fd.append('trailer', this.clipDetail.trailer)
    fd.append('video', this.clipDetail.video)
    fd.append('total', this.clipDetail.price)
    fd.append('paymentDate', moment(this.time).format('YYYY-MM-DD'))
    this.api.buynow(fd).subscribe((res: any) => {
      this.message.sucessMessage(res.message, 2000)
      if(res.status==200){
      this.router.navigate(['/purchased-video'])
      }
    })
  }




  addToCart() {
    let fd = new FormData
    fd.append('CID', this.clipDetail.id)
    fd.append('SID', this.clipDetail.SID)
    fd.append('title', this.clipDetail.title)
    fd.append('image', this.clipDetail.image)
    fd.append('trailer', this.clipDetail.trailer)
    fd.append('video', this.clipDetail.video)
    fd.append('total', this.clipDetail.price)
    this.api.addToCart(fd).subscribe((res: any) => {
      this.message.sucessMessage(res.message, 1000)
      this.videoDetail(this.id)

    })
  }

  goToCart() {
    this.router.navigate(['/cart'])
  }


  customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: false,
    autoplay: true,
    pullDrag: false,
    dots: false,
    navSpeed: 1000,
    margin: 10,
    navText: [
      '<img src="assets/images/left_chev.svg" alt="left arrow" />',
      '<img src="assets/images/right_chev.svg" alt="right arrow" />'
    ],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 2
      },
      940: {
        items: 3
      }
    },
    nav: true
  }

}
