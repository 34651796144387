import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/common/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/message/messagehandling.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ImageCropperComponent } from '../image-cropper/image-cropper.component';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-add-model',
  templateUrl: './add-model.component.html',
  styleUrls: ['./add-model.component.scss']
})
export class AddModelComponent implements OnInit {
  addModal: FormGroup
  gendervalue: any = "Gender"
  modalProfile: any
  modalProfileBlob: any;
  availCustom: boolean = false


  value: any;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  modelData: any[] = [];
  profile: any;
  licenceData: any;
  


  constructor(private formBuilder: FormBuilder, private router: Router, private api: ApiService, private message:
    MessagehandlingService, private dialog: MatDialog
  ) {

    this.addModal = this.formBuilder.group({
      username: new FormControl('', Validators.required),
      firstname: new FormControl('', [Validators.required]),
      profileImage: new FormControl(''),
      tags: new FormControl(''),
      bio: new FormControl(''),
      gendervalue: new FormControl('', Validators.required),
      isCustom: new FormControl(''),


    })

  }

  ngOnInit(): void {
  }

  genderClass(event: any) {
    this.gendervalue = event;
  }

  checkEvent(event: any) {
    if (event.target.value == '' && event.which == 32) {
      event.preventDefault();
    }
  }

  customChange(event: any) {
    console.log(this.availCustom, event)

  }

  checkEnterEvent(event: any){
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  
  }


  commaSaperatedTags(data: any[]) {
    let saperated = '';
    if (data.length) {
      data.forEach(element => {
        saperated = saperated + (saperated.length ? ',' : '') + element.name
      });
    }
    return saperated;
  }


  add(event: MatChipInputEvent): void {

    const input = event.input;
    this.value = event.value;

    // Add our fruit
    if ((this.value || '').trim()) {

      this.modelData.push({ id: '0', name: this.value.trim() });
      // this.modelData.push(this.value);
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(model: any): void {
    const index = this.modelData.indexOf(model);

    if (index >= 0) {
      this.modelData.splice(index, 1);
    }
  }


    onFileChange(event: any, type: any): void {
      let dialogRef = this.dialog.open(ImageCropperComponent, {
        data: {
          imageUrl: event,
          type: type
        },
        panelClass: ['cmn_modal', 'img_croper'],
      });
  
      setTimeout(() => {
        event.target.value = '';
      }, 0);
  
      dialogRef.afterClosed().subscribe((res: any) => {
        if (res ) {
          console.log('Cropped Image Data:', res);
          this.profile = this.convertBase64ToFile(res.data);
          this.licenceData = res.data;
        } else {
          console.error('No data returned from the dialog');
        }
      });
    }
  
    convertBase64ToFile(base64Data: string) {
      const base64Image = base64Data.replace(/^data:image\/[a-z]+;base64,/, "");
      const byteCharacters = atob(base64Image);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'image/png' });
      const timestamp: any = Date.now();
      const randomNumber = Math.floor(Math.random() * 10000);
      const file = new File([blob], timestamp + '.png', { type: blob.type });
      return file;
    }
  
  

  addModelData() {
    if (this.addModal.value.username == '' || this.addModal.value.firstname == '' || this.addModal.value.gendervalue == '' || this.addModal.value.gendervalue == 'Gender' || this.addModal.value.profileImage == '') {
      alert("Please add Image and Enter Name , Username and Gender. These fields are mandatory ")
    } else {
      setTimeout(() => {
        let fd = new FormData
        fd.append('profile', this.profile)
        fd.append('username', this.addModal.value.username)
        fd.append('name', this.addModal.value.firstname)
        fd.append('bio', this.addModal.value.bio)
        fd.append('gender', this.addModal.value.gendervalue)
        fd.append('isCustom', this.addModal.value.availCustom == false ? '0' : '1')
        fd.append('tags', JSON.stringify(this.modelData))
        this.api.addModel(fd).subscribe((res: any) => {
          if (res.status==400) {
            this.message.sucessMessage(res.message, 2000)
            return
          }
          if(res.status==200){
            this.message.sucessMessage(res.message, 500)
            this.router.navigate(['/studio-models'])
          }
        })
      }, 2000);
    }
  }

  removeImage(){
    this.licenceData=''
    this.profile=''
  }




}



