<app-header></app-header>
<form [formGroup]="editProfile">
<section class="my-profile py-5">
    <div class="container">
        <button class="backbtn" [routerLink]="['/studio-my-profile']"><img
                src="assets/images/studiomodule/backbtn.svg"></button>
       
            <div class="row g-4 justify-content-center mb-5">


                <div class="col-md-5">
                    <div class="inner-sec">
                        <!-- <div class="logo-sec text-center">
                        <div class="img-wrap mx-auto d-flex align-items-center justify-content-center">
                            <img class="img-fluid" src="assets/images/dummyimg.svg">
                        </div>
                        <p class="mt-4 upload-sec fs18 mb-0">
                            <span class="position-relative d-inline-block">
                                
                                <input type="file" class="upload_input">
                            </span>
                        </p>
                    </div> -->
                        <!-- <div class="licence-sec">
                            <div
                                class="img-wrap d-flex align-items-center justify-content-center flex-column position-relative">
                                <img class="fit-img" [src]="logoData">
                                <p class="fs18 text-center mb-0 mt-3 pt-1">Upload Studio Logo</p>
                                <input type="file" class="upload_input" formControlName="logo" (change)="onFileChange($event,'logo')" autofocus="false">
                            </div>
                        </div> -->
                        
                    <div class="profile_sec text-center mb-5 position-relative">
                        <div class="studio-img mb-4 p-0">
                            <img class="fit-img" [src]="logoData">   
                        </div>
                        <div class="upload_img">    <span> <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.167 11.916C19.9239 11.916 19.6907 12.0126 19.5188 12.1845C19.3469 12.3564 19.2503 12.5896 19.2503 12.8327V16.6946C19.2496 17.3719 18.9802 18.0213 18.5013 18.5003C18.0223 18.9792 17.3729 19.2486 16.6956 19.2493H5.30508C4.62774 19.2486 3.97835 18.9792 3.4994 18.5003C3.02045 18.0213 2.75105 17.3719 2.75033 16.6946V12.8327C2.75033 12.5896 2.65375 12.3564 2.48184 12.1845C2.30993 12.0126 2.07677 11.916 1.83366 11.916C1.59054 11.916 1.35739 12.0126 1.18548 12.1845C1.01357 12.3564 0.916992 12.5896 0.916992 12.8327V16.6946C0.918205 17.858 1.38091 18.9734 2.20357 19.7961C3.02623 20.6188 4.14166 21.0815 5.30508 21.0827H16.6956C17.859 21.0815 18.9744 20.6188 19.7971 19.7961C20.6197 18.9734 21.0824 17.858 21.0837 16.6946V12.8327C21.0837 12.5896 20.9871 12.3564 20.8152 12.1845C20.6433 12.0126 20.4101 11.916 20.167 11.916Z" fill="white"/>
                            <path d="M6.14821 7.98057L10.0835 4.04532V15.5825C10.0835 15.8256 10.18 16.0588 10.3519 16.2307C10.5239 16.4026 10.757 16.4992 11.0001 16.4992C11.2432 16.4992 11.4764 16.4026 11.6483 16.2307C11.8202 16.0588 11.9168 15.8256 11.9168 15.5825V4.04532L15.852 7.98057C16.0249 8.14755 16.2565 8.23994 16.4968 8.23785C16.7372 8.23577 16.9671 8.13936 17.137 7.9694C17.307 7.79945 17.4034 7.56953 17.4055 7.32919C17.4076 7.08884 17.3152 6.85729 17.1482 6.6844L11.6482 1.1844C11.4763 1.01255 11.2432 0.916016 11.0001 0.916016C10.7571 0.916016 10.5239 1.01255 10.352 1.1844L4.85205 6.6844C4.68507 6.85729 4.59267 7.08884 4.59476 7.32919C4.59685 7.56953 4.69325 7.79945 4.86321 7.9694C5.03317 8.13936 5.26308 8.23577 5.50343 8.23785C5.74378 8.23994 5.97533 8.14755 6.14821 7.98057V7.98057Z" fill="white"/>
                            </svg>
                            <input type="file" class="upload_input" formControlName="logo" (change)="onFileChange($event,'logo')" autofocus="false">
                            
                            <!-- <input type="file" class="upload_input"> -->
                        </span>  </div>

                    </div>
                    </div>
                </div>
                <!-- <div class="col-md-5">
                    <div class="inner-sec">
                        <div class="licence-sec">
                            <div
                                class="img-wrap d-flex align-items-center justify-content-center flex-column position-relative">
                                <img class="fit-img" [src]="licenceData">
                                <p class="fs18 text-center mb-0 mt-3 pt-1">Upload Licence</p>
                                <input type="file" class="upload_input" formControlName="licence" (change)="onFileChange($event,'licence')" autofocus="false">
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="row justify-content-center">
                <div class="col-md-10">
                    <div class="form-sec">
                        <!-- <form> -->
                            <div class="inner-sec ">
                                <div class="mb-4 pb-1">
                                    <label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Studio
                                        name</label>
                                    <input type="text" formControlName="name" (keydown)="checkEvent($event)"
                                        class="form-control fs18 cmn-input input_60 ff_Roboto"
                                        placeholder="Enter studio name" >
                                </div>
                                <div class="row g-4">
                                    <div class="mb-4 pb-1 col-md-6">
                                        <label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Studio
                                            Description</label>
                                        <input type="text" formControlName="description" (keydown)="checkEvent($event)"
                                            class="form-control fs18 cmn-input input_60 ff_Roboto"
                                            placeholder="Enter studio description">
                                    </div>

                                    <div class="mb-4 pb-1 col-md-6" >
                                        <label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Email
                                            Address</label>
                                        <input type="email" formControlName="email"  [readonly]="true" 
                                            class="form-control fs18 cmn-input input_60 ff_Roboto">
                                    </div>
                                </div>

                                <div class="row g-4">
                                    <div class="mb-4 pb-1 col-md-6">
                                        <label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Phone
                                            Number</label>
                                        <input type="number" formControlName="phoneNumber"
                                            class="form-control fs18 cmn-input input_60 ff_Roboto"
                                            placeholder="Enter phone number">
                                    </div>
                                    <div class="mb-4 pb-1 col-md-6">
                                        <label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">SSN/EIN</label>
                                        <input type="number" formControlName="ssn"
                                            class="form-control fs18 cmn-input input_60 ff_Roboto"
                                            placeholder="0215125120245">
                                    </div>
                                </div>

                                <div class="row g-4">
                                    <div class="mb-4 pb-1 col-md-6">
                                        <label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Legal Business Name
                                            </label>
                                        <input type="text" formControlName="fax"
                                            class="form-control fs18 cmn-input input_60 ff_Roboto"
                                            placeholder="Enter Tax ID number or EIN">
                                    </div>
                                    <div class="mb-4 pb-1 col-md-6">
                                        <label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Website
                                            <span class="color_pink"></span> (Optional)</label>
                                        <input type="text" formControlName="website"
                                            class="form-control fs18 cmn-input input_60 ff_Roboto"
                                            placeholder="Enter website URl">
                                    </div>
                                </div>

                                <div class="text-center mt-5">
                                    <button class="btn  purple_btn btn70 fw-bold px-5" type="submit"
                                        (click)="saveProfile()">Save Profile</button>
                                </div>
                            </div>
                        <!-- </form> -->
                    </div>
                </div>
            </div>
       
    </div>

</section>
</form>

<app-studio-footer> </app-studio-footer>