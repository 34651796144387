import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxOtpInputConfig } from 'ngx-otp-input';
import { ApiService } from 'src/app/common/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/message/messagehandling.service';
@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.scss']
})
export class VerifyOtpComponent implements OnInit {
  verifyForm: FormGroup
  email: any
  otpInputConfig: NgxOtpInputConfig = {
    otpLength: 6,
    autofocus: true,
    classList: {
      inputBox: 'my-super-box-class',
      input: 'my-super-class',
      inputFilled: 'my-super-filled-class',
      inputDisabled: 'my-super-disable-class',
      inputSuccess: 'my-super-success-class',
      inputError: 'my-super-error-class',
    },
  };
  constructor(private formBuilder: FormBuilder, private api: ApiService, private router: Router, private message: MessagehandlingService) {
    this.verifyForm = this.formBuilder.group({
      otp: new FormControl('')
    })
  }
  ngOnInit(): void {
    this.email = sessionStorage.getItem('emailUser')
  }

  submit() {
    let fd = new FormData
    fd.append('otp', this.verifyForm.value.otp)
    fd.append('type', '1')


    if (this.verifyForm.value.otp == '') {
      this.message.errorMessage('Please enter OTP', 2000)
    }
    else {
      this.api.verifyOtpUser(fd).subscribe((res: any) => {
        if (res.status == 200) {
          this.message.sucessMessage(res.message, 2000)
          this.router.navigate(['/reset-password'])

        }
        else {
          this.message.errorMessage(res.message, 2000)
        }
      })
    }
  }


  handeOtpChange(value: string[]): void {
    console.log(value);

  }

  handleFillEvent(value: string): void {
    this.verifyForm.value.otp = value
  }
  // [routerLink]=""

  resendCode() {
    let fd = new FormData
    fd.append('email', this.email)
    this.api.resendOtpUser(fd).subscribe((ele: any) => {
      if (ele) {
        this.message.sucessMessage(ele.message, 2000)
      }

    })
  }




}
