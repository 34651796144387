import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ApiService } from 'src/app/common/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/message/messagehandling.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {
  cartData: any;
  token: any;
  baseUrl = this.api.clipsImageUrl
  cartPrice: any = []
  totalPrice: number = 0;
  OID: any;
  total: any;
  time = new Date();
  adminToken: any;
  bonusToken: any;
  commonToken: any = 0
  customValue: any;
  isBuyVideo: boolean = false
  data: any = []

  constructor(private api: ApiService, private message: MessagehandlingService, private router: Router, private route: ActivatedRoute) {
    // this.route.data.subscribe(data => {
    //   this.customValue = data['state']['customKey'];
    //   localStorage.setItem('studioToken', this.customValue)
    // });

    // need to uncomment code if in case having issue related domain change 30 Apr 2024
    // const key = this.route.snapshot.queryParamMap.get('customKey');
    // if (key) {
    //   localStorage.setItem('studioToken', key)
   
    // } else {
    // }
  }

  ngOnInit(): void {
    this.cartList()
    this.tokenDetail()

  }

  redirectToUrl(url: string) {
    // this.router.navigate(['/add-token'])
    let a = sessionStorage.getItem('membername')
    let b: any = localStorage.getItem('studioToken')
    const dataToPass = { key: b };
    const queryParams = new URLSearchParams(dataToPass).toString();
    window.location.href = `${url}?${queryParams}`;
  }

  // cartList() {
  //   this.cartPrice = []
  //   this.totalPrice = 0
  //   this.api.cartList().subscribe((res: any) => {
  //     this.cartData = res.data
  //     this.OID = res.OID
  //     this.token = res.token
  //     this.cartData?.forEach((element: any) => {
  //       this.cartPrice.push({ price: element.total })
  //       this.calculateTotal()
  //     });
  //   })

  // }


  // calculateTotal() {

  //   this.cartPrice.map((cart: any) => {
  //     this.totalPrice += cart.price;
  //     this.total = this.totalPrice
  //   });


  // }

  cartList() {
    this.cartPrice = [];
    this.totalPrice = 0;
    this.api.cartList().subscribe((res: any) => {
      this.cartData = res.data;
      this.OID = res.OID;
      this.token = res.token;
      this.adminToken = res.adminToken
      this.bonusToken = res.bonusToken
      this.commonToken = res.commonToken

      this.cartData?.forEach((element: any) => {
        this.cartPrice.push({ price: element.total });
      });
      this.calculateTotal();
    });
  }

  calculateTotal() {
    this.totalPrice = this.cartPrice.reduce((total: any, cartItem: any) => total + cartItem.price, 0);
    this.total = this.totalPrice;
  }


  tokenDetail() {
    this.api.tokenDetail().subscribe((res: any) => {
    })
  }

  deleteItem(id: any) {
    this.api.deleteItem(id).subscribe((res: any) => {
      this.message.sucessMessage(res.message, 1000)
      this.cartList()

    })
  }

  // [routerLink]="['/video-detail']"

  videoDetail(id: any) {
    this.router.navigate([`/video-detail/${id}`])

  }

  checkout() {
    // this.router.navigate(['/card-detail'])
    this.router.navigate(['/add-token'])
    // let Subscription: any = sessionStorage.getItem('isSubscription')
    // this.data = JSON.parse(sessionStorage.getItem('studioDetail') || '')
    // console.log(Subscription, this.data?.isSubscription);

    // if (Subscription == '1' || this.data.isSubscription == '1') {
    //    this.isBuyVideo=true
    // let fd = new FormData
    // fd.append('OID', this.OID)
    // fd.append('total', this.total)
    // fd.append('paymentDate', moment(this.time).format('YYYY-MM-DD'))
    // this.api.checkout(fd).subscribe((res: any) => {
    //   this.message.sucessMessage(res.message, 1000)
    //   if (res.status == 200) {
    //     this.router.navigate(['/purchased-video'])
    //   }
    //   this.cartList()
    // })
  }
  // else {

  // window.alert('Are you sure! You want to purchase this video?')
  // this.router.navigate(['/membership'])
  // }
  // }

  buy() {
    let fd = new FormData
    fd.append('OID', this.OID)
    fd.append('total', this.total)
    fd.append('paymentDate', moment(this.time).format('YYYY-MM-DD'))
    this.api.checkout(fd).subscribe((res: any) => {
      this.message.sucessMessage(res.message, 1000)
      if (res.status == 200) {
        this.router.navigate(['/purchased-video'])
      }
      this.cartList()
    })
  }

}
