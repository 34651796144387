<!-- <app-header></app-header> -->
<div class="p-4">
    <section class="about-us">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="text-wrap ps-0 ps-md-3 d-flex align-items-center">
                        <div class="inner-sec">
                            <h2 class="mb-4 pb-1 text-uppercase fw-bold">{{privacyData?.title}}</h2>
                            <p class="color_grey mb-4 pb-1"[innerHTML]="privacyData?.content"> </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<!-- <app-footer></app-footer> -->