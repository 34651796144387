import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ApiService } from 'src/app/common/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/message/messagehandling.service';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {
  profileData: any
  logoData!: any;
  subsDetail: any=[]
  time = new Date()
  constructor(private api: ApiService, private message: MessagehandlingService, private router:Router) {

  }
  ngOnInit(): void {
    setTimeout(() => {
      this.getProfile()
      this.getDetail()
    }, 2000);
  }

  getProfile() {
    this.api.profileUser().subscribe((res: any) => {
      this.profileData = res.data
      // this.logoData = `${this.api.uerFilePath}${this.profileData.logo}`;
    })

  }

  redirectToUrl(url: string) {
   this.router.navigate(['/add-token'])
  //  let a= sessionStorage.getItem('membername')
  //  let b:any= localStorage.getItem('studioToken')
  //  console.log(a,b);
  //  const dataToPass = { key: b };
  //  const queryParams = new URLSearchParams(dataToPass).toString();
  //  console.log(`${url}?${queryParams}`);
  // window.location.href = `${url}?${queryParams}`;
    
}


  getDetail() {
    this.api.getSubscriptionDetail().subscribe((res: any) => {
      this.subsDetail = res.data

    })
  }

  cancelMemberShip() {
    let fd = new FormData
    fd.append('cancelDate', moment(this.time).format('YYYY- MM- DD'))
    this.api.cancelSubscription(fd).subscribe((res: any) => {
      this.message.sucessMessage(res.message, 2000)
    })

  }

}
