
<image-cropper class="image-cropper"
    [imageChangedEvent]="imageChangedEvent"
    [maintainAspectRatio]="true"
    (imageCropped)="imageCropped($event)" 
    [maintainAspectRatio]="true"
    [cropperStaticHeight]="400"
    [cropperMaxWidth]="630"
   >
</image-cropper>

<div class="btns-sec d-flex align-items-center justify-content-center pt-4 px-1">
    <button class="btn cancelBtn bbtn50 fw-bold px-5" (click)="closeModal()">Cancel</button>
    <button class="btn purple_btn btn50 fw-bold px-5" (click)="cropImage()">Crop</button>
</div>
