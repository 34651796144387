import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/common/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/message/messagehandling.service';
@Component({
  selector: 'app-studio-signup',
  templateUrl: './studio-signup.component.html',
  styleUrls: ['./studio-signup.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true },
    },
  ],
})




export class StudioSignupComponent implements OnInit {
  // firstFormGroup = this._formBuilder.group({
  //   firstCtrl: ['', Validators.required],
  // });
  // secondFormGroup = this._formBuilder.group({
  //   secondCtrl: ['', Validators.required],
  // });
  logoData: any
  licenceData: any
  createProfileForm: FormGroup
  logo: any
  licence: any
 
  constructor(private formBuilder: FormBuilder, private router: Router, private api: ApiService, private message: MessagehandlingService) {

    this.createProfileForm = this.formBuilder.group({
      description: ['', [Validators.required]],
      name: ['', [Validators.required]],
      phoneNumber: ['', [Validators.required]],
      email: ['', [Validators.required, this.emailValidator.bind(this)]],
      password: ['', [Validators.required,this.cannotContainSpace.bind(this)]],
      confirmPassword: [''],
      ssn: [''],
      fax: [''],
      address: [''],
      country: [''],
      state: [''],
      city: [''],
      zipcode: [''],
      logo: ['', [Validators.required]],
      licence: ['', [Validators.required]],

    }, { validator: this.checkPasswords })

  }

  ngOnInit(): void {
  }

  emailValidator(control: FormControl) {
    if (!(control.value.toLowerCase().match('^[a-z0-9]+(\.[_a-z0-9]+)+(\.[a-z0-9-]+)+([.{1}])(\.[a-z]{1,15})$'))) {
      return { invalidEmail: true };
    }
    return
  }


  checkEvent(event:any){
    if(event.target.value=='' && event.which==32){
      event.preventDefault();
    }
  }


  checkPasswords(control: FormControl) {
    const pass = control.get('password')?.value
    const confirmPass = control.get('confirmPassword')?.value
    if (pass != confirmPass) {
      control.get('confirmPassword')?.setErrors({ ConfirmPassword: true });
    } else {
      return null;
    }
    return
  }


  onFileChange(event: any, type: string): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      let reader = new FileReader();
      reader.onload = e => {
        switch (type) {
          case 'logo':
            this.logoData = reader.result
            break;
          case 'licence':
            this.licenceData = reader.result
            break;
        }

      };
      reader.readAsDataURL(file);

      if (type == 'licence') {
        this.licence = file
      }
      if (type == 'logo') {
        this.logo = file
      }


    }

  }




  cannotContainSpace(control: AbstractControl): ValidationErrors | null {
    if ((control.value as string).indexOf(' ') >= 0) {
      return { cannotContainSpace: true }
    }

    return null;
  }


  submitProfileData() {
    let fd = new FormData
    fd.append('description', this.createProfileForm.value.description)
    fd.append('name', this.createProfileForm.value.name)
    fd.append('email', this.createProfileForm.value.email)
    fd.append('password', this.createProfileForm.value.password)
    fd.append('phoneNumber', this.createProfileForm.value.phoneNumber)
    fd.append('address', this.createProfileForm.value.address)
    fd.append('country', this.createProfileForm.value.country)
    fd.append('city', this.createProfileForm.value.city)
    fd.append('state', this.createProfileForm.value.state)
    fd.append('ssn', this.createProfileForm.value.ssn)
    fd.append('fax', this.createProfileForm.value.fax)
    fd.append('logo', this.logo)
    fd.append('zipcode', this.createProfileForm.value.zipcode)
    fd.append('licence', this.licence)

    this.api.register(fd).subscribe((ele: any) => {
      if (ele.status == 200) {
        localStorage.setItem('studioToken', ele.data.token)
        this.message.sucessMessage(ele.message, 2000)
        sessionStorage.setItem('studioname', ele.data.name)
        // sessionStorage.setItem('studioDetail',JSON.stringify(ele.data))
      
        this.router.navigate(['/studio-login'])

      } else {
        this.message.errorMessage(ele.message, 2000)
      }
    })
  }
}
