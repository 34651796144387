<section class="login-sec d-flex align-items-center justify-content-center p-4">
    <div class="content-box">
      <form [formGroup]="resetForm">
        <button class="backbtn" [routerLink]="['/verify-otp']"><svg width="34" height="24" viewBox="0 0 34 24"
            fill="none">
            <path d="M32 12L3.42857 12" stroke="white" stroke-width="3" stroke-linecap="round" />
            <path d="M10.5714 2L2 12L10.5714 22" stroke="white" stroke-width="3" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </button>
        <h2 class="ff_kalam font-normal text-white mb-4 pb-1">Reset Password</h2>
        <div class="mb-4">
          <label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">New Password</label>
          <input type="password" formControlName="password" class="form-control fs18 cmn-input input_60 ff_Roboto"
            placeholder="Enter new password" (keydown)="checkEvent($event)">
          <!-- </div> -->
          <small *ngIf="!resetForm.controls?.['password']?.valid &&
        resetForm.controls?.['password']?.dirty" class="error_main_div">
            <small *ngIf="resetForm.controls?.['password']?.hasError('required')" class="error_message">This
              field
              is
              Mandatory !
            </small>
          </small>
          <!-- <input type="password" class="form-control fs18 cmn-input input_60 ff_Roboto" placeholder="Enter New Password"> -->
        </div>
  
        <div class="mb-5">
          <label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto"> Confirm Password</label>
          <input type="password" formControlName="confirmPassword" class="form-control fs18 cmn-input input_60 ff_Roboto"
            placeholder="Enter confirm password" (keydown)="checkEvent($event)">
          <!-- </div> -->
          <small *ngIf="!resetForm.controls?.['confirmPassword']?.valid &&
        resetForm.controls?.['confirmPassword']?.dirty" class="error_main_div">
            <small
              *ngIf="resetForm.controls?.['confirmPassword']?.invalid && resetForm.controls?.['confirmPassword']?.errors?.['ConfirmPassword']"
              class="error_message">Password and confirm password is not match !</small>
            <small *ngIf="resetForm.controls?.['confirmPassword']?.hasError('required')" class="error_message">This
              field
              is Mandatory !
            </small>
          </small>
          <!-- <input type="password" class="form-control fs18 cmn-input input_60 ff_Roboto" placeholder="Confirm Password"> -->
        </div>
  
        <button type="submit" class="btn w-100 purple_btn btn70 fw-bold" (click)="reset()" [disabled]="resetForm.invalid" (keyup.enter)="reset()">
          Reset</button>
      </form>
    </div>   
</section>
