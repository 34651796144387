<app-header></app-header>

<section class="breadcrumb-banner">
    <div class="container">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item ps-0 active" [routerLink]="['/home']">Home</li>
                <li class="breadcrumb-item">All Categories</li>
            </ol>
        </nav>
    </div>
</section>

<section class="categories-sec sec_padding_bottom">
    <div class="container">
        <div class="row g-4">

            <div class="col-md-4 col-lg-3"*ngFor="let cat of categoriesData">
                <div class="categ_itm cursor_p position-relative" (click)="categoryDetail(cat)" >
                    <img class="fit-img img-fluid" [src]="this.imageUrl + cat.icon">
                    <p class="fw-medium m-0 text-center">{{cat.name}}</p>
                </div>
            </div>

            <!-- <div class="col-md-4 col-lg-3">
                <div class="categ_itm cursor_p position-relative" >
                    <img class="fit-img img-fluid" src="assets/images/categ2.png">
                    <p class="fw-medium m-0 text-center">Asian</p>
                </div>
            </div>
          
            <div class="col-md-4 col-lg-3">
                <div class="categ_itm cursor_p position-relative">
                    <img class="fit-img img-fluid" src="assets/images/categ3.png">
                    <p class="fw-medium m-0 text-center">Amateur</p>
                </div>
            </div>
          
            <div class="col-md-4 col-lg-3">
                <div class="categ_itm cursor_p position-relative">
                    <img class="fit-img img-fluid" src="assets/images/categ4.png">
                    <p class="fw-medium m-0 text-center">Athletic</p>
                </div>
            </div>
          
            <div class="col-md-4 col-lg-3">
                <div class="categ_itm cursor_p position-relative">
                    <img class="fit-img img-fluid" src="assets/images/categ5.png">
                    <p class="fw-medium m-0 text-center">Babes</p>
                </div>
            </div>
          
            <div class="col-md-4 col-lg-3">
                <div class="categ_itm cursor_p position-relative">
                    <img class="fit-img img-fluid" src="assets/images/categ6.png">
                    <p class="fw-medium m-0 text-center">Big Tits</p>
                </div>
            </div>
          
            <div class="col-md-4 col-lg-3">
                <div class="categ_itm cursor_p position-relative">
                    <img class="fit-img img-fluid" src="assets/images/categ7.png">
                    <p class="fw-medium m-0 text-center">Blonde</p>
                </div>
            </div>
          
            <div class="col-md-4 col-lg-3">
                <div class="categ_itm cursor_p position-relative">
                    <img class="fit-img img-fluid" src="assets/images/categ8.png">
                    <p class="fw-medium m-0 text-center">Bondage</p>
                </div>
            </div>
          
            <div class="col-md-4 col-lg-3">
                <div class="categ_itm cursor_p position-relative">
                    <img class="fit-img img-fluid" src="assets/images/categ9.png">
                    <p class="fw-medium m-0 text-center">Big Ass</p>
                </div>
            </div>
          
            <div class="col-md-4 col-lg-3">
                <div class="categ_itm cursor_p position-relative">
                    <img class="fit-img img-fluid" src="assets/images/categ10.png">
                    <p class="fw-medium m-0 text-center">Big Natural Tits</p>
                </div>
            </div>
          
            <div class="col-md-4 col-lg-3">
                <div class="categ_itm cursor_p position-relative">
                    <img class="fit-img img-fluid" src="assets/images/categ11.png">
                    <p class="fw-medium m-0 text-center">Blowjob</p>
                </div>
            </div>
          
            <div class="col-md-4 col-lg-3">
                <div class="categ_itm cursor_p position-relative">
                    <img class="fit-img img-fluid" src="assets/images/categ12.png">
                    <p class="fw-medium m-0 text-center">Brutal Punishment</p>
                </div>
            </div>
          
            <div class="col-md-4 col-lg-3">
                <div class="categ_itm cursor_p position-relative">
                    <img class="fit-img img-fluid" src="assets/images/categ1.png">
                    <p class="fw-medium m-0 text-center">Anal</p>
                </div>
            </div>
          
            <div class="col-md-4 col-lg-3">
                <div class="categ_itm cursor_p position-relative">
                    <img class="fit-img img-fluid" src="assets/images/categ2.png">
                    <p class="fw-medium m-0 text-center">Asian</p>
                </div>
            </div>
          
            <div class="col-md-4 col-lg-3">
                <div class="categ_itm cursor_p position-relative">
                    <img class="fit-img img-fluid" src="assets/images/categ3.png">
                    <p class="fw-medium m-0 text-center">Amateur</p>
                </div>
            </div>
          
            <div class="col-md-4 col-lg-3">
                <div class="categ_itm cursor_p position-relative">
                    <img class="fit-img img-fluid" src="assets/images/categ4.png">
                    <p class="fw-medium m-0 text-center">Athletic</p>
                </div>
            </div>
          
            <div class="col-md-4 col-lg-3">
                <div class="categ_itm cursor_p position-relative">
                    <img class="fit-img img-fluid" src="assets/images/categ5.png">
                    <p class="fw-medium m-0 text-center">Babes</p>
                </div>
            </div>
          
            <div class="col-md-4 col-lg-3">
                <div class="categ_itm cursor_p position-relative">
                    <img class="fit-img img-fluid" src="assets/images/categ6.png">
                    <p class="fw-medium m-0 text-center">Big Tits</p>
                </div>
            </div>
          
            <div class="col-md-4 col-lg-3">
                <div class="categ_itm cursor_p position-relative">
                    <img class="fit-img img-fluid" src="assets/images/categ7.png">
                    <p class="fw-medium m-0 text-center">Blonde</p>
                </div>
            </div>
          
            <div class="col-md-4 col-lg-3">
                <div class="categ_itm cursor_p position-relative">
                    <img class="fit-img img-fluid" src="assets/images/categ8.png">
                    <p class="fw-medium m-0 text-center">Bondage</p>
                </div>
            </div>
          
            <div class="col-md-4 col-lg-3">
                <div class="categ_itm cursor_p position-relative">
                    <img class="fit-img img-fluid" src="assets/images/categ9.png">
                    <p class="fw-medium m-0 text-center">Big Ass</p>
                </div>
            </div>
          
            <div class="col-md-4 col-lg-3">
                <div class="categ_itm cursor_p position-relative">
                    <img class="fit-img img-fluid" src="assets/images/categ10.png">
                    <p class="fw-medium m-0 text-center">Big Natural Tits</p>
                </div>
            </div>
          
            <div class="col-md-4 col-lg-3">
                <div class="categ_itm cursor_p position-relative">
                    <img class="fit-img img-fluid" src="assets/images/categ11.png">
                    <p class="fw-medium m-0 text-center">Blowjob</p>
                </div>
            </div>
          
            <div class="col-md-4 col-lg-3">
                <div class="categ_itm cursor_p position-relative">
                    <img class="fit-img img-fluid" src="assets/images/categ12.png">
                    <p class="fw-medium m-0 text-center">Brutal Punishment</p>
                </div>
            </div> -->
        </div>
    </div>
</section>

<app-footer></app-footer>