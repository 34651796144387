<app-header></app-header>

<section class="breadcrumb-banner">
    <div class="container">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item ps-0 active" [routerLink]="['/home']">Home</li>
                <li class="breadcrumb-item active" [routerLink]="['/my-wallet']">My Wallet</li>
                <li class="breadcrumb-item">View Token</li>
            </ol>
        </nav>
    </div>
</section>

<section class="add-token-sec sec_padding_bottom">
    <div class="container">
        <div class="main-box mt-4 p-5">
            <div class="row">
                <!-- <div class="col-md-6">
                    <div class="img-wrap d-flex align-items-center justify-content-center">
                        <img src="assets/images/add-token-img.svg">
                    </div>
                </div> -->
                <div class="col-md-10">
                    <div class="black-card p-4 mb-4 w-50">
                        <div class="balance d-block lg-d-flex align-items-center mb-3 py-2">
                            <div class="img-wrap me-4">
                                <img class="img-fluid" src="assets/images/coins.svg">
                            </div>
                            <div class="token_list">
                                <div class="text-sec d-flex  align-items-center mb-3">
                                    <p class="color_grey mb-0 "> Total Token :</p>
                                    <p class="fw-regular mb-0 ms-3">
                                        {{tokenDetail?.totalToken==''?'0':tokenDetail?.totalToken}} Tokens</p>
                                </div>
                                <div class="text-sec d-flex align-items-center mb-3">
                                    <p class="color_grey mb-0 "> Common Token :</p>
                                    <p class="fw-regular mb-0 ms-3">
                                        {{tokenDetail?.commonToken==''?'0':tokenDetail?.commonToken}} Tokens</p>
                                </div>

                                <div class="text-sec d-flex align-items-center mb-3">
                                    <p class="color_grey mb-0"> Bonus :</p>
                                    <p class="fw-regular mb-0 ms-3">
                                        {{tokenDetail?.bonusToken==''?'0':tokenDetail?.bonusToken}} Tokens
                                    </p>
                                </div>

                                <div class="text-sec d-flex align-items-center mb-3">
                                    <p class="color_grey mb-0"> Admin Token :</p>
                                    <p class="fw-regular mb-0 ms-3">
                                        {{tokenDetail?.adminToken==''?'0':tokenDetail?.adminToken}} Tokens <span
                                            class="info_icon cursor_p" [routerLink]="['/bonus-token']"> <img
                                                class="img-fluid" src="assets/images/info_icon.svg"> </span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="notes">
                        <h2 class="ff_kalam fw-regular mb-0"> Note </h2>
                        <p class="mb-0"> These tokens can be used as follows:</p>
                        <ul class="p-0 fs14 color_grey">
                            <li>
                                (1) Common tokens can be used to purchase any video of any studio.
                            </li>
                            <li>
                                (2) Admin token can be used to purchase only the token given for the particular studio
                                as
                                shown in below list.
                            </li>
                            <!-- <li>
                                (3) Bonus token can be used only to purchase the choke chamber videos.
                            </li> -->

                        </ul>
                    </div>

                </div>
            </div>
        </div>
    </div>

</section>

<app-footer></app-footer>