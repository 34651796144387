


<!-- Banner section Start -->
<section id="banner" class="position-relative video-wrapper">
<div class="position-absolute top-0 w-100 z-999">
  <section class="headerTopbar bg-black">
    <div class="container">
      <div class="row py-2">
        <div class="col-8">
          <ul class="list-unstyled list-inline m-0">
            <li class="list-inline-item me-3 me-lg-4 d-none d-lg-inline-block">For site related questions, comments or custom inquiries, please email us at:</li>
            
            <li class="list-inline-item">
              <a href="mailto:contact@bluewatercyninc.com" class="text-decoration-none text-white d-flex"><i
                  class="listIcon me-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path
                      d="M16.3 2H3.7C2.98392 2 2.29716 2.31607 1.79081 2.87868C1.28446 3.44129 1 4.20435 1 5V15C1 15.7956 1.28446 16.5587 1.79081 17.1213C2.29716 17.6839 2.98392 18 3.7 18H16.3C17.0161 18 17.7028 17.6839 18.2092 17.1213C18.7155 16.5587 19 15.7956 19 15V5C19 4.20435 18.7155 3.44129 18.2092 2.87868C17.7028 2.31607 17.0161 2 16.3 2ZM15.697 4L10 8.75L4.303 4H15.697ZM16.3 16H3.7C3.46131 16 3.23239 15.8946 3.0636 15.7071C2.89482 15.5196 2.8 15.2652 2.8 15V5.25L9.46 10.8C9.61579 10.9298 9.80527 11 10 11C10.1947 11 10.3842 10.9298 10.54 10.8L17.2 5.25V15C17.2 15.2652 17.1052 15.5196 16.9364 15.7071C16.7676 15.8946 16.5387 16 16.3 16Z"
                      fill="#9A2B4C" />
                  </svg>
                </i><span class="d-none d-sm-inline-block">contact&#64;bluewatercyninc.com</span>
              </a></li>
          </ul>
        </div>

        <div class="col text-end">
          <ul class="list-unstyled list-inline m-0">
            <li class="list-inline-item"><a role="button" class="text-decoration-none text-white"
                [routerLink]="['/user-login']"><i class="listIcon me-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M11 14L15 10L11 6" stroke="#9A2B4C" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round" />
                    <path d="M1 10L13 10" stroke="#9A2B4C" stroke-width="2" stroke-linecap="round" />
                    <path
                      d="M7 14V16.9451C7 17.6301 7 19 9.3913 19C11.3043 18.589 14.6522 17.8013 16.087 17.4588C16.4134 17.3809 18 16.9451 18 15.404C18 13.7601 18 7.18444 18 4.10211C18 3.58839 17.8883 2.53094 16.087 2.04723C14.8409 1.71262 10.9855 1.19103 9.3913 1.01979C8.53566 0.927886 7 1.11001 7 2.56095C7 4.20486 7 5.1438 7 6"
                      stroke="#9A2B4C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </i> <span class="d-none d-sm-inline-block">Login/Signup</span> </a>
              </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <!-- Top bar section End -->
  
  <header class="ff_smooch">
    <div class="container position-relative">
      <nav class="navbar-expand-lg navbar-dark">
        <div class="container-fluid align-items-baseline">
          <a class="navbar-brand  d-none" href="#">Navbar</a>
          <div class="d-block d-lg-none logo-mobile">
            <a class="navbar-brand m-0" href="#">
              <img class="img-fluid" src="assets/images/logo_header_mobile.png" alt="">
            </a>
          </div>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse justify-content-lg-center" id="navbarNavDropdown">
            <ul class="navbar-nav navbar-nav-block text-uppercase">
              <li class="nav-item">
                <a class="nav-link active" aria-current="page" href="#banner">Home</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#aboutUs">REGISTER FOR FREE
                  <!-- how it works -->
                </a>
              </li>
              <!-- Brand logo -->
              <li class="nav-item d-none d-lg-block">
                <a class="navbar-brand m-0" href="#">
                  <img class="img-fluid" src="assets/images/logo_header.png" alt="">
                </a>
              </li>
              <!-- Brand logo end-->
              <li class="nav-item">
                <a class="nav-link" href="#Ourmodels">Models</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#exampleVideos">Samples
                  <!-- Videos -->
                </a>
              </li>
             <li class="nav-item">
                <a class="nav-link" href="#download">Download App</a>
              </li>
            </ul>
          </div>
          <div class="header_bg" style="background: url(assets/images/header_bg.png) no-repeat top center;"></div>
        </div>
      </nav>
    </div>
  </header>
</div>
  <div class="bannerTextCont h-100 d-flex align-items-center">
    <video class="background" src="assets/images/banner-video.mp4" type="video/mp4" playsinline loop autoplay [muted]="true" ></video>
  </div>
</section>

<!-- Example Videos Section Start -->
<section id="exampleVideos" class="example sec_padding position-relative"
  style="background: url(assets/images/women-5634282.jpg) no-repeat;">
  <div class="container position-relative">
    <div class="center_heading text-center mb-4 position-relative">
      <h3 class="ff_kalam fw-regular display-4">
        <!-- Example Videos -->
        Samples
      </h3>
      <img src="assets/images/border_shade.png" alt="">
      <!-- <div class="slider_arrows">
        <div class="lefArrow me-4 cursor_pointer">
          <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 22 42" fill="none">
            <path d="M21 41L1 21L21 1" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>

        <div class="rightArrow cursor_pointer">
          <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 22 42" fill="none">
            <path d="M1 0.999999L21 21L1 41" stroke="white" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round" />
          </svg>
        </div>

      </div> -->
    </div>

    <div class="exampleVideo1">
      <!-- Slide One -->
      <div class="row">
        <div class="col-md-4 col-lg-4">
          <div class="videoContainer position-relative cursor_pointer aboutVideo" (click)="aboutVideo()">
            <div class="vedioImg d-flex align-items-center justify-content-center border"> 
              <!-- <img class="img-fluid" src="assets/images/img-video.png" alt=""> -->
               <!-- testing playsinline -->
              <!-- <div class="playIcon position-absolute d-inline-block ">
                <img src="assets/images/play_icon.svg" alt="">
              </div> -->
              <video autoplay loop muted playsinline  width="100%" height="480px"  >
                <source src="assets/videos/Sample-Video2.mp4" type="video/mp4">
              </video>
            </div>
            <!-- <div class="videoTitle ff_Roboto text-center py-3">
              <h5 class="fw-light mb-0">Jogging To Death</h5>
            </div> -->
          </div>
        </div>
        <div class="col-md-4 col-lg-4">
          <!-- Slide Two -->
          <div class="videoContainer position-relative cursor_pointer playsinline">
            <div class="vedioImg d-flex align-items-center justify-content-center border">
              <video width="100%" height="480px" autoplay loop muted playsinline>
                <source src="assets/videos/Sample-Video6.mp4" type="video/mp4">
              </video>
              <!-- <img class="img-fluid" src="assets/images/video_img2.jpg" alt="">
              <div class="playIcon position-absolute d-inline-block ">
                <img src="assets/images/play_icon.svg" alt="">
              </div> -->
            </div>
            <!-- <div class="videoTitle ff_Roboto text-center py-3">
              <h5 class="fw-light mb-0">Cheap Hostel</h5>
            </div> -->
          </div>
        </div>
        <div class="col-md-4 col-lg-4">
          <!-- Slide Three -->
          <div class="videoContainer position-relative cursor_pointer">
            <div class="vedioImg d-flex align-items-center justify-content-center border">
              <video autoplay loop muted playsinline width="100%" height="480px" >
                <source src="assets/videos/demovedio1.mp4" type="video/mp4">
              </video>
              <!-- <img class="img-fluid" src="assets/images/video_img4.jpg" alt="">
              <div class="playIcon position-absolute d-inline-block ">
                <img src="assets/images/play_icon.svg" alt="">
              </div> -->
            </div>
            <!-- <div class="videoTitle ff_Roboto text-center py-3">
              <h5 class="fw-light mb-0">Offended Customer</h5>
            </div> -->
          </div>
        </div>
        <div class="col-md-4 col-lg-4">
          <!-- Slide Three -->
          <div class="videoContainer position-relative cursor_pointer">
            <div class="vedioImg d-flex align-items-center justify-content-center">
              <video width="100%" height="480px" autoplay loop muted playsinline>
                <source src="assets/videos/Sample-Video1.mp4" type="video/mp4">
              </video>
              <!-- <img class="img-fluid" src="assets/images/video_img5.jpg" alt="">
              <div class="playIcon position-absolute d-inline-block ">
                <img src="assets/images/play_icon.svg" alt="">
              </div> -->
            </div>
            <!-- <div class="videoTitle ff_Roboto text-center py-3">
              <h5 class="fw-light mb-0">Offended Customer</h5>
            </div> -->
          </div>
        </div>
        <div class="col-md-4 col-lg-4">
          <!-- Slide Three -->
          <div class="videoContainer position-relative cursor_pointer">
            <div class="vedioImg d-flex align-items-center justify-content-center">
              <video width="100%" height="480px" playsinline  controls>
                <source src="assets/videos/Sample-Video3.mp4" type="video/mp4">
              </video>
              <!-- <img class="img-fluid" src="assets/images/video_img6.jpg" alt="">
              <div class="playIcon position-absolute d-inline-block ">
                <img src="assets/images/play_icon.svg" alt="">
              </div> -->
            </div>
            <!-- <div class="videoTitle ff_Roboto text-center py-3">
              <h5 class="fw-light mb-0">Offended Customer</h5>
            </div> -->
          </div>
        </div>
        <div class="col-md-4 col-lg-4">
          <!-- Slide Three -->
          <div class="videoContainer position-relative cursor_pointer">
            <div class="vedioImg d-flex align-items-center justify-content-center">
              <video width="100%" height="480px" controls playsinline>
                <source src="assets/videos/Sample-Video4.mp4" type="video/mp4">
              </video>
              <!-- <img class="img-fluid" src="assets/images/video_img7.jpg" alt="">
              <div class="playIcon position-absolute d-inline-block ">
                <img src="assets/images/play_icon.svg" alt="">
              </div> -->
            </div>
            <!-- <div class="videoTitle ff_Roboto text-center py-3">
              <h5 class="fw-light mb-0">Offended Customer</h5>
            </div> -->
          </div>
        </div>
        <div class="col-md-4 col-lg-4">
          <!-- Slide Three -->
          <div class="videoContainer position-relative cursor_pointer">
            <div class="vedioImg d-flex align-items-center justify-content-center">
              <video width="100%" height="480px" controls playsinline>
                <source src="assets/videos/Sample-Video5.mp4" type="video/mp4">
              </video>
            </div>
            <!-- <div class="videoTitle ff_Roboto text-center py-3">
              <h5 class="fw-light mb-0">Offended Customer</h5>
            </div> -->
          </div>
        </div>
        <!-- <div class="col-md-4 col-lg-4">
          <div class="videoContainer position-relative cursor_pointer">
            <div class="vedioImg d-flex align-items-center justify-content-center">
              <img class="img-fluid" src="assets/images/video_img3.jpg" alt="">
              <div class="playIcon position-absolute d-inline-block ">
                <img src="assets/images/play_icon.svg" alt="">
              </div>
            </div>
            <div class="videoTitle ff_Roboto text-center py-3">
              <h5 class="fw-light mb-0">Offended Customer</h5>
            </div>
          </div>
        </div> -->
      </div>
      <!-- Slide Four -->
      <!-- <div class="videoContainer position-relative cursor_pointer">
        <div class="vedioImg d-flex align-items-center justify-content-center">
          <img class="img-fluid" src="assets/images/eroticas_fotos.jpg" alt="">
          <div class="playIcon position-absolute d-inline-block">
            <img src="assets/images/play_icon.svg" alt="">
          </div>
        </div>
        <div class="videoTitle ff_Roboto text-center py-3">
          <h5 class="fw-light mb-0">Dream Girl</h5>
        </div>
      </div> -->
    </div>
  </div>
</section>

<!-- Our Models Section Start -->
<section id="Ourmodels" class="ourModels sec_padding px-5">
  <div class="container-fluid">
    <div class="center_heading text-center mb-5">
      <h3 class="ff_kalam fw-regular display-4">Our Models</h3>
      <img src="assets/images/border_shade.png" alt="">
    </div>
    <div class="modelSliderContainer position-relative">
      <div class="models_slider">
        <!-- Slide One -->
        <div class="modelContainer position-relative cursor_pointer" (click)="artist_detail()">
          <div class="model_img">
            <img class="img-fluid" src="assets/images/models/model-1.jpg" alt="" />
            <div class="overlay"></div>
          </div>

          <div class="item_content">
            <h4 class="fw-light mb-5">Erika <br />
              Jordan</h4>
            <div class="info">
              <p class="mb-1 fw-bold">Gender</p>
              <h4 class="fw-light m-0">Female</h4>
            </div>
            <div class="detailEye position-absolute">
              <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                <circle cx="25" cy="25" r="25" fill="black" />
                <path
                  d="M25 19.5C26.8387 19.4939 28.6419 20.0068 30.2021 20.9797C31.7624 21.9527 33.0164 23.3462 33.82 25C32.17 28.37 28.8 30.5 25 30.5C21.2 30.5 17.83 28.37 16.18 25C16.9836 23.3462 18.2376 21.9527 19.7979 20.9797C21.3581 20.0068 23.1613 19.4939 25 19.5ZM25 17.5C20 17.5 15.73 20.61 14 25C15.73 29.39 20 32.5 25 32.5C30 32.5 34.27 29.39 36 25C34.27 20.61 30 17.5 25 17.5ZM25 22.5C25.663 22.5 26.2989 22.7634 26.7678 23.2322C27.2366 23.7011 27.5 24.337 27.5 25C27.5 25.663 27.2366 26.2989 26.7678 26.7678C26.2989 27.2366 25.663 27.5 25 27.5C24.337 27.5 23.7011 27.2366 23.2322 26.7678C22.7634 26.2989 22.5 25.663 22.5 25C22.5 24.337 22.7634 23.7011 23.2322 23.2322C23.7011 22.7634 24.337 22.5 25 22.5ZM25 20.5C22.52 20.5 20.5 22.52 20.5 25C20.5 27.48 22.52 29.5 25 29.5C27.48 29.5 29.5 27.48 29.5 25C29.5 22.52 27.48 20.5 25 20.5Z"
                  fill="black" />
                <path
                  d="M35.5795 23.8137C36.1212 24.5223 36.1212 25.4789 35.5795 26.1863C33.8732 28.4137 29.7795 33 25 33C20.2206 33 16.1269 28.4137 14.4206 26.1863C14.157 25.847 14.014 25.4296 14.014 25C14.014 24.5704 14.157 24.153 14.4206 23.8137C16.1269 21.5863 20.2206 17 25 17C29.7795 17 33.8732 21.5863 35.5795 23.8137V23.8137Z"
                  stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M25 28.4287C26.8936 28.4287 28.4286 26.8937 28.4286 25.0001C28.4286 23.1066 26.8936 21.5715 25 21.5715C23.1065 21.5715 21.5715 23.1066 21.5715 25.0001C21.5715 26.8937 23.1065 28.4287 25 28.4287Z"
                  stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
          </div>
        </div>

        <!-- Slide Two -->
        <div class="modelContainer position-relative cursor_pointer">
          <div class="model_img">
            <img class="img-fluid" src="assets/images/models/model-2.jpg" alt="" />
            <div class="overlay"></div>
          </div>
          <div class="item_content">
            <h4 class="fw-light mb-5">Erika <br />
              Jordan</h4>
            <div class="info">
              <p class="mb-1 fw-bold">Gender</p>
              <h4 class="fw-light m-0">Female</h4>
            </div>
            <div class="detailEye position-absolute">
              <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                <circle cx="25" cy="25" r="25" fill="black" />
                <path
                  d="M25 19.5C26.8387 19.4939 28.6419 20.0068 30.2021 20.9797C31.7624 21.9527 33.0164 23.3462 33.82 25C32.17 28.37 28.8 30.5 25 30.5C21.2 30.5 17.83 28.37 16.18 25C16.9836 23.3462 18.2376 21.9527 19.7979 20.9797C21.3581 20.0068 23.1613 19.4939 25 19.5ZM25 17.5C20 17.5 15.73 20.61 14 25C15.73 29.39 20 32.5 25 32.5C30 32.5 34.27 29.39 36 25C34.27 20.61 30 17.5 25 17.5ZM25 22.5C25.663 22.5 26.2989 22.7634 26.7678 23.2322C27.2366 23.7011 27.5 24.337 27.5 25C27.5 25.663 27.2366 26.2989 26.7678 26.7678C26.2989 27.2366 25.663 27.5 25 27.5C24.337 27.5 23.7011 27.2366 23.2322 26.7678C22.7634 26.2989 22.5 25.663 22.5 25C22.5 24.337 22.7634 23.7011 23.2322 23.2322C23.7011 22.7634 24.337 22.5 25 22.5ZM25 20.5C22.52 20.5 20.5 22.52 20.5 25C20.5 27.48 22.52 29.5 25 29.5C27.48 29.5 29.5 27.48 29.5 25C29.5 22.52 27.48 20.5 25 20.5Z"
                  fill="black" />
                <path
                  d="M35.5795 23.8137C36.1212 24.5223 36.1212 25.4789 35.5795 26.1863C33.8732 28.4137 29.7795 33 25 33C20.2206 33 16.1269 28.4137 14.4206 26.1863C14.157 25.847 14.014 25.4296 14.014 25C14.014 24.5704 14.157 24.153 14.4206 23.8137C16.1269 21.5863 20.2206 17 25 17C29.7795 17 33.8732 21.5863 35.5795 23.8137V23.8137Z"
                  stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M25 28.4287C26.8936 28.4287 28.4286 26.8937 28.4286 25.0001C28.4286 23.1066 26.8936 21.5715 25 21.5715C23.1065 21.5715 21.5715 23.1066 21.5715 25.0001C21.5715 26.8937 23.1065 28.4287 25 28.4287Z"
                  stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
          </div>
        </div>

        <!-- Slide Three -->
        <div class="modelContainer position-relative cursor_pointer">
          <div class="model_img">
            <img class="img-fluid" src="assets/images/models/model-3.jpg" alt="" />
            <div class="overlay"></div>
          </div>
          <div class="item_content">
            <h4 class="fw-light mb-5">Erika <br />
              Jordan</h4>

            <div class="info">
              <p class="mb-1 fw-bold">Gender</p>
              <h4 class="fw-light m-0">Female</h4>
            </div>

            <div class="detailEye position-absolute">
              <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                <circle cx="25" cy="25" r="25" fill="black" />
                <path
                  d="M25 19.5C26.8387 19.4939 28.6419 20.0068 30.2021 20.9797C31.7624 21.9527 33.0164 23.3462 33.82 25C32.17 28.37 28.8 30.5 25 30.5C21.2 30.5 17.83 28.37 16.18 25C16.9836 23.3462 18.2376 21.9527 19.7979 20.9797C21.3581 20.0068 23.1613 19.4939 25 19.5ZM25 17.5C20 17.5 15.73 20.61 14 25C15.73 29.39 20 32.5 25 32.5C30 32.5 34.27 29.39 36 25C34.27 20.61 30 17.5 25 17.5ZM25 22.5C25.663 22.5 26.2989 22.7634 26.7678 23.2322C27.2366 23.7011 27.5 24.337 27.5 25C27.5 25.663 27.2366 26.2989 26.7678 26.7678C26.2989 27.2366 25.663 27.5 25 27.5C24.337 27.5 23.7011 27.2366 23.2322 26.7678C22.7634 26.2989 22.5 25.663 22.5 25C22.5 24.337 22.7634 23.7011 23.2322 23.2322C23.7011 22.7634 24.337 22.5 25 22.5ZM25 20.5C22.52 20.5 20.5 22.52 20.5 25C20.5 27.48 22.52 29.5 25 29.5C27.48 29.5 29.5 27.48 29.5 25C29.5 22.52 27.48 20.5 25 20.5Z"
                  fill="black" />
                <path
                  d="M35.5795 23.8137C36.1212 24.5223 36.1212 25.4789 35.5795 26.1863C33.8732 28.4137 29.7795 33 25 33C20.2206 33 16.1269 28.4137 14.4206 26.1863C14.157 25.847 14.014 25.4296 14.014 25C14.014 24.5704 14.157 24.153 14.4206 23.8137C16.1269 21.5863 20.2206 17 25 17C29.7795 17 33.8732 21.5863 35.5795 23.8137V23.8137Z"
                  stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M25 28.4287C26.8936 28.4287 28.4286 26.8937 28.4286 25.0001C28.4286 23.1066 26.8936 21.5715 25 21.5715C23.1065 21.5715 21.5715 23.1066 21.5715 25.0001C21.5715 26.8937 23.1065 28.4287 25 28.4287Z"
                  stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>
          </div>
        </div>

        <!-- Slide Four -->
        <div class="modelContainer position-relative cursor_pointer">
          <div class="model_img">
            <img class="img-fluid" src="assets/images/models/model-4.jpg" alt="" />
            <div class="overlay"></div>
          </div>
          <div class="item_content">
            <h4 class="fw-light mb-5">Erika <br />
              Jordan</h4>
            <div class="info">
              <p class="mb-1 fw-bold">Gender</p>
              <h4 class="fw-light m-0">Female</h4>
            </div>

            <div class="detailEye position-absolute">
              <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                <circle cx="25" cy="25" r="25" fill="black" />
                <path
                  d="M25 19.5C26.8387 19.4939 28.6419 20.0068 30.2021 20.9797C31.7624 21.9527 33.0164 23.3462 33.82 25C32.17 28.37 28.8 30.5 25 30.5C21.2 30.5 17.83 28.37 16.18 25C16.9836 23.3462 18.2376 21.9527 19.7979 20.9797C21.3581 20.0068 23.1613 19.4939 25 19.5ZM25 17.5C20 17.5 15.73 20.61 14 25C15.73 29.39 20 32.5 25 32.5C30 32.5 34.27 29.39 36 25C34.27 20.61 30 17.5 25 17.5ZM25 22.5C25.663 22.5 26.2989 22.7634 26.7678 23.2322C27.2366 23.7011 27.5 24.337 27.5 25C27.5 25.663 27.2366 26.2989 26.7678 26.7678C26.2989 27.2366 25.663 27.5 25 27.5C24.337 27.5 23.7011 27.2366 23.2322 26.7678C22.7634 26.2989 22.5 25.663 22.5 25C22.5 24.337 22.7634 23.7011 23.2322 23.2322C23.7011 22.7634 24.337 22.5 25 22.5ZM25 20.5C22.52 20.5 20.5 22.52 20.5 25C20.5 27.48 22.52 29.5 25 29.5C27.48 29.5 29.5 27.48 29.5 25C29.5 22.52 27.48 20.5 25 20.5Z"
                  fill="black" />
                <path
                  d="M35.5795 23.8137C36.1212 24.5223 36.1212 25.4789 35.5795 26.1863C33.8732 28.4137 29.7795 33 25 33C20.2206 33 16.1269 28.4137 14.4206 26.1863C14.157 25.847 14.014 25.4296 14.014 25C14.014 24.5704 14.157 24.153 14.4206 23.8137C16.1269 21.5863 20.2206 17 25 17C29.7795 17 33.8732 21.5863 35.5795 23.8137V23.8137Z"
                  stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M25 28.4287C26.8936 28.4287 28.4286 26.8937 28.4286 25.0001C28.4286 23.1066 26.8936 21.5715 25 21.5715C23.1065 21.5715 21.5715 23.1066 21.5715 25.0001C21.5715 26.8937 23.1065 28.4287 25 28.4287Z"
                  stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>

          </div>
        </div>

        <!-- Slide Five -->
        <div class="modelContainer position-relative cursor_pointer">
          <div class="model_img">
            <img class="img-fluid" src="assets/images/models/model-5.jpg" alt="" />
            <div class="overlay"></div>
          </div>

          <div class="item_content">
            <h4 class="fw-light mb-5">Erika <br />Jordan</h4>

            <div class="info">
              <p class="mb-1 fw-bold">Gender</p>
              <h4 class="fw-light m-0">Female</h4>
            </div>

            <div class="detailEye position-absolute">
              <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                <circle cx="25" cy="25" r="25" fill="black" />
                <path
                  d="M25 19.5C26.8387 19.4939 28.6419 20.0068 30.2021 20.9797C31.7624 21.9527 33.0164 23.3462 33.82 25C32.17 28.37 28.8 30.5 25 30.5C21.2 30.5 17.83 28.37 16.18 25C16.9836 23.3462 18.2376 21.9527 19.7979 20.9797C21.3581 20.0068 23.1613 19.4939 25 19.5ZM25 17.5C20 17.5 15.73 20.61 14 25C15.73 29.39 20 32.5 25 32.5C30 32.5 34.27 29.39 36 25C34.27 20.61 30 17.5 25 17.5ZM25 22.5C25.663 22.5 26.2989 22.7634 26.7678 23.2322C27.2366 23.7011 27.5 24.337 27.5 25C27.5 25.663 27.2366 26.2989 26.7678 26.7678C26.2989 27.2366 25.663 27.5 25 27.5C24.337 27.5 23.7011 27.2366 23.2322 26.7678C22.7634 26.2989 22.5 25.663 22.5 25C22.5 24.337 22.7634 23.7011 23.2322 23.2322C23.7011 22.7634 24.337 22.5 25 22.5ZM25 20.5C22.52 20.5 20.5 22.52 20.5 25C20.5 27.48 22.52 29.5 25 29.5C27.48 29.5 29.5 27.48 29.5 25C29.5 22.52 27.48 20.5 25 20.5Z"
                  fill="black" />
                <path
                  d="M35.5795 23.8137C36.1212 24.5223 36.1212 25.4789 35.5795 26.1863C33.8732 28.4137 29.7795 33 25 33C20.2206 33 16.1269 28.4137 14.4206 26.1863C14.157 25.847 14.014 25.4296 14.014 25C14.014 24.5704 14.157 24.153 14.4206 23.8137C16.1269 21.5863 20.2206 17 25 17C29.7795 17 33.8732 21.5863 35.5795 23.8137V23.8137Z"
                  stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M25 28.4287C26.8936 28.4287 28.4286 26.8937 28.4286 25.0001C28.4286 23.1066 26.8936 21.5715 25 21.5715C23.1065 21.5715 21.5715 23.1066 21.5715 25.0001C21.5715 26.8937 23.1065 28.4287 25 28.4287Z"
                  stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </div>

          </div>
        </div>
      </div>
      <div class="slide_arrow position-absolute w-100">
        <ul class="list-unstyled">
          <li class="prevText"></li>
          <li class="nextText"></li>
        </ul>
      </div>
    </div>
  </div>
</section>

<!-- About Section Start -->

<section id="aboutUs" class="landingAbout"
  style="background: url(assets/images/pattern_bg.jpg) no-repeat center top / cover;">
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-5">
        <div class="aboutImg">
          <img class="img-fluid w-100" src="assets/images/about_img.jpg" alt="">
        </div>
      </div>
      <div class="col-12 col-lg-7">
        <div class="about_text ps-xl-5 mt-0">
          <h1 class="ff_squarePeg mb-4">REGISTER FOR FREE</h1>
          <img class="mb-5" src="assets/images/border_shade.png" alt="">
          <!-- <p class="mt-3 mb-4 pb-3 fw-light h5">Duis rhoncus ante vitae venenatis consectetur. Pellentesque habitant morbi tristique senectus et netus et malesuada.</p> -->
        </div>

        <div class="ps-xl-5">
          <div class="studio_form">
            <form [formGroup]="memberForm">
              <div class="mb-4 pb-1">
                <ng-container><label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Full Name <span
                      class="color_danger">*</span></label>
                  <input type="text" class="form-control fs18 cmn-input input_60 ff_Roboto" formControlName="name"
                    placeholder="Enter your full name" (keydown)="checkEvent($event)"></ng-container>
                <small *ngIf="!memberForm.controls?.['name']?.valid &&
                  memberForm.controls?.['name']?.dirty" class="error_main_div">
                  <small *ngIf="memberForm.controls?.['name']?.hasError('required')" class="error_message">This field is
                    Mandatory !</small>
                </small>
              </div>
              <div class="mb-4">
                <ng-container> <label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Email Address
                    <span class="color_danger">*</span></label>
                  <input type="text" class="form-control fs18 cmn-input input_60 ff_Roboto"
                    placeholder="Enter email address" formControlName="email"
                    (keydown)="checkEvent($event)"></ng-container>
                <small *ngIf="!memberForm.controls?.['email']?.valid &&
                  memberForm.controls?.['email']?.dirty">
                  <small
                    *ngIf="memberForm.controls?.['email']?.hasError('invalidEmail') && memberForm.controls?.['email']?.value"
                    class="error_message">Invalid email !
                  </small>
                  <small class="error_message" *ngIf="memberForm.controls?.['email']?.hasError('required')">This field
                    is
                    Mandatory !
                  </small>
                </small>
              </div>
              <div class="mb-4">
                <ng-container>
                  <label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Date of birth</label>
                  <input type="date" class="form-control fs18 cmn-input input_60 ff_Roboto" formControlName="dob"
                    placeholder="DD/MM/YYYY" [ngModel]="theDate | date:'yyyy-MM-dd'" name="theDate"
                    (ngModelChange)="theDate = $event">
                </ng-container>
                <small *ngIf="!memberForm.controls?.['dob']?.valid &&
                    memberForm.controls?.['dob']?.dirty" class="error_main_div">
                  <small *ngIf="memberForm.controls?.['dob']?.hasError('required')" class="error_message">This
                    field
                    is
                    Mandatory !
                  </small>
                </small>
              </div>

              <div class="mb-4 position-relative">
                <ng-container>
                  <label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Password<span
                      class="color_danger">*</span></label>
                  <input [type]="showPassword ? 'text' : 'password'" class="form-control fs18 cmn-input input_60 ff_Roboto"
                  
                    placeholder="Enter password" formControlName="password" (keydown)="checkEvent($event)">
                </ng-container>
                <!-- pattern="(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{8,}).*$" -->
                <small *ngIf="!memberForm.controls?.['password']?.valid &&
                  memberForm.controls?.['password']?.dirty" class="error_main_div">
                  <small *ngIf="memberForm.controls?.['password']?.hasError('required')" class="error_message">This
                    field
                    is
                    Mandatory !
                  </small>
                  <small *ngIf="memberForm.controls?.['password']?.hasError('pattern')" class="error_message">Password
                    must contain uppercase, lowercase, number and special characters and min length should be 10.
                  </small>
                </small>
                <span class="eye-icon" (click)="togglePassword()">
                  <img [src]="showPassword ? 'assets/images/eye-open.png' : 'assets/images/eye-close.png'" 
                    alt="Toggle Password"/>
                </span>
              </div>

              <div class="mb-4 position-relative">
                <ng-container>
                  <label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Confirm Password <span
                      class="color_danger">*</span></label>
                  <input [type]="showPassword1 ? 'text' : 'password'" class="form-control fs18 cmn-input input_60 ff_Roboto"
                    placeholder="Enter confirm password" formControlName="confirmPassword"
                    (keydown)="checkEvent($event)">
                </ng-container>
                <small *ngIf="!memberForm.controls?.['confirmPassword']?.valid &&
               memberForm.controls?.['confirmPassword']?.dirty" class="error_main_div">
                  <small
                    *ngIf="memberForm.controls?.['confirmPassword']?.invalid && memberForm.controls?.['confirmPassword']?.errors?.['ConfirmPassword']"
                    class="error_message">Passwords must match !</small>
                  <small *ngIf="memberForm.controls?.['confirmPassword']?.hasError('required')"
                    class="error_message">This field is Mandatory !
                  </small>
                </small>
                <span class="eye-icon" (click)="togglePassword1()">
                  <img [src]="showPassword1 ? 'assets/images/eye-open.png' : 'assets/images/eye-close.png'" 
                    alt="Toggle Password"/>
                </span>
              </div>
              <div class="terms-sec d-flex align-items-center mb-4 pb-2">
                <!-- <mat-checkbox class="me-2" color="primary"></mat-checkbox> -->
                <mat-checkbox class="me-2" color="primary" formControlName="acceptCheckbox">
                </mat-checkbox>
                <p class="h6 mb-0 fw-normal" role="button">Accept our <span class="text-decoration-underline"
                    (click)="termsConditions()">terms and conditions</span></p>
              </div>
              <button class="btn w-100 purple_btn btn70 fw-bold" type="submit" (click)="becomeMember()"
                [disabled]="!memberForm.valid">REGISTER FOR FREE</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- Become a studio section  -->
<section class="become_studio sec_padding">
  <div class="container">
    
    <h2 class="text-white ff_kalam mb-0 text-center fw-normal mb-3">If you are a studio, You can register with</h2>
    <h3 class="text-white mb-5 ff_squarePeg text-center"><span class="position-relative">Vixen Horror House
        Productions...</span></h3>
    <button class="red_btn btn btn70 mx-auto d-block w-100" [routerLink]="['/studio-signup']">Become A
      Studio</button>
    <h5 class="text-white ff_kalam mb-0 text-center fw-normal mt-3">Already have an account? <a
        [routerLink]="['/studio-login']">LOGIN</a></h5>

  </div>
</section>

<!------app store-------->
<section class="app_store become_studio sec_padding" id="download">
    <div class="container">
        <div class="d-flex align-items-center justify-content-center gap-3">
          <!-- <div class="btn-download d-flex align-items-center">
            <div class="imgios"><img src="../../../../assets/images/ios-app.svg" alt=""/></div>
            <div>
              <p>Download</p>
              <h5>IOS App</h5>
            </div>
          </div> -->
          <a href="javascript:;"><img src="../../../../assets/images/download-ios-btn.svg" alt=""/></a>
          <!-- <a href="https://drive.google.com/file/d/139tCC7avAvrVHsj8HOHEBaubo_0uZzJ6/view?usp=sharing" target="_blank" (click)="download()">
            <img src="../../../../assets/images/download-andriod-btn.svg" alt=""/></a> -->
            <a href="../../../../assets/apk/Blu Water Media.apk" download target="_blank">
              <img src="../../../../assets/images/download-andriod-btn.svg" alt="Download APK"/>
          </a>
          
        </div>
    </div>
</section>



<!-- PEOPLE SAYS Section Start -->
<!-- 
<section id="testMonials" class="testimonials sec_padding"
  style="background: url(assets/images/pattern_bg.jpg) no-repeat center top / cover;">
  <div class="container">
    <div class="center_heading text-center mb-5">
      <h3 class="ff_kalam fw-regular display-4">Testamonials
      </h3>
      <img src="assets/images/border_shade.png" alt="">
    </div>

    <div class="testimonial_slider px-5">
      <div class="text-center mb-4">
        <svg xmlns="http://www.w3.org/2000/svg" width="92" height="92" viewBox="0 0 92 92" fill="none">
          <path
            d="M8.16757 73.9658C2.85323 68.3666 0 62.0867 0 51.9068C0 33.9936 12.677 17.9381 31.1121 10L35.7196 17.0527C18.5125 26.2857 15.1485 38.2672 13.807 45.8214C16.5776 44.3986 20.2048 43.9022 23.7597 44.2297C33.0676 45.0844 40.4045 52.6643 40.4045 62.0867C40.4045 66.8376 38.5019 71.3939 35.1153 74.7533C31.7287 78.1127 27.1354 80 22.346 80C19.6974 79.977 17.0799 79.4317 14.6457 78.3958C12.2116 77.3598 10.0095 75.8539 8.16757 73.9658ZM59.7631 73.9658C54.4488 68.3666 51.5955 62.0867 51.5955 51.9068C51.5955 33.9936 64.2726 17.9381 82.7077 10L87.3151 17.0527C70.108 26.2857 66.744 38.2672 65.4025 45.8214C68.1732 44.3986 71.8004 43.9022 75.3553 44.2297C84.6631 45.0844 92 52.6643 92 62.0867C92 66.8376 90.0974 71.3939 86.7108 74.7533C83.3242 78.1127 78.731 80 73.9416 80C71.2929 79.977 68.6754 79.4317 66.2412 78.3958C63.8071 77.3598 61.605 75.8539 59.7631 73.9658Z"
            fill="white" />
        </svg>
      </div>
      <div class="slider_cont">
       
        <div class="testimonial_col text-center ">
          <p class="fw-light">In cursus ex nec magna fermentum ultricies. Nunc ut magna ligula. Cras a odio odio. Fusce
            interdum pretium nulla a maximus. Vestibulum vel justo in augue pharetra suscipit. Quisque faucibus purus
            sed rhoncus semper. Nullam vel blandit nulla. Sed suscipit leo vestibulum nulla consequat bibendum.</p>
          <h5 class="ff_smooch fw-semi-bold letterSpacing2 text-uppercase">Mr. Danial smith</h5>
        </div>

      
        <div class="testimonial_col text-center ">
          <p class="fw-light">Cras a magna elementum, iaculis justo eu, fermentum elit. In pellentesque tortor mauris,
            vitae tristique metus egestas nec. Suspendisse potenti. Maecenas ultrices purus condimentum orci gravida
            tincidunt ac sit amet nisi. Etiam mollis interdum nulla id pulvinar. Nulla nec quam in ante vulputate
            lacinia.</p>
          <h5 class="ff_smooch fw-semi-bold letterSpacing2 text-uppercase">Mr. Danial smith</h5>
        </div>
        <div class="testimonial_col text-center ">
          <p class="fw-light">Nulla enim purus, consequat sed imperdiet nec, tincidunt ut sem. Nam aliquam bibendum
            lacus, eget sagittis mauris suscipit et. In molestie elit quis nunc fringilla, quis venenatis elit feugiat.
            Curabitur id nisi libero. Ut eget arcu mollis, tempor tellus ut, pulvinar lorem. Mauris porta dolor ipsum,
            eget placerat magna viverra in.</p>
          <h5 class="ff_smooch fw-semi-bold letterSpacing2 text-uppercase">Mr. Danial smith</h5>
        </div>
      </div>
    </div>
  </div>
</section> -->


<!-- Footer Section Start -->
<footer>
  <div class="container">
    <div class="footer_container text-center py-5">
      <div class="footerLogo">
        <img src="assets/images/logo_banner.png" alt="">
      </div>

      <div class="footer_menu my-5">
        <!-- <ul class="list-unstyled list-inline ff_smooch m-0">
          <li class="list-inline-item"[routerLink]="['/landingpage']">Home</li>
          <li class="list-inline-item"[routerLink]="['/about-us']">About Us</li>
          <li class="list-inline-item" [routerLink]="['/contact-us']">Contact Us</li>
          <li class="list-inline-item" [routerLink]="['/privacy-policy']">Privacy Policy</li>
          <li class="list-inline-item" [routerLink]="['/terms-condition']">Terms & Conditions</li>
        </ul> -->
      </div>

      <div class="social_icon">
        <!-- <ul class="list-unstyled m-0">
          <li class="list-inline-item twitter_icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
              <g clip-path="url(#clip0_75_50)">
                <path
                  d="M25 4.74844C24.0703 5.15625 23.0797 5.42656 22.0469 5.55781C23.1094 4.92344 23.9203 3.92656 24.3016 2.725C23.3109 3.31562 22.2172 3.73281 21.0516 3.96563C20.1109 2.96406 18.7703 2.34375 17.3078 2.34375C14.4703 2.34375 12.1859 4.64688 12.1859 7.47031C12.1859 7.87656 12.2203 8.26719 12.3047 8.63906C8.04375 8.43125 4.27344 6.38906 1.74062 3.27813C1.29844 4.04531 1.03906 4.92344 1.03906 5.86875C1.03906 7.64375 1.95312 9.21719 3.31562 10.1281C2.49219 10.1125 1.68437 9.87344 1 9.49688C1 9.5125 1 9.53281 1 9.55312C1 12.0437 2.77656 14.1125 5.10625 14.5891C4.68906 14.7031 4.23438 14.7578 3.7625 14.7578C3.43438 14.7578 3.10312 14.7391 2.79219 14.6703C3.45625 16.7 5.34063 18.1922 7.58125 18.2406C5.8375 19.6047 3.62344 20.4266 1.22656 20.4266C0.80625 20.4266 0.403125 20.4078 0 20.3563C2.27031 21.8203 4.96094 22.6562 7.8625 22.6562C17.2937 22.6562 22.45 14.8438 22.45 8.07187C22.45 7.84531 22.4422 7.62656 22.4312 7.40938C23.4484 6.6875 24.3031 5.78594 25 4.74844Z"
                  fill="white" />
              </g>
              <defs>
                <clipPath id="clip0_75_50">
                  <rect width="25" height="25" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </li>
          <li class="list-inline-item instagram_icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
              <path
                d="M17.1875 0H7.8125C3.49844 0 0 3.49844 0 7.8125V17.1875C0 21.5016 3.49844 25 7.8125 25H17.1875C21.5016 25 25 21.5016 25 17.1875V7.8125C25 3.49844 21.5016 0 17.1875 0ZM22.6562 17.1875C22.6562 20.2031 20.2031 22.6562 17.1875 22.6562H7.8125C4.79688 22.6562 2.34375 20.2031 2.34375 17.1875V7.8125C2.34375 4.79688 4.79688 2.34375 7.8125 2.34375H17.1875C20.2031 2.34375 22.6562 4.79688 22.6562 7.8125V17.1875Z"
                fill="white" />
              <path
                d="M12.5 6.25C9.04844 6.25 6.25 9.04844 6.25 12.5C6.25 15.9516 9.04844 18.75 12.5 18.75C15.9516 18.75 18.75 15.9516 18.75 12.5C18.75 9.04844 15.9516 6.25 12.5 6.25ZM12.5 16.4062C10.3469 16.4062 8.59375 14.6531 8.59375 12.5C8.59375 10.3453 10.3469 8.59375 12.5 8.59375C14.6531 8.59375 16.4062 10.3453 16.4062 12.5C16.4062 14.6531 14.6531 16.4062 12.5 16.4062Z"
                fill="white" />
              <path
                d="M19.2188 6.61411C19.6787 6.61411 20.0516 6.24125 20.0516 5.7813C20.0516 5.32135 19.6787 4.94849 19.2188 4.94849C18.7588 4.94849 18.386 5.32135 18.386 5.7813C18.386 6.24125 18.7588 6.61411 19.2188 6.61411Z"
                fill="white" />
            </svg>
          </li>
          <li class="list-inline-item facebook_icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
              <path
                d="M9.61252 14.0629C9.5222 14.0629 7.53532 14.0629 6.63219 14.0629C6.15052 14.0629 6 13.8822 6 13.4307C6 12.2265 6 10.9922 6 9.78807C6 9.3064 6.18063 9.15588 6.63219 9.15588H9.61252C9.61252 9.06556 9.61252 7.31951 9.61252 6.5067C9.61252 5.30253 9.82325 4.15856 10.4253 3.10491C11.0575 2.02116 11.9607 1.29866 13.1046 0.877195C13.8572 0.606257 14.6098 0.48584 15.4226 0.48584H18.3729C18.7943 0.48584 18.975 0.666466 18.975 1.08793V4.51981C18.975 4.94127 18.7943 5.1219 18.3729 5.1219C17.56 5.1219 16.7472 5.1219 15.9344 5.15201C15.1216 5.15201 14.7001 5.54336 14.7001 6.38628C14.67 7.28941 14.7001 8.16243 14.7001 9.09567H18.1922C18.6739 9.09567 18.8545 9.27629 18.8545 9.75796V13.4006C18.8545 13.8822 18.704 14.0328 18.1922 14.0328C17.1085 14.0328 14.7905 14.0328 14.7001 14.0328V23.8468C14.7001 24.3585 14.5496 24.5392 14.0077 24.5392C12.7434 24.5392 11.5091 24.5392 10.2447 24.5392C9.79314 24.5392 9.61252 24.3585 9.61252 23.907C9.61252 20.746 9.61252 14.1532 9.61252 14.0629Z"
                fill="white" />
            </svg>
          </li>
        </ul> -->
      </div>
    </div>
  </div>
  <!-- copyright section start -->
  <div class="copyright_container">
    <hr class="m-0" />
    <div class="container">
      <div class="d-block d-lg-flex justify-content-between align-items-center">

        <p class="m-0 py-2 py-lg-4">Copyright © 2025. All rights reserved by the <a href=""
            class="color_purple text-decoration-none">VHH Presents Chokechamber</a></p>


        <p class="m-0 py-3 py-lg-4">Made With By &#9829; <a href="https://parastechnologies.com/"
            class="color_red text-decoration-none "> Paras Technologies</a></p>
      </div>
    </div>
  </div>
</footer>