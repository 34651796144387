import { Component, OnInit } from '@angular/core';
import { IVideoConfig } from "ngx-video-list-player";

@Component({
  selector: 'app-revenue-history-details',
  templateUrl: './revenue-history-details.component.html',
  styleUrls: ['./revenue-history-details.component.scss']
})
export class RevenueHistoryDetailsComponent implements OnInit {
  config: IVideoConfig = {
    sources: [
    {
      src: 'assets/videos/demovedio.mp4',
    }]          
  };

  constructor() { }

  ngOnInit(): void {
  }

}
