import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/common/api-service/api.service';

@Component({
  selector: 'app-see-all-models',
  templateUrl: './see-all-models.component.html',
  styleUrls: ['./see-all-models.component.scss']
})
export class SeeAllModelsComponent implements OnInit {
  id: any;
  studioDetail: any;
  modelData: any;
  logobaseUrl: any;
  modelUrl: any;

  constructor(private router: Router, public activeroute: ActivatedRoute, private api: ApiService) {
    this.activeroute.params.subscribe((params: any) => {
      this.id = params['id'];
      if (this.id) {
        // this.getDetail(this.id)
        this.modelPageDetail(this.id)
      } else {
        this.router.navigate(['/']);
      }
    })
  }

  ngOnInit(): void {
    this.logobaseUrl = this.api.licenceAndLogoUrl
    this.modelUrl = this.api.modelImageUrl
  }

  modelPageDetail(id: any) {
    let fd = new FormData
    fd.append('pageNo', "0")
    fd.append('id', id)
    this.api.modelsBasedOnStudio(fd).subscribe((res: any) => {
      this.studioDetail = res.data
      this.modelData = res.data.models
    })
  }

  modelDetail(id:any){
    this.router.navigate([`/model-details/${id}`])

  }

}
