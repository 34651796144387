import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/common/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/message/messagehandling.service';
declare const $: any;
@Component({
  selector: 'app-model-details', 
  templateUrl: './model-details.component.html',
  styleUrls: ['./model-details.component.scss']
})
export class ModelDetailsComponent implements OnInit {
id:any
  clips: any;
  modelData: any;
  tags: any;
  modelUrl: any;
  clipUrl: any;
  constructor(private router: Router, public activeroute: ActivatedRoute, private api: ApiService,private message:MessagehandlingService) {
    this.activeroute.params.subscribe((params: any) => {
      this.id = params['id'];
      if (this.id) {
        // this.getDetail(this.id)
        this.modelDetail(this.id)
      } else {
        this.router.navigate(['/']);
      }
    })
  }

  ngOnInit(): void {
    this.modelUrl=this.api.modelImageUrl
    this.clipUrl = this.api.clipsImageUrl


  }
  
 
  fav(id: any, isFav: any) {
    
    let fd = new FormData
   
    fd.append('CID', id)
    if (isFav==1) {
      fd.append('status', "0")
      $(".likeContainer").toggleClass("heart_filled");
    }
    else {
      fd.append('status', "1")

      $(".likeContainer").removeClass("heart_filled");

    }
    this.api.addFavourite(fd).subscribe((res: any) => {
      this.message.sucessMessage(res.message, 1000)
      this.modelDetail(this.id)

    })
    // $(".likeContainer").toggleClass("heart_filled");
  }
  

  modelDetail(id:any){
    this.api.modelDetailUser(id).subscribe((res:any)=>{
      if(res){
      this.modelData=res?.data
      this.tags=res.data?.tags
      this.clips=res.data?.clips
      
      
      setTimeout(() => {
        $('.commonVideo_slider').not('.slick-initialized').slick({
          slidesToShow: 3,
          slidesToScroll: 1,
          autoplay: false,
          autoplaySpeed: 3000,
          dots: true,
          infinite: false,
          // centerMode: true,
          // variableWidth: true,
          prevArrow: $('.prev'),
          nextArrow: $('.next'),
          responsive: [
            {
              breakpoint: 1400,
              settings: {
                slidesToShow: 3,
    
              }
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 2,
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        });
    }, 10);
      }

    })

  }

  studioDetail(id:any){
    this.router.navigate([`/studio-details/${id}`])

  }
}
