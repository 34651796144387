import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/common/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/message/messagehandling.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  notificationData: any;
  baseUrl=this.api.notificationUrl

  constructor(private api: ApiService, private message: MessagehandlingService) { }

  ngOnInit(): void {
   
    this.notification()
  }

  notification() {
    let fd = new FormData
    fd.append('pageNo', '0')
    this.api.notificationList(fd).subscribe((res: any) => {
      this.notificationData=res.data
      this.message.sucessMessage(res.message, 2000)

    })
  }

  notificationsDelete(){
    this.api.notificationsDelete().subscribe((res:any)=>{
      this.message.sucessMessage(res.message,1000)
      this.notification()
    })
  }

}
