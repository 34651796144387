import { Dialog } from '@angular/cdk/dialog';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/common/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/message/messagehandling.service';
import { ArtistDetailsComponent } from '../../member/components/modal/artist-details/artist-details.component';
import { LandingPageVideoComponent } from '../../member/components/modal/landing-page-video/landing-page-video.component';
declare const $: any;
@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
  memberForm: FormGroup
  theDate:any
  // acceptCheckbox: boolean = false;
  constructor(private dialog: Dialog, private formBuilder: FormBuilder, private router: Router, private api: ApiService, private message: MessagehandlingService) {

    this.memberForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, this.emailValidator.bind(this)]],
      password: ['', [Validators.required]],
      dob:['',Validators.required],
      confirmPassword: [''],
      acceptCheckbox: ['', [Validators.required]]
    }, { validator: this.checkPasswords })

  }

  ngOnInit(): void {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 1000);
    $('.models_slider').not('.slick-initialized').slick({
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: false,
      autoplaySpeed: 3000,
      dots: true,
      prevArrow: $('.prevText'),
      nextArrow: $('.nextText'),
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,

          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });

    // Example videos js
    $('.exampleVideo').not('.slick-initialized').slick({
      slidesToShow: 3,
      slidesToScroll: 3,
      autoplay: false,
      autoplaySpeed: 3000,
      dots: true,
      prevArrow: $('.lefArrow'),
      nextArrow: $('.rightArrow'),
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,

          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]
    });

    // models slider js
    $('.slider_cont').not('.slick-initialized').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      dots: true,
      arrows: false
    });
  }


  emailValidator(control: FormControl) {
    if (!(control.value.toLowerCase().match('^[a-z0-9]+(\.[_a-z0-9]+)+(\.[a-z0-9-]+)+([.{1}])(\.[a-z]{1,15})$'))) {
      return { invalidEmail: true };
    }
    return
  }


  checkEvent(event: any) {
    if (event.target.value == '' && event.which == 32) {
      event.preventDefault();
    }
  }


  checkPasswords(control: FormControl) {
    const pass = control.get('password')?.value
    const confirmPass = control.get('confirmPassword')?.value
    if (pass != confirmPass) {
      control.get('confirmPassword')?.setErrors({ ConfirmPassword: true });
    } else {
      return null;
    }
    return
  }



  becomeMember() {
    if (!this.memberForm.value.acceptCheckbox)
      return this.message.errorMessage('Please agree with terms and conditions', 2000)      
    var dob = new Date(this.theDate);
    var month_diff = Date.now() - dob.getTime();
    var age_dt = new Date(month_diff);
    var year = age_dt.getUTCFullYear();
    var age = Math.abs(year - 1970);
    if (age >= 18) {
      let formData = new FormData
      formData.append('name', this.memberForm.value.name)
      formData.append('email', this.memberForm.value.email)
      formData.append('password', this.memberForm.value.password)
      formData.append('dob', this.theDate)
      formData.append('deviceType', 'web')
      this.api.registerUser(formData).subscribe((res: any) => {
        this.message.sucessMessage(res.message, 2000)
        localStorage.setItem('studioToken', res.data.token)
        sessionStorage.setItem('membername', res.data.name)
        this.router.navigate(['/verify-email'])
      })
    } else {
      this.message.errorMessage(`You are atleast 18 years old.`, 2000)
    }



  }

  termsConditions() {
    this.router.navigate(['/terms-condition'])

  }


  aboutVideo(): void {
    this.dialog.open(LandingPageVideoComponent, {
      panelClass: ['common_model', 'dialog_lg', 'aboutVideo'],
      disableClose: true,
      maxHeight: '90vh',
    });
  }

  artist_detail(): void {
    this.dialog.open(ArtistDetailsComponent, {
      panelClass: ['common_model', 'dialog_lg', 'artistModal'],
      disableClose: true,
      maxHeight: '60vh',
      height: '100%',
    });
  }
}
