import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { base64ToFile } from 'ngx-image-cropper';

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss']
})
export class ImageCropperComponent implements OnInit {
  imageChangedEvent: any;
  croppedImage: any;
  blobfile: Blob | null = null;
  type: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { imageUrl: any, type: any },
    private dialogRef: MatDialogRef<ImageCropperComponent>
  ) { }

  ngOnInit(): void {
    this.imageChangedEvent = this.data.imageUrl;
    this.type = this.data.type;
  }

  async imageCropped(event: ImageCroppedEvent) {
    if (event.base64) {
      this.croppedImage = event.base64;
      const base64Response = await fetch(this.croppedImage);
      this.blobfile = await base64Response.blob();
    } else {
      this.blobfile = null;
    }
  }


  
  cropImage() {
    if (this.blobfile) {
      let croppedData = {
        data: this.croppedImage,
        blob: this.blobfile
      };
      this.dialogRef.close(croppedData);
    } else {
      console.error('No cropped image data to return');
      this.dialogRef.close();
    }
  }

  closeModal() {
    this.dialogRef.close();
  }
}
