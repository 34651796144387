<app-header></app-header>

<section class="clip-details mb-4">
    <div class="container">
        <button class="backbtn" [routerLink]="['/studio-clips']"><img
                src="assets/images/studiomodule/backbtn.svg"></button>

        <div class="row">
            <div class="col-md-7">
                <video  class="img-fluid" [src]="videoData"  height="200" controls controlsList="nodownload"></video>

                <!-- <ngx-video-list-player [config]="config"></ngx-video-list-player> -->
            </div>
            <div class="col-md-5">
                <h4 class="fw-medium text-white mb-4 mt-3 pt-1">{{clipdata.title}}</h4>
                <h5 *ngIf="clipdata.name!=''&& clipdata.name!=undefined" class="text-white fs18 fw-medium d-flex align-items-center mb-3 pb-1">Category:<span
                        class="color_pink fw-normal ps-3 fs20">{{clipdata.name}}</span></h5>
                <h6 *ngIf="tags.length!=0" class="sa18 text-white d-flex align-items-center flex-wrap mb-4"><span class="me-3" >Tags:</span>
                    <span class="tag fs14 me-2" *ngFor="let tag of tags">{{tag.name}}</span>
                </h6>

                <h5 class="fs18 fw-medium mb-0 d-flex align-items-center">Price: <span
                        class="fs20 fw-normal ps-3">${{clipdata.price}}</span></h5>
                <hr>
                <!-- <div class="d-flex mt-4">  <div> <h5 class="fs22 fw-medium mb-0 d-flex align-items-center">  William Blake</h5>
                    <p class="mt-2 emailtext fw-300 fs16">williamblake2@gmail.com</p>   </div>   <div class="ms-auto"> <p>  May 20, 2022 | 10:00 AM</p></div>  </div> -->

                <div class="d-flex mt-4 align-items-center">
                    <div>
                        <div class="user_img">
                            <img class="w-100 h-100" [src]="modelImage" alt>
                        </div>
                    </div>
                    <div class="ms-3 pt-3">
                        <h5 class="fs22 fw-medium mb-0 d-flex align-items-center"> {{clipdata.studioName}}</h5>
                        <p class="mt-2 emailtext fw-300 fs16">{{clipdata.email}}</p>
                    </div>
                </div>


            </div>
        </div>
        <h6 class="fs16 fw-normal mt-3 pt-1 pb-4 mb-1 d-flex align-items-center flex-wrap color_white_half">
           <span class="px-3">{{clipdata.created_at | date}}</span>
           <!-- <span class="px-3">{{clipdata.created_at | date}}  {{clipdata?.duration}}</span></h6> -->
        </h6>
        <h4 class="fw-medium mb-2"> Video Description</h4>
        <p class="description fw-light pt-1 mb-4">{{clipdata.description}}</p>
    </div>
</section>

<app-studio-footer> </app-studio-footer>