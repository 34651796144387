<div mat-dialog-content class="p-4">
    <div class="content_sec">
        <p class="mb-3 fw-light">This website contains scenes of simulated violence, nudity, and adult situations that may offend some viewers. The content of this website is inappropriate for viewing by anyone under the age of 18. Viewer discretion is required</p>
        <p class="mb-3 fw-light">By clicking the "I Agree" link provided below, the individual reading these terms certifies and agrees to the following:</p>

        <p class="mb-0 fw-light"><span class="pe-1">1</span>  I do not find images of nude adults, adults engaging in violent behavior, (however simulated) or sexual material to be offensive.</p>
        <p class="mb-0 fw-light"><span class="pe-1">2</span>  I am at least 18 years of age or older</p>
        <p class="mb-0 fw-light"><span class="pe-1">3</span>  I understand the standards and laws in my community, and have determined that viewing sexually oriented images does not violate any such standard or law.</p>
        <p class="mb-0 fw-light"><span class="pe-1">4</span>  I understand that I am solely responsible for my decision to enter this site and view such images.</p>
        <p class="mb-0 fw-light"><span class="pe-1">5</span>  By entering the site, I am releasing and discharging the owners, providers, creators and host for this site from any and all legal actions that might arise.</p>
        <p class="mb-0 fw-light"><span class="pe-1">6</span>  You must agree fully with our Terms and Conditions set in out PRIVACY POLICY</p>
    </div>
</div>
<div mat-dialog-actions class="justify-content-center btns-sec flex-wrap mt-4 mt-lg-0 gap-4">
    <button class="btn purple_btn btn70 fw-bold" mat-dialog-close [routerLink]="['/landingpage']">I agree and wish to continue</button>
    <button class="btn purple_btn exitbtn btn70 fw-bold" mat-dialog-close>I do not agree and wish to EXIT</button>
</div>