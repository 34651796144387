import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/common/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/message/messagehandling.service';
declare const $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  latestReleases: any
  categories: any;
  models: any;
  studios: any;
  mostViewed: any;
  catUrl: any
  modelUrl: any
  studioUrl: any
  clipUrl: any;
  activeImage: any;
  search: string = ''
  red: boolean = false;
  isHovered: boolean = false;
 

  constructor(private api: ApiService, private router: Router, private message: MessagehandlingService, private route: ActivatedRoute) {
    const key = this.route.snapshot.queryParamMap.get('customKey');
    if (key) {
      localStorage.setItem('studioToken', key)
      console.log('Key:', key);

    }
   
  }

  ngOnInit(): void {
    this.homepageData()
    this.catUrl = this.api.userCatFilePath
    this.modelUrl = this.api.modelImageUrl
    this.studioUrl = this.api.licenceAndLogoUrl
    this.clipUrl = this.api.clipsImageUrl
    // models slider js

    // $(document).ready(function() {
    //   $(".nav-link").click(function () {
    //       $("this").toggleClass("active");        
    //   });
    //   });
    const links = document.querySelectorAll('.nav-link');

    if (links.length) {
      links.forEach((link) => {
        link.addEventListener('click', (e) => {
          links.forEach((link) => {
            link.classList.remove('active');
          });
          e.preventDefault();
          link.classList.add('active');
        });
      });
    }

  }


  homepageData() {
    let fd = new FormData
    fd.append('type', '0')
    fd.append('pageNo', '0')
    this.api.home(fd).subscribe((res: any) => {
      if (res) {

        this.latestReleases = res.data
        // this.categories = res.categories
        // this.models = res.models
        this.studios = res.studios
        // this.mostViewed = res.mostViewed

        // $('.moreview_video_slider').slick({
        //   slidesToShow: 4,
        //   slidesToScroll: 1,
        //   autoplay: false,
        //   autoplaySpeed: 3000,
        //   dots: true,
        //   // centerMode: true,
        //   // variableWidth: true,
        //   prevArrow: $('.leftArrowMore'),
        //   nextArrow: $('.rightArrowMore'),
        //   responsive: [
        //     {
        //       breakpoint: 1400,
        //       settings: {
        //         slidesToShow: 3,

        //       }
        //     },
        //     {
        //       breakpoint: 992,
        //       settings: {
        //         slidesToShow: 2,
        //       }
        //     },
        //     {
        //       breakpoint: 600,
        //       settings: {
        //         slidesToShow: 1,
        //       }
        //     },
        //     {
        //       breakpoint: 480,
        //       settings: {
        //         slidesToShow: 1,
        //         slidesToScroll: 1
        //       }
        //     }
        //   ]
        // });

        // $('.latest_video_slider').slick({
        //   slidesToShow: 4,
        //   slidesToScroll: 1,
        //   autoplay: false,
        //   autoplaySpeed: 3000,
        //   dots: true,
        //   // centerMode: true,
        //   // variableWidth: true,
        //   prevArrow: $('.leftArrowLatest'),
        //   nextArrow: $('.rightArrowLatest'),
        //   responsive: [
        //     {
        //       breakpoint: 1400,
        //       settings: {
        //         slidesToShow: 3,

        //       }
        //     },
        //     {
        //       breakpoint: 992,
        //       settings: {
        //         slidesToShow: 2,
        //       }
        //     },
        //     {
        //       breakpoint: 600,
        //       settings: {
        //         slidesToShow: 1,
        //       }
        //     },
        //     {
        //       breakpoint: 480,
        //       settings: {
        //         slidesToShow: 1,
        //         slidesToScroll: 1
        //       }
        //     }
        //   ]
        // });

        // $('.topCategory_slider').slick({
        //   slidesToShow: 4,
        //   slidesToScroll: 1,
        //   autoplay: false,
        //   autoplaySpeed: 3000,
        //   dots: false,
        //   // centerMode: true,
        //   // variableWidth: true,
        //   prevArrow: $('.cateLeftArrow'),
        //   nextArrow: $('.caterightArrow'),
        //   responsive: [
        //     {
        //       breakpoint: 1400,
        //       settings: {
        //         slidesToShow: 3,

        //       }
        //     },
        //     {
        //       breakpoint: 992,
        //       settings: {
        //         slidesToShow: 2,
        //       }
        //     },
        //     {
        //       breakpoint: 600,
        //       settings: {
        //         slidesToShow: 1,
        //       }
        //     },
        //     {
        //       breakpoint: 480,
        //       settings: {
        //         slidesToShow: 1,
        //         slidesToScroll: 1
        //       }
        //     }
        //   ]
        // });

        // $('.models_slider').not('.slick-initialized').slick({
        //   slidesToShow: 4,
        //   slidesToScroll: 1,
        //   autoplay: false,
        //   autoplaySpeed: 3000,
        //   dots: false,
        //   // centerMode: true,
        //   // variableWidth: true,
        //   prevArrow: $('.Modelprev'),
        //   nextArrow: $('.Modelnext'),
        //   responsive: [
        //     {
        //       breakpoint: 1400,
        //       settings: {
        //         slidesToShow: 3,

        //       }
        //     },
        //     {
        //       breakpoint: 992,
        //       settings: {
        //         slidesToShow: 2,
        //       }
        //     },
        //     {
        //       breakpoint: 600,
        //       settings: {
        //         slidesToShow: 1,
        //       }
        //     },
        //     {
        //       breakpoint: 480,
        //       settings: {
        //         slidesToShow: 1,
        //         slidesToScroll: 1
        //       }
        //     }
        //   ]
        // });
        setTimeout(() => {
          $('.studio_slider').not('.slick-initialized').slick({
            slidesToShow: 4,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 3000,
            dots: false,
            nav: true,
            infinite: false,
            // centerMode: true,
            // variableWidth: true,
            prevArrow: $('.prev'),
            nextArrow: $('.next'),
            responsive: [
              {
                breakpoint: 992,
                settings: {
                  slidesToShow: 2,
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 1,
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ]
          });
        }, 10);


      }
    })

  }

  filter(num: any) {
    switch (num) {
      case num = '0':
        let fd = new FormData
        fd.append('type', '0')
        fd.append('pageNo', '0')
        this.api.home(fd).subscribe((res: any) => {
          if (res) {
            this.latestReleases = res.data
          }
        })
        break;
      case num = '1':
        let fd1 = new FormData
        fd1.append('type', '1')
        fd1.append('pageNo', '0')
        this.api.home(fd1).subscribe((res: any) => {
          if (res) {
            this.latestReleases = res.data
          }
        })
        break;
      case num = '2':
        let fd2 = new FormData
        fd2.append('type', '2')
        fd2.append('pageNo', '0')
        this.api.home(fd2).subscribe((res: any) => {
          if (res) {
            this.latestReleases = res.data
          }
        })
        break;
        // case num = '3':
        //   this.Price()
        // let fd3 = new FormData
        // fd3.append('type', '3')
        // fd3.append('price','')
        // fd3.append('pageNo', '0')
        // this.api.home(fd3).subscribe((res: any) => {
        //   if (res) {
        //     this.latestReleases = res.data
        //   }
        // })
        break;
      case num = '4':
        let fd4 = new FormData
        fd4.append('type', '4')
        fd4.append('pageNo', '0')
        this.api.home(fd4).subscribe((res: any) => {
          if (res) {
            this.latestReleases = res.data
          }
        })
        break;
      default:
        break;
    }
  }


  Price(num: any) {
    if (num == '0') {
      let fd4 = new FormData
      fd4.append('type', '3')
      fd4.append('pageNo', '0')
      fd4.append('price', '0')
      this.api.home(fd4).subscribe((res: any) => {
        if (res) {
          this.latestReleases = res.data
        }
      })
    }
    else {
      let fd4 = new FormData
      fd4.append('type', '3')
      fd4.append('pageNo', '0')
      fd4.append('price', '1')
      this.api.home(fd4).subscribe((res: any) => {
        if (res) {
          this.latestReleases = res.data
        }
      })
    }

  }


  play() {
    setTimeout(() => {
      this.play()

    }, 1000);
  }

  pause() {
    setTimeout(() => {
      this.pause()

    }, 1000);
  }


  searchData(text: any) {
    let fd = new FormData
    if (text != '') {
      fd.append('search', text)
      // fd.append('pageNo', '0')
      this.api.home(fd).subscribe((res: any) => {
        if (res) {
          this.latestReleases = res.data
        }
      })
    }
    else {
      fd.append('type', '0')
      fd.append('pageNo', '0')
      this.api.home(fd).subscribe((res: any) => {
        if (res) {
          this.latestReleases = res.data
        }
      })
    }
  }

  fav(id: any, isFav: any) {
    let fd = new FormData
    fd.append('CID', id)
    if (isFav == 1) {
      fd.append('status', "0")
      //  $(".heart").toggleClass("heart_filled");
      this.red = this.red
    }
    else {
      fd.append('status', "1")
      this.red = !this.red
      // $(".heart").removeClass("heart_filled");

    }
    this.api.addFavourite(fd).subscribe((res: any) => {
      this.message.sucessMessage(res.message, 1000)
      // this.homepageData()
      let fd = new FormData
      fd.append('type', '0')
      fd.append('pageNo', '0')
      this.api.home(fd).subscribe((res: any) => {
        setTimeout(() => {
          if (res) {

            this.latestReleases = res.data
            // this.categories = res.categories
            // this.models = res.models
            this.studios = res.studios
          }
          //  alert(1)
        }, 100);
        setTimeout(() => {
          // alert(2)
          $('.studio_slider').not('.slick-initialized').slick({
            slidesToShow: 4,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 3000,
            dots: false,
            nav: true,
            infinite: false,
            // centerMode: true,
            // variableWidth: true,
            prevArrow: $('.prev'),
            nextArrow: $('.next'),
            responsive: [
              {
                breakpoint: 992,
                settings: {
                  slidesToShow: 2,
                }
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 1,
                }
              },
              {
                breakpoint: 480,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              }
            ]
          });
        }, 2000);

      })
    })
    // $(".likeContainer").toggleClass("heart_filled");
  }

  toggleClass() {
    this.red = !this.red
  }

  isActive(item: any) {
    return this.activeImage === item;
  }


  videoDetail(id: any) {
    // [routerLink]="['/video-detail']"
    this.isHovered = false;
    this.router.navigate([`/video-detail/${id}`])

  }

  categoryDetail(id: any) {
    this.router.navigate([`/category-list/${id}`])
  }

  modelDetail(id: any) {
    // [routerLink]="['/video-detail']"
    this.router.navigate([`/model-details/${id}`])
  }


 
  // playVideo(clip: any): void {
  //   clip.isHovered = true;
  //   setTimeout(() => {
  //     const videoElement = document.querySelector(`video[src="${this.clipUrl + clip.trailer}"]`) as HTMLVideoElement;
  //     if (videoElement) {
  //       videoElement.play();
  //     }
  //   }, 0);
  // }

  playVideo(clip: any): void {
    clip.isHovered = true;
  }

  pauseVideo(clip: any, video: HTMLVideoElement): void {
    clip.isHovered = false;
    video.pause();
  }


}

