<app-header></app-header>

<section class="breadcrumb-banner">
    <div class="container">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item ps-0 active" [routerLink]="['/home']">Home</li>
              <!-- <li class="breadcrumb-item active" [routerLink]="['/cart']">Cart</li> -->
              <li class="breadcrumb-item">Notification</li>
            </ol>
        </nav>
    </div>
</section>

<section class="notification-sec sec_padding_bottom">
    <div class="container">
        <div class="main-box mt-4">
            <div class="row">
                <div class="col-md-12">
                    <div class="d-flex justify-content-between noti_header">
                        <h4>Notification</h4>
                        <a (click)="notificationsDelete()">Clear all</a>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12" *ngFor="let item of notificationData">
                    <div class="d-flex align-items-center noti">
                        <div class="payment_notification_img thumbnail">
                            <img [src]="baseUrl+ item.image" alt="">
                        </div>
                        <div class="notification_cnt">
                            <p class="mb-1">{{item.title}}</p>
                            <p class="mb-1">{{item.message}}</p>
                            <p class="date_and_time mb-0">{{item.createdDate | date}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer></app-footer>