import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ApiService } from 'src/app/common/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/message/messagehandling.service';

@Component({
  selector: 'app-cardforsubscription',
  templateUrl: './cardforsubscription.component.html',
  styleUrls: ['./cardforsubscription.component.scss']
})
export class CardforsubscriptionComponent implements OnInit {
  addCardForm!: FormGroup
  time = new Date()
  selectedMonthAndYear: any = ''
  formattedMonthAndYear: any = ''
  constructor(private fb: FormBuilder, private api: ApiService, private message: MessagehandlingService, private router: Router, private datePipe: DatePipe) {
    this.addCardForm = this.fb.group({
      cardNumber: '',
      expDate: '',
      cardHolderName: '',
      cvv: '',
      address:'',
      city:'',
      state:'',
      country:'',
      zipcode:''
    })
    // this.formattedMonthAndYear = this.datePipe.transform(new Date(), 'MM/yy');
  }

  ngOnInit(): void {
  }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }


  onMonthYearSelected() {
    this.formattedMonthAndYear = this.datePipe.transform(this.selectedMonthAndYear, 'MM/yy');

  }

  checkEvent(event: any) {
    if (event.target.value == '' && event.which == 32) {
      event.preventDefault();
    }
  }

  saveDetails() {
    if (this.addCardForm.value.cardNumber == '' || this.addCardForm.value.expDate == ''
      || this.addCardForm.value.cardHolderName == '' || this.addCardForm.value.cvv == ''
      || this.addCardForm.value.address=='' || this.addCardForm.value.city=='' || this.addCardForm.value.state=='' || this.addCardForm.value.zipcode==''|| this.addCardForm.value.country=='') {
      this.message.errorMessage('please fill all details', 2000)
    }
    else {  
      let fd = new FormData
      fd.append('cardNumber', this.addCardForm.value.cardNumber)
      fd.append('expDate', this.addCardForm.value.expDate)
      fd.append('cardHolderName', this.addCardForm.value.cardHolderName)
      fd.append('cvv', this.addCardForm.value.cvv)
      fd.append('address', this.addCardForm.value.address)
      fd.append('city', this.addCardForm.value.city)
      fd.append('state', this.addCardForm.value.state)
      fd.append('zipcode', this.addCardForm.value.zipcode)
      fd.append('country', this.addCardForm.value.country)
      fd.append('startDate', moment(this.time).format('YYYY-MM-DD'))
      this.api.addSubscription(fd).subscribe((res: any) => {
        this.message.sucessMessage(res.message, 2000)
        if (res.status == 200) {
          sessionStorage.setItem('isSubscription', res.isSubscription)
          this.router.navigate(['/home'])
        }
      })
    }
  }

}

