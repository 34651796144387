import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/common/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/message/messagehandling.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm!: FormGroup
  constructor(private formBuilder: FormBuilder, private api: ApiService, private message: MessagehandlingService, private route: Router) {

    this.changePasswordForm = this.formBuilder.group({
      oldpassword: ['', [Validators.required]],
      password: ['', [Validators.required]],
      confirmPassword: [''],

    }, { validator: this.checkPasswords })

  }


  ngOnInit(): void {
  }

  checkEvent(event: any) {
    if (event.target.value == '' && event.which == 32) {
      event.preventDefault();
    }
  }

  checkPasswords(control: FormControl) {
    const pass = control.get('password')?.value
    const confirmPass = control.get('confirmPassword')?.value
    if (pass != confirmPass) {
      control.get('confirmPassword')?.setErrors({ ConfirmPassword: true });
    } else {
      return null;
    }
    return
  }
  savePassword() {
    let fd = new FormData
    fd.append('oldPassword', this.changePasswordForm.value.oldpassword)
    fd.append('password', this.changePasswordForm.value.password)
    this.api.changePassword(fd).subscribe((res: any) => {
      if (res.status == 200) {
        this.message.sucessMessage(res.message, 2000)
        this.route.navigate(['/my-profile'])

      }
      else {
        this.message.errorMessage(res.message, 2000)

      }

    })
  }

}
