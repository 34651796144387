import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/common/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/message/messagehandling.service';
declare const $: any;


@Component({
  selector: 'app-see-all-videos',
  templateUrl: './see-all-videos.component.html',
  styleUrls: ['./see-all-videos.component.scss']
})
export class SeeAllVideosComponent implements OnInit {
  id: any;
  studioDetail: any;
  ClipData: any;
  logobaseUrl: any;
  clipUrl: any;

  constructor(private router: Router, public activeroute: ActivatedRoute, private api: ApiService,private message :MessagehandlingService) {
    this.activeroute.params.subscribe((params: any) => {
      this.id = params['id'];
      if (this.id) {
        // this.getDetail(this.id)
        this.clipPageDetail(this.id)
      } else {
        this.router.navigate(['/']);
      }
    })
  }

  ngOnInit(): void {
    this.logobaseUrl = this.api.licenceAndLogoUrl
    // this.modelUrl = this.api.modelImageUrl
    this.clipUrl = this.api.clipsImageUrl
  }


  clipPageDetail(id: any) {
    let fd = new FormData
    fd.append('pageNo', "0")
    fd.append('id', id)
    this.api.clipsBasedOnStudio(fd).subscribe((res: any) => {
      this.studioDetail = res.data
      this.ClipData = res.data.clips
    })
  }

  videoDetail(id: any) {
    this.router.navigate([`/video-detail/${id}`])


  }
  fav(id: any, isFav: any) {
    
    let fd = new FormData
   
    fd.append('CID', id)
    if (isFav==1) {
      fd.append('status', "0")
      $(".likeContainer").toggleClass("heart_filled");
    }
    else {
      fd.append('status', "1")

      $(".likeContainer").removeClass("heart_filled");

    }
    this.api.addFavourite(fd).subscribe((res: any) => {
      this.message.sucessMessage(res.message, 1000)
      this.clipPageDetail(this.id)


    })
    // $(".likeContainer").toggleClass("heart_filled");
  }
  
}
