<app-header></app-header>

<section class="breadcrumb-banner">
    <div class="container">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item ps-0 active" [routerLink]="['/home']">Home</li>
                <li class="breadcrumb-item active" [routerLink]="['/my-profile']">My Profile</li>
                <li class="breadcrumb-item">Add Tokens</li>
            </ol>
        </nav>
    </div>
</section>
<p *ngIf="this.amount==''"> Please enter amount</p>
<section class="add-token-sec sec_padding_bottom">
    <div class="container">
        <div class="main-box mt-4 p-5">
            <div class="row">
                <div class="col-md-6">
                    <div class="img-wrap d-flex align-items-center justify-content-center">
                        <img src="assets/images/add-token-img.svg" class="img-fluid">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-sec">
                        <form [formGroup]="tokenForm">
                            <div class="mb-4 pb-1 position-relative token_info">
                                <label
                                    class="form-label text-uppercase fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Enter
                                    Token Amount</label>
                                <input type="text" class="form-control fs18 cmn-input input_60 ff_Roboto"
                                    (keypress)="numberOnly($event)" placeholder="ex.50" (keydown)="checkEvent($event)"
                                    [(ngModel)]="this.amount" [ngModelOptions]="{standalone: true}">


                                <img src="assets/images/coin.svg" class="position-absolute">

                                <p class="mt-2 color_grey"> Total payable amount: <span class="fw-bold fs18 text-white">
                                        ${{this.amount==''?'0':this.amount}} </span> </p>

                            </div>

                            <div class="card_lists mt-4">

                                <h4 class="ff_kalam fw-regular "> Credit/Debit Card</h4>

                                <div class="debit-card d-flex align-items-center justify-content-between mt-2"
                                    *ngFor="let card of cardDetail">
                                    <div class="left-sec ">

                                        <mat-radio-button value="1" color="primary" (change)="selectedCard(card)"
                                            >
                                            <div
                                                class="card-type d-flex align-items-center justify-content-center p-1 me-3">
                                                <span> <img class="img-fluid"
                                                        src="assets/images/edit-visa-img.svg"></span>

                                                <p class="mb-0 lh1 h5 fw-normal ps-1">**** **** ****
                                                    {{card?.cardNumber.slice(12, 16)}}</p>

                                            </div>

                                        </mat-radio-button>

                                    </div>
                                    <button class="btn delete-btn m-0 p-0 shadow-none" data-bs-toggle="modal"
                                        data-bs-target="#deleteCard"><img src="assets/images/delete-card-img.svg"
                                            (click)="cardDelete(card.id)"></button>
                                </div>

                            </div>

                            <button [routerLink]="['/add-card']"
                                class="btn grey_btn rounded-0 w-100 btn-lg shadow-none d-flex align-items-center justify-content-center fw-bold">
                                <svg class="me-3" width="22" height="22" viewBox="0 0 22 22" fill="none">
                                    <g clip-path="url(#clip0_719_1699)">
                                        <path
                                            d="M11.9377 11.8772L11.9377 20.5398C11.9214 20.7508 11.826 20.9478 11.6707 21.0916C11.5154 21.2354 11.3116 21.3152 11.0999 21.3152C10.8883 21.3152 10.6845 21.2354 10.5292 21.0916C10.3739 20.9478 10.2785 20.7508 10.2622 20.5398L10.2622 11.8772L1.59364 11.8831C1.38265 11.8668 1.18558 11.7714 1.04182 11.6161C0.898068 11.4608 0.81821 11.257 0.81821 11.0454C0.81821 10.8337 0.898067 10.6299 1.04182 10.4746C1.18558 10.3193 1.38265 10.2239 1.59364 10.2076L10.2622 10.2017L10.2563 1.53313C10.2473 1.41767 10.2624 1.30161 10.3004 1.19224C10.3385 1.08286 10.3987 0.982533 10.4774 0.897547C10.556 0.812562 10.6514 0.744754 10.7575 0.698381C10.8636 0.652007 10.9782 0.62807 11.094 0.62807C11.2098 0.62807 11.3244 0.652008 11.4305 0.698382C11.5366 0.744755 11.632 0.812563 11.7106 0.897548C11.7893 0.982533 11.8495 1.08286 11.8876 1.19224C11.9256 1.30161 11.9407 1.41767 11.9317 1.53313L11.9377 10.2017H20.6003C20.8113 10.218 21.0084 10.3133 21.1521 10.4687C21.2959 10.624 21.3757 10.8278 21.3757 11.0394C21.3757 11.2511 21.2959 11.4549 21.1521 11.6102C21.0084 11.7655 20.8113 11.8608 20.6003 11.8772L11.9377 11.8772Z"
                                            fill="white" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_719_1699">
                                            <rect width="15.5565" height="15.5565" fill="white"
                                                transform="matrix(0.707107 0.707107 0.707107 -0.707107 0 11)" />
                                        </clipPath>
                                    </defs>
                                </svg>
                                Add New Cards
                            </button>

                        </form>


                        <button class="btn w-100 purple_btn btn70 fw-bold mt-4" data-bs-toggle="modal"
                            (click)="cardDetail?.length==0? message.errorMessage('Please add card',1000):null"
                            [attr.data-bs-target]="cardDetail?.length!=0?'#card_cvv':null">Pay
                            Now</button>



                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-footer></app-footer>
<!-- Button trigger modal -->


<!-- Modal -->
<div class="modal fade custom_model" id="deleteCard" tabindex="-1" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"></button>
            <div class="modal-body text-center pt-5">
                <img src="assets/images/studiomodule/delete_clip.svg" alt="" class="img-fluid mb-3">
                <h3>Are you sure!</h3>
                <p class="fw-light">You want to delete this card</p>
            </div>
            <div class="modal-footer gap-3">
                <button type="button" class="btn dark_btn btn60" data-bs-dismiss="modal">Cancel</button>
                <button type="button" class="btn red_btn shadow-none btn60" data-bs-dismiss="modal"
                    (click)="deleteCard()">Delete</button>
            </div>
        </div>
    </div>
</div>


<div class="modal fade custom_model" id="card_cvv" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <button type="button" class="btn-close shadow-none" data-bs-dismiss="modal" aria-label="Close"></button>
            <div class="modal-body text-center pt-2">

                <h3>Card CVV</h3>
                <p class="fw-light">Please enter your card CVV</p>
            </div>

            <div class="modal-footer flex-column gap-3">


                <ng-container [formGroup]="cvvForm">

                    <input type="text" class="form-control fs18 cmn-input input_60 ff_Roboto" formControlName="name"
                        placeholder="Enter CVV" formControlName="cvv"></ng-container>


                <button type="button" class="btn red_btn shadow-none btn60 w-100" data-bs-dismiss="modal"
                    (click)="payNow(this.amount)">Pay
                    Now</button>


            </div>
        </div>
    </div>
</div>