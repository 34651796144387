import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/common/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/message/messagehandling.service';
import { ArtistDetailsComponent } from '../modal/artist-details/artist-details.component';
import { DomSanitizer } from '@angular/platform-browser';
declare const $: any;

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
  aboutData: any
  modelData: any;
  imageUrl: any
  constructor(private api: ApiService, public dialog: MatDialog, private message: MessagehandlingService, private router: Router, private sanitized: DomSanitizer) { }

  ngOnInit(): void {
    this.about()
    // this.getModels()
    // this.imageUrl=this.api.modelImageUrl

    // models slider js

  }

  artist_detail(data: any): void {
    this.dialog.open(ArtistDetailsComponent, {
      panelClass: ['common_model', 'dialog_lg', 'artistModal'],
      disableClose: true,
      maxHeight: '80vh',
      height: '100%',
      data: { block: data }
    });
  }


  about() {
    let pageType = 'about'
    this.api.page(pageType).subscribe((res: any) => {
      this.aboutData = res.data
     let a= this.transform(res.data.content)     
    })
  }


  transform(value: any) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }

  getModels() {
    let fd = new FormData
    fd.append('page', '0')
    this.api.models(fd).subscribe((res: any) => {
      this.modelData = res.data
      setTimeout(() => {
        $('.models_slider1').slick({
          slidesToShow: 4,
          slidesToScroll: 1,
          autoplay: false,
          autoplaySpeed: 3000,
          dots: false,
          prevArrow: $('.prevText'),
          nextArrow: $('.nextText'),
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 3,

              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
        });
      }, 100);
    })
  }

}
