<app-header></app-header>

<!-- New Banner Start Here -->
<!-- <section class="bannerArea">
  <div class="container-fluid p-0">
    <div class="banner">
      <img src="assets/images/header_banner.jpg" alt="" class="img-fluid">
    </div>
  </div>
</section> -->
<!-- Banner section Start -->
<!-- <section id="banner" class="bannerLanding"
  style="background: url(assets/images/header_banner_inner.png) no-repeat; background-position:6px 5px; background-size: cover;">
  <div class="bannerTextCont h-100 d-flex align-items-center">
    <div class="bannerText">
      <div class="brandName rube_font mb-0">
        Vixen Horror House Productions Presents...
      </div>
      <div class="bannerSubtitle pt-2">
        <img src="assets/images/logo_banner.png" alt="">
       
      </div>
     
    </div>
  </div>
</section> -->

<!-- New Banner End Here -->


<!-- Old Banner  -->
<!-- <section class="bannerLanding headerPadding" style="background: url(assets/images/banner2.jpg) no-repeat center top;">
  <div class="bannerTextCont h-100 d-flex align-i
  tems-center">
    <div class="bannerText">
      <div class="brandName ff_squarePeg mb-0">
        Vixen Horror House Productions Presents
      </div>
      <div class="bannerSubtitle pt-2">
        Chokechamber Films
        <i class="ms-3">
          <svg width="50" height="14" viewBox="0 0 50 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 0L14 7L7 14L0 7L7 0Z" fill="#9A2B4C"/>
            <path d="M29 2L34 7L29 12L24 7L29 2Z" fill="#9A2B4C"/>
            <path d="M47 4L50 7L47 10L44 7L47 4Z" fill="#9A2B4C"/>
          </svg>
        </i>
      </div>
      <div class="bannerTitle ff_kalam">
        <h4 class="display-4 pt-3">Where her pain is your pleasure.</h4>
      </div>
    </div> -->
<!-- <div class="bannerText">
      <div class="brandName ff_smooch text-uppercase h2 mb-0">
        <span class="ff_squarePeg display-4">VHH</span> presents Chokechamber
        <i class="ms-4">
          <svg xmlns="http://www.w3.org/2000/svg" width="50" height="14" viewBox="0 0 50 14" fill="none">
            <path d="M7 0L14 7L7 14L0 7L7 0Z" fill="#9A2B4C" />
            <path d="M29 2L34 7L29 12L24 7L29 2Z" fill="#9A2B4C" />
            <path d="M47 4L50 7L47 10L44 7L47 4Z" fill="#9A2B4C" />
          </svg>
        </i>
      </div>
      <div class="bannerTitle ff_kalam">
        <h4 class="display-4">Where Her pain is</h4>
        <h4 class="display-4 text-end"><i class="borderLine"></i> <i class="borderLine small ms-2"></i> your <span
            class="position-relative">pleasure</span></h4>
      </div>
    </div> -->
<!-- </div>
</section> -->

<!-- Latest Release Section Start -->
<section class="latest_release_sec pt-4  sec_padding_bottom">
  <div class="container-fluid">
    <!-- <div class="center_heading text-center mb-4 position-relative">
      <h3 class="ff_kalam fw-regular display-4">Latest Release</h3>
    </div> -->
    <div class="row">
      <div class="col-md-12 col-lg-6 col-xxl-6 col-xl-6">
        <nav class="navbar navbar-expand-lg navbar_block fit-content">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a class="nav-link active" (click)="filter('0')">Latest Release</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" (click)="filter('1')"> Best Sellers </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" (click)="filter('2')">Hot Deal</a>
            </li>
            <li class="nav-item">
              <!-- <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
              </button> -->
              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav mx-0 mb-2 mb-lg-0 mx-lg-auto">
                  <li class="nav-item px-0">
                    <a class="nav-link px-0 pt-1 pb-0 nav_link_bg">
                      <button class="bg-transparent btn-dark border-0 p-0" mat-icon-button
                        [matMenuTriggerFor]="catemenu" aria-label="Example icon-button with a menu">
                        <span class="price_text">price</span>
                        <i class="icon ms-2">
                          <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10"
                            fill="none">
                            <path d="M1 4L5 8L9 4" stroke="#F8F8F8" stroke-width="1.5" stroke-linecap="round"
                              stroke-linejoin="round" />
                          </svg>
                        </i>
                      </button>
                      <mat-menu #catemenu="matMenu" class="mat_dropdown bg-black">
                        <button mat-menu-item>
                          <span (click)="Price('0')"> Price Low to High </span>
                        </button>
                        <hr>
                        <button mat-menu-item>
                          <span (click)="Price('1')"> Price High to low </span>
                        </button>



                      </mat-menu>
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            <!-- <li class="nav-item">
                    <a class="nav-link" [routerLink]="['/models']" [routerLinkActive]="['active']">Models</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" [routerLink]="['/studios']" [routerLinkActive]="['active']">Studios</a>
                  </li> -->
            <li class="nav-item">
              <a class="nav-link" (click)="filter('4')">See All</a>
            </li>


          </ul>
        </nav>
      </div>
      <div class="col-md-12 col-lg-6">
        <div class="search_block ms-auto" (click)="searchData(search)">
          <input type="text" class="form-control" placeholder="Search Title...." [(ngModel)]="search">
          <span>
            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd"
                d="M8.39703 0.133301C12.924 0.133301 16.5939 3.80315 16.5939 8.33013C16.5939 10.2653 15.9233 12.0438 14.8018 13.446L18.1486 16.7937C18.5043 17.1494 18.5043 17.7261 18.1486 18.0817C17.8203 18.41 17.3037 18.4353 16.9464 18.1575L16.8606 18.0817L13.5129 14.7349C12.1107 15.8564 10.3322 16.527 8.39703 16.527C3.87004 16.527 0.200195 12.8571 0.200195 8.33013C0.200195 3.80315 3.87004 0.133301 8.39703 0.133301ZM8.39703 1.95482C4.87604 1.95482 2.02171 4.80914 2.02171 8.33013C2.02171 11.8511 4.87604 14.7054 8.39703 14.7054C11.918 14.7054 14.7723 11.8511 14.7723 8.33013C14.7723 4.80914 11.918 1.95482 8.39703 1.95482Z"
                fill="white" />
            </svg>
          </span>
        </div>
      </div>
    </div>
    <div class="row mt-3 mt-lg-3 g-3" *ngIf="latestReleases?.length!=0">
      <div class="col-md-3 col-lg-3 position-relative" *ngFor="let clips of latestReleases">
        <div class="videoBox position-relative m-0">
          <!-- <div class="video_container position-relative latest_img" (click)="videoDetail(clips.id)">
           
            <video onmouseover="this.play()" onmouseout="this.pause()">
              <source [src]="clipUrl + clips?.trailer" type="video/mp4">
            </video>
          </div> -->
          <div class="video_container position-relative latest_img">
            <div *ngIf="!clips.isHovered">
              <img [src]="clipUrl + clips.image" alt="" class="img-fluid" (mouseover)="playVideo(clips)" />
            </div>
            <div *ngIf="clips.isHovered">
              <video #video controls muted (mouseenter)="video.play()" (mouseleave)="pauseVideo(clips, video)">
                <source [src]="clipUrl + clips.trailer" type="video/mp4">
              </video>
            </div>
          </div>
          
          <div class="likeContainer d-flex align-items-center justify-content-center ms-auto me-3 cursor_pointer"
            (click)="fav(clips.id,clips.isFav)" [ngClass]="{'heart_filled': clips.isFav == '1'}" id="heart"
            [class.heart_filled]="red">
            <svg xmlns="http://www.w3.org/2000/svg" width="26.927" height="24" viewBox="0 0 26.927 24">
              <g id="Icon_feather-heart" data-name="Icon feather-heart" transform="translate(-0.327 -2.497)" fill="none"
                stroke-linecap="round" stroke-linejoin="round">
                <path
                  d="M23.485,6.265a6.033,6.033,0,0,0-8.535,0L13.788,7.428,12.625,6.265A6.035,6.035,0,1,0,4.091,14.8l1.163,1.163L13.788,24.5l8.535-8.535L23.485,14.8a6.033,6.033,0,0,0,0-8.535Z"
                  stroke="none" />
                <path
                  d="M 19.218017578125 4.497261047363281 C 17.67348861694336 4.497261047363281 16.12896156311035 5.086641311645508 14.95075130462646 6.265401840209961 L 13.78795146942139 7.428211212158203 L 12.62515163421631 6.265401840209961 C 10.26840209960938 3.908658981323242 6.447360992431641 3.908660888671875 4.090621948242188 6.265401840209961 C 1.733880996704102 8.622150421142578 1.733880996704102 12.443190574646 4.090621948242188 14.79993057250977 L 13.78795146942139 24.49726104736328 L 23.48528099060059 14.79993057250977 C 25.84280204772949 12.44351100921631 25.84280204772949 8.621820449829102 23.48528099060059 6.265401840209961 C 22.30707168579102 5.086641311645508 20.76254463195801 4.497261047363281 19.218017578125 4.497261047363281 M 19.2180118560791 2.497261047363281 C 21.36453247070312 2.497261047363281 23.38237190246582 3.333351135253906 24.89982223510742 4.851520538330078 C 26.41733169555664 6.368310928344727 27.25342178344727 8.386150360107422 27.25342178344727 10.53266143798828 C 27.25342178344727 12.67918109893799 26.41733169555664 14.69702053070068 24.89916229248047 16.21446990966797 L 15.20216178894043 25.91147041320801 C 14.82709121704102 26.28655052185059 14.31838130950928 26.49726104736328 13.78795146942139 26.49726104736328 C 13.2575216293335 26.49726104736328 12.74881172180176 26.28655052185059 12.37374114990234 25.91147041320801 L 2.676410675048828 16.21414184570312 C -0.4563579559326172 13.08137130737305 -0.4563694000244141 7.983970642089844 2.676401138305664 4.851190567016602 C 4.193981170654297 3.333610534667969 6.211702346801758 2.497840881347656 8.357881546020508 2.497840881347656 C 10.38759994506836 2.497840881347656 12.30235290527344 3.245304107666016 13.78789329528809 4.610126495361328 C 15.27305603027344 3.245044708251953 17.18797302246094 2.497261047363281 19.2180118560791 2.497261047363281 Z"
                  stroke="none" fill="#fff" />
              </g>
            </svg>
          </div>

          <!-- <div class="duration_box position-absolute">
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
              <path
                d="M6.99984 12.8332C10.2215 12.8332 12.8332 10.2215 12.8332 6.99984C12.8332 3.77818 10.2215 1.1665 6.99984 1.1665C3.77818 1.1665 1.1665 3.77818 1.1665 6.99984C1.1665 10.2215 3.77818 12.8332 6.99984 12.8332Z"
                stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M9 9L7.39067 7.793C7.1406 7.60551 7.00008 7.35119 7 7.086V4" stroke="white" stroke-width="1.5"
                stroke-linecap="round" stroke-linejoin="round" />
            </svg>
            <span class="small ms-1">1:15:30</span>
          </div> -->
          <div class="desBox d-flex position-absolute p-3 w-100" (click)="videoDetail(clips.id)">
            <div class="description">
              <p class="small color_pink mb-1 text-truncate">{{clips?.title}}</p>
              <h6 class="fs18 text-truncate mb-0 desHeading">{{clips?.description}}</h6>
            </div>
            <div class="price_count text-end">
              <p class="fs13 mb-1 opacity-50 text-uppercase">{{clips.created_at | date}}</p>
              <h6 class="mb-0 d-flex align-items-center justify-content-end"><i class="me-2"><img
                    src="assets/images/coin.svg" alt=""></i>
                {{clips.price}}</h6>
            </div>
          </div>
        </div>
      </div>

      <!-- no data found -->


      <!-- <div class="col-md-3 col-lg-3 mt-4">
              <div class="videoBox position-relative m-0">
              
                <div class="video_container position-relative latest_img">
                    <img src="assets/images/Vimg2.jpg" alt="" class="img-fluid"/>
                </div>
            
                  <div class="likeContainer d-flex align-items-center justify-content-center ms-auto me-3 cursor_pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="26.927" height="24" viewBox="0 0 26.927 24">
                      <g id="Icon_feather-heart" data-name="Icon feather-heart" transform="translate(-0.327 -2.497)"
                        fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path
                          d="M23.485,6.265a6.033,6.033,0,0,0-8.535,0L13.788,7.428,12.625,6.265A6.035,6.035,0,1,0,4.091,14.8l1.163,1.163L13.788,24.5l8.535-8.535L23.485,14.8a6.033,6.033,0,0,0,0-8.535Z"
                          stroke="none" />
                        <path
                          d="M 19.218017578125 4.497261047363281 C 17.67348861694336 4.497261047363281 16.12896156311035 5.086641311645508 14.95075130462646 6.265401840209961 L 13.78795146942139 7.428211212158203 L 12.62515163421631 6.265401840209961 C 10.26840209960938 3.908658981323242 6.447360992431641 3.908660888671875 4.090621948242188 6.265401840209961 C 1.733880996704102 8.622150421142578 1.733880996704102 12.443190574646 4.090621948242188 14.79993057250977 L 13.78795146942139 24.49726104736328 L 23.48528099060059 14.79993057250977 C 25.84280204772949 12.44351100921631 25.84280204772949 8.621820449829102 23.48528099060059 6.265401840209961 C 22.30707168579102 5.086641311645508 20.76254463195801 4.497261047363281 19.218017578125 4.497261047363281 M 19.2180118560791 2.497261047363281 C 21.36453247070312 2.497261047363281 23.38237190246582 3.333351135253906 24.89982223510742 4.851520538330078 C 26.41733169555664 6.368310928344727 27.25342178344727 8.386150360107422 27.25342178344727 10.53266143798828 C 27.25342178344727 12.67918109893799 26.41733169555664 14.69702053070068 24.89916229248047 16.21446990966797 L 15.20216178894043 25.91147041320801 C 14.82709121704102 26.28655052185059 14.31838130950928 26.49726104736328 13.78795146942139 26.49726104736328 C 13.2575216293335 26.49726104736328 12.74881172180176 26.28655052185059 12.37374114990234 25.91147041320801 L 2.676410675048828 16.21414184570312 C -0.4563579559326172 13.08137130737305 -0.4563694000244141 7.983970642089844 2.676401138305664 4.851190567016602 C 4.193981170654297 3.333610534667969 6.211702346801758 2.497840881347656 8.357881546020508 2.497840881347656 C 10.38759994506836 2.497840881347656 12.30235290527344 3.245304107666016 13.78789329528809 4.610126495361328 C 15.27305603027344 3.245044708251953 17.18797302246094 2.497261047363281 19.2180118560791 2.497261047363281 Z"
                          stroke="none" fill="#fff" />
                      </g>
                    </svg>
                  </div>
                  <div class="duration_box position-absolute">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                      <path
                        d="M6.99984 12.8332C10.2215 12.8332 12.8332 10.2215 12.8332 6.99984C12.8332 3.77818 10.2215 1.1665 6.99984 1.1665C3.77818 1.1665 1.1665 3.77818 1.1665 6.99984C1.1665 10.2215 3.77818 12.8332 6.99984 12.8332Z"
                        stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M9 9L7.39067 7.793C7.1406 7.60551 7.00008 7.35119 7 7.086V4" stroke="white" stroke-width="1.5"
                        stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span class="small ms-1">1:15:30</span>
                  </div>
                 
                  <div class="desBox d-flex position-absolute p-3 w-100">
                    <div class="description">
                      <p class="small color_pink mb-1">Bondage</p>
                      <h6 class="fs18 text-truncate mb-0 desHeading">Innocent looking...</h6>
                    </div>
                    <div class="price_count text-end">
                      <p class="fs13 mb-1 opacity-50 text-uppercase">JUN 15, 2020</p>
                      <h6 class="mb-0 d-flex align-items-center justify-content-end"><i class="me-2"><img
                            src="assets/images/coin.svg" alt=""></i>
                            10</h6>
                    </div>
                  </div>
              </div>
            </div>


            <div class="col-md-3 col-lg-3 mt-4">
              <div class="videoBox position-relative m-0">
              
                <div class="video_container position-relative latest_img">
                    <img src="assets/images/Vimg3.jpg" alt="" class="img-fluid"/>
                </div>
            
                  <div class="likeContainer d-flex align-items-center justify-content-center ms-auto me-3 cursor_pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="26.927" height="24" viewBox="0 0 26.927 24">
                      <g id="Icon_feather-heart" data-name="Icon feather-heart" transform="translate(-0.327 -2.497)"
                        fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path
                          d="M23.485,6.265a6.033,6.033,0,0,0-8.535,0L13.788,7.428,12.625,6.265A6.035,6.035,0,1,0,4.091,14.8l1.163,1.163L13.788,24.5l8.535-8.535L23.485,14.8a6.033,6.033,0,0,0,0-8.535Z"
                          stroke="none" />
                        <path
                          d="M 19.218017578125 4.497261047363281 C 17.67348861694336 4.497261047363281 16.12896156311035 5.086641311645508 14.95075130462646 6.265401840209961 L 13.78795146942139 7.428211212158203 L 12.62515163421631 6.265401840209961 C 10.26840209960938 3.908658981323242 6.447360992431641 3.908660888671875 4.090621948242188 6.265401840209961 C 1.733880996704102 8.622150421142578 1.733880996704102 12.443190574646 4.090621948242188 14.79993057250977 L 13.78795146942139 24.49726104736328 L 23.48528099060059 14.79993057250977 C 25.84280204772949 12.44351100921631 25.84280204772949 8.621820449829102 23.48528099060059 6.265401840209961 C 22.30707168579102 5.086641311645508 20.76254463195801 4.497261047363281 19.218017578125 4.497261047363281 M 19.2180118560791 2.497261047363281 C 21.36453247070312 2.497261047363281 23.38237190246582 3.333351135253906 24.89982223510742 4.851520538330078 C 26.41733169555664 6.368310928344727 27.25342178344727 8.386150360107422 27.25342178344727 10.53266143798828 C 27.25342178344727 12.67918109893799 26.41733169555664 14.69702053070068 24.89916229248047 16.21446990966797 L 15.20216178894043 25.91147041320801 C 14.82709121704102 26.28655052185059 14.31838130950928 26.49726104736328 13.78795146942139 26.49726104736328 C 13.2575216293335 26.49726104736328 12.74881172180176 26.28655052185059 12.37374114990234 25.91147041320801 L 2.676410675048828 16.21414184570312 C -0.4563579559326172 13.08137130737305 -0.4563694000244141 7.983970642089844 2.676401138305664 4.851190567016602 C 4.193981170654297 3.333610534667969 6.211702346801758 2.497840881347656 8.357881546020508 2.497840881347656 C 10.38759994506836 2.497840881347656 12.30235290527344 3.245304107666016 13.78789329528809 4.610126495361328 C 15.27305603027344 3.245044708251953 17.18797302246094 2.497261047363281 19.2180118560791 2.497261047363281 Z"
                          stroke="none" fill="#fff" />
                      </g>
                    </svg>
                  </div>
                  <div class="duration_box position-absolute">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                      <path
                        d="M6.99984 12.8332C10.2215 12.8332 12.8332 10.2215 12.8332 6.99984C12.8332 3.77818 10.2215 1.1665 6.99984 1.1665C3.77818 1.1665 1.1665 3.77818 1.1665 6.99984C1.1665 10.2215 3.77818 12.8332 6.99984 12.8332Z"
                        stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M9 9L7.39067 7.793C7.1406 7.60551 7.00008 7.35119 7 7.086V4" stroke="white" stroke-width="1.5"
                        stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span class="small ms-1">1:15:30</span>
                  </div>
                 
                  <div class="desBox d-flex position-absolute p-3 w-100">
                    <div class="description">
                      <p class="small color_pink mb-1">Bondage</p>
                      <h6 class="fs18 text-truncate mb-0 desHeading">Innocent looking...</h6>
                    </div>
                    <div class="price_count text-end">
                      <p class="fs13 mb-1 opacity-50 text-uppercase">JUN 15, 2020</p>
                      <h6 class="mb-0 d-flex align-items-center justify-content-end"><i class="me-2"><img
                            src="assets/images/coin.svg" alt=""></i>
                            10</h6>
                    </div>
                  </div>
              </div>
            </div>


            <div class="col-md-3 col-lg-3 mt-4">
              <div class="videoBox position-relative m-0">
              
                <div class="video_container position-relative latest_img">
                    <img src="assets/images/Vimg4.jpg" alt="" class="img-fluid"/>
                </div>
            
                  <div class="likeContainer d-flex align-items-center justify-content-center ms-auto me-3 cursor_pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="26.927" height="24" viewBox="0 0 26.927 24">
                      <g id="Icon_feather-heart" data-name="Icon feather-heart" transform="translate(-0.327 -2.497)"
                        fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path
                          d="M23.485,6.265a6.033,6.033,0,0,0-8.535,0L13.788,7.428,12.625,6.265A6.035,6.035,0,1,0,4.091,14.8l1.163,1.163L13.788,24.5l8.535-8.535L23.485,14.8a6.033,6.033,0,0,0,0-8.535Z"
                          stroke="none" />
                        <path
                          d="M 19.218017578125 4.497261047363281 C 17.67348861694336 4.497261047363281 16.12896156311035 5.086641311645508 14.95075130462646 6.265401840209961 L 13.78795146942139 7.428211212158203 L 12.62515163421631 6.265401840209961 C 10.26840209960938 3.908658981323242 6.447360992431641 3.908660888671875 4.090621948242188 6.265401840209961 C 1.733880996704102 8.622150421142578 1.733880996704102 12.443190574646 4.090621948242188 14.79993057250977 L 13.78795146942139 24.49726104736328 L 23.48528099060059 14.79993057250977 C 25.84280204772949 12.44351100921631 25.84280204772949 8.621820449829102 23.48528099060059 6.265401840209961 C 22.30707168579102 5.086641311645508 20.76254463195801 4.497261047363281 19.218017578125 4.497261047363281 M 19.2180118560791 2.497261047363281 C 21.36453247070312 2.497261047363281 23.38237190246582 3.333351135253906 24.89982223510742 4.851520538330078 C 26.41733169555664 6.368310928344727 27.25342178344727 8.386150360107422 27.25342178344727 10.53266143798828 C 27.25342178344727 12.67918109893799 26.41733169555664 14.69702053070068 24.89916229248047 16.21446990966797 L 15.20216178894043 25.91147041320801 C 14.82709121704102 26.28655052185059 14.31838130950928 26.49726104736328 13.78795146942139 26.49726104736328 C 13.2575216293335 26.49726104736328 12.74881172180176 26.28655052185059 12.37374114990234 25.91147041320801 L 2.676410675048828 16.21414184570312 C -0.4563579559326172 13.08137130737305 -0.4563694000244141 7.983970642089844 2.676401138305664 4.851190567016602 C 4.193981170654297 3.333610534667969 6.211702346801758 2.497840881347656 8.357881546020508 2.497840881347656 C 10.38759994506836 2.497840881347656 12.30235290527344 3.245304107666016 13.78789329528809 4.610126495361328 C 15.27305603027344 3.245044708251953 17.18797302246094 2.497261047363281 19.2180118560791 2.497261047363281 Z"
                          stroke="none" fill="#fff" />
                      </g>
                    </svg>
                  </div>
                  <div class="duration_box position-absolute">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                      <path
                        d="M6.99984 12.8332C10.2215 12.8332 12.8332 10.2215 12.8332 6.99984C12.8332 3.77818 10.2215 1.1665 6.99984 1.1665C3.77818 1.1665 1.1665 3.77818 1.1665 6.99984C1.1665 10.2215 3.77818 12.8332 6.99984 12.8332Z"
                        stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M9 9L7.39067 7.793C7.1406 7.60551 7.00008 7.35119 7 7.086V4" stroke="white" stroke-width="1.5"
                        stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span class="small ms-1">1:15:30</span>
                  </div>
                 
                  <div class="desBox d-flex position-absolute p-3 w-100">
                    <div class="description">
                      <p class="small color_pink mb-1">Bondage</p>
                      <h6 class="fs18 text-truncate mb-0 desHeading">Innocent looking...</h6>
                    </div>
                    <div class="price_count text-end">
                      <p class="fs13 mb-1 opacity-50 text-uppercase">JUN 15, 2020</p>
                      <h6 class="mb-0 d-flex align-items-center justify-content-end"><i class="me-2"><img
                            src="assets/images/coin.svg" alt=""></i>
                            10</h6>
                    </div>
                  </div>
              </div>
            </div>


            <div class="col-md-3 col-lg-3 mt-4">
              <div class="videoBox position-relative m-0">
              
                <div class="video_container position-relative latest_img">
                    <img src="assets/images/Vimg6.jpg" alt="" class="img-fluid"/>
                </div>
            
                  <div class="likeContainer d-flex align-items-center justify-content-center ms-auto me-3 cursor_pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="26.927" height="24" viewBox="0 0 26.927 24">
                      <g id="Icon_feather-heart" data-name="Icon feather-heart" transform="translate(-0.327 -2.497)"
                        fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path
                          d="M23.485,6.265a6.033,6.033,0,0,0-8.535,0L13.788,7.428,12.625,6.265A6.035,6.035,0,1,0,4.091,14.8l1.163,1.163L13.788,24.5l8.535-8.535L23.485,14.8a6.033,6.033,0,0,0,0-8.535Z"
                          stroke="none" />
                        <path
                          d="M 19.218017578125 4.497261047363281 C 17.67348861694336 4.497261047363281 16.12896156311035 5.086641311645508 14.95075130462646 6.265401840209961 L 13.78795146942139 7.428211212158203 L 12.62515163421631 6.265401840209961 C 10.26840209960938 3.908658981323242 6.447360992431641 3.908660888671875 4.090621948242188 6.265401840209961 C 1.733880996704102 8.622150421142578 1.733880996704102 12.443190574646 4.090621948242188 14.79993057250977 L 13.78795146942139 24.49726104736328 L 23.48528099060059 14.79993057250977 C 25.84280204772949 12.44351100921631 25.84280204772949 8.621820449829102 23.48528099060059 6.265401840209961 C 22.30707168579102 5.086641311645508 20.76254463195801 4.497261047363281 19.218017578125 4.497261047363281 M 19.2180118560791 2.497261047363281 C 21.36453247070312 2.497261047363281 23.38237190246582 3.333351135253906 24.89982223510742 4.851520538330078 C 26.41733169555664 6.368310928344727 27.25342178344727 8.386150360107422 27.25342178344727 10.53266143798828 C 27.25342178344727 12.67918109893799 26.41733169555664 14.69702053070068 24.89916229248047 16.21446990966797 L 15.20216178894043 25.91147041320801 C 14.82709121704102 26.28655052185059 14.31838130950928 26.49726104736328 13.78795146942139 26.49726104736328 C 13.2575216293335 26.49726104736328 12.74881172180176 26.28655052185059 12.37374114990234 25.91147041320801 L 2.676410675048828 16.21414184570312 C -0.4563579559326172 13.08137130737305 -0.4563694000244141 7.983970642089844 2.676401138305664 4.851190567016602 C 4.193981170654297 3.333610534667969 6.211702346801758 2.497840881347656 8.357881546020508 2.497840881347656 C 10.38759994506836 2.497840881347656 12.30235290527344 3.245304107666016 13.78789329528809 4.610126495361328 C 15.27305603027344 3.245044708251953 17.18797302246094 2.497261047363281 19.2180118560791 2.497261047363281 Z"
                          stroke="none" fill="#fff" />
                      </g>
                    </svg>
                  </div>
                  <div class="duration_box position-absolute">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                      <path
                        d="M6.99984 12.8332C10.2215 12.8332 12.8332 10.2215 12.8332 6.99984C12.8332 3.77818 10.2215 1.1665 6.99984 1.1665C3.77818 1.1665 1.1665 3.77818 1.1665 6.99984C1.1665 10.2215 3.77818 12.8332 6.99984 12.8332Z"
                        stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M9 9L7.39067 7.793C7.1406 7.60551 7.00008 7.35119 7 7.086V4" stroke="white" stroke-width="1.5"
                        stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span class="small ms-1">1:15:30</span>
                  </div>
                 
                  <div class="desBox d-flex position-absolute p-3 w-100">
                    <div class="description">
                      <p class="small color_pink mb-1">Bondage</p>
                      <h6 class="fs18 text-truncate mb-0 desHeading">Innocent looking...</h6>
                    </div>
                    <div class="price_count text-end">
                      <p class="fs13 mb-1 opacity-50 text-uppercase">JUN 15, 2020</p>
                      <h6 class="mb-0 d-flex align-items-center justify-content-end"><i class="me-2"><img
                            src="assets/images/coin.svg" alt=""></i>
                            10</h6>
                    </div>
                  </div>
              </div>
            </div>

            <div class="col-md-3 col-lg-3 mt-4">
              <div class="videoBox position-relative m-0">
              
                <div class="video_container position-relative latest_img">
                    <img src="assets/images/Vimg7.jpg" alt="" class="img-fluid"/>
                </div>
            
                  <div class="likeContainer d-flex align-items-center justify-content-center ms-auto me-3 cursor_pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="26.927" height="24" viewBox="0 0 26.927 24">
                      <g id="Icon_feather-heart" data-name="Icon feather-heart" transform="translate(-0.327 -2.497)"
                        fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path
                          d="M23.485,6.265a6.033,6.033,0,0,0-8.535,0L13.788,7.428,12.625,6.265A6.035,6.035,0,1,0,4.091,14.8l1.163,1.163L13.788,24.5l8.535-8.535L23.485,14.8a6.033,6.033,0,0,0,0-8.535Z"
                          stroke="none" />
                        <path
                          d="M 19.218017578125 4.497261047363281 C 17.67348861694336 4.497261047363281 16.12896156311035 5.086641311645508 14.95075130462646 6.265401840209961 L 13.78795146942139 7.428211212158203 L 12.62515163421631 6.265401840209961 C 10.26840209960938 3.908658981323242 6.447360992431641 3.908660888671875 4.090621948242188 6.265401840209961 C 1.733880996704102 8.622150421142578 1.733880996704102 12.443190574646 4.090621948242188 14.79993057250977 L 13.78795146942139 24.49726104736328 L 23.48528099060059 14.79993057250977 C 25.84280204772949 12.44351100921631 25.84280204772949 8.621820449829102 23.48528099060059 6.265401840209961 C 22.30707168579102 5.086641311645508 20.76254463195801 4.497261047363281 19.218017578125 4.497261047363281 M 19.2180118560791 2.497261047363281 C 21.36453247070312 2.497261047363281 23.38237190246582 3.333351135253906 24.89982223510742 4.851520538330078 C 26.41733169555664 6.368310928344727 27.25342178344727 8.386150360107422 27.25342178344727 10.53266143798828 C 27.25342178344727 12.67918109893799 26.41733169555664 14.69702053070068 24.89916229248047 16.21446990966797 L 15.20216178894043 25.91147041320801 C 14.82709121704102 26.28655052185059 14.31838130950928 26.49726104736328 13.78795146942139 26.49726104736328 C 13.2575216293335 26.49726104736328 12.74881172180176 26.28655052185059 12.37374114990234 25.91147041320801 L 2.676410675048828 16.21414184570312 C -0.4563579559326172 13.08137130737305 -0.4563694000244141 7.983970642089844 2.676401138305664 4.851190567016602 C 4.193981170654297 3.333610534667969 6.211702346801758 2.497840881347656 8.357881546020508 2.497840881347656 C 10.38759994506836 2.497840881347656 12.30235290527344 3.245304107666016 13.78789329528809 4.610126495361328 C 15.27305603027344 3.245044708251953 17.18797302246094 2.497261047363281 19.2180118560791 2.497261047363281 Z"
                          stroke="none" fill="#fff" />
                      </g>
                    </svg>
                  </div>
                  <div class="duration_box position-absolute">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                      <path
                        d="M6.99984 12.8332C10.2215 12.8332 12.8332 10.2215 12.8332 6.99984C12.8332 3.77818 10.2215 1.1665 6.99984 1.1665C3.77818 1.1665 1.1665 3.77818 1.1665 6.99984C1.1665 10.2215 3.77818 12.8332 6.99984 12.8332Z"
                        stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M9 9L7.39067 7.793C7.1406 7.60551 7.00008 7.35119 7 7.086V4" stroke="white" stroke-width="1.5"
                        stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span class="small ms-1">1:15:30</span>
                  </div>
                 
                  <div class="desBox d-flex position-absolute p-3 w-100">
                    <div class="description">
                      <p class="small color_pink mb-1">Bondage</p>
                      <h6 class="fs18 text-truncate mb-0 desHeading">Innocent looking...</h6>
                    </div>
                    <div class="price_count text-end">
                      <p class="fs13 mb-1 opacity-50 text-uppercase">JUN 15, 2020</p>
                      <h6 class="mb-0 d-flex align-items-center justify-content-end"><i class="me-2"><img
                            src="assets/images/coin.svg" alt=""></i>
                            10</h6>
                    </div>
                  </div>
              </div>
            </div>


            <div class="col-md-3 col-lg-3 mt-4">
              <div class="videoBox position-relative m-0">
              
                <div class="video_container position-relative latest_img">
                    <img src="assets/images/Vimg8.jpg" alt="" class="img-fluid"/>
                </div>
            
                  <div class="likeContainer d-flex align-items-center justify-content-center ms-auto me-3 cursor_pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="26.927" height="24" viewBox="0 0 26.927 24">
                      <g id="Icon_feather-heart" data-name="Icon feather-heart" transform="translate(-0.327 -2.497)"
                        fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path
                          d="M23.485,6.265a6.033,6.033,0,0,0-8.535,0L13.788,7.428,12.625,6.265A6.035,6.035,0,1,0,4.091,14.8l1.163,1.163L13.788,24.5l8.535-8.535L23.485,14.8a6.033,6.033,0,0,0,0-8.535Z"
                          stroke="none" />
                        <path
                          d="M 19.218017578125 4.497261047363281 C 17.67348861694336 4.497261047363281 16.12896156311035 5.086641311645508 14.95075130462646 6.265401840209961 L 13.78795146942139 7.428211212158203 L 12.62515163421631 6.265401840209961 C 10.26840209960938 3.908658981323242 6.447360992431641 3.908660888671875 4.090621948242188 6.265401840209961 C 1.733880996704102 8.622150421142578 1.733880996704102 12.443190574646 4.090621948242188 14.79993057250977 L 13.78795146942139 24.49726104736328 L 23.48528099060059 14.79993057250977 C 25.84280204772949 12.44351100921631 25.84280204772949 8.621820449829102 23.48528099060059 6.265401840209961 C 22.30707168579102 5.086641311645508 20.76254463195801 4.497261047363281 19.218017578125 4.497261047363281 M 19.2180118560791 2.497261047363281 C 21.36453247070312 2.497261047363281 23.38237190246582 3.333351135253906 24.89982223510742 4.851520538330078 C 26.41733169555664 6.368310928344727 27.25342178344727 8.386150360107422 27.25342178344727 10.53266143798828 C 27.25342178344727 12.67918109893799 26.41733169555664 14.69702053070068 24.89916229248047 16.21446990966797 L 15.20216178894043 25.91147041320801 C 14.82709121704102 26.28655052185059 14.31838130950928 26.49726104736328 13.78795146942139 26.49726104736328 C 13.2575216293335 26.49726104736328 12.74881172180176 26.28655052185059 12.37374114990234 25.91147041320801 L 2.676410675048828 16.21414184570312 C -0.4563579559326172 13.08137130737305 -0.4563694000244141 7.983970642089844 2.676401138305664 4.851190567016602 C 4.193981170654297 3.333610534667969 6.211702346801758 2.497840881347656 8.357881546020508 2.497840881347656 C 10.38759994506836 2.497840881347656 12.30235290527344 3.245304107666016 13.78789329528809 4.610126495361328 C 15.27305603027344 3.245044708251953 17.18797302246094 2.497261047363281 19.2180118560791 2.497261047363281 Z"
                          stroke="none" fill="#fff" />
                      </g>
                    </svg>
                  </div>
                  <div class="duration_box position-absolute">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                      <path
                        d="M6.99984 12.8332C10.2215 12.8332 12.8332 10.2215 12.8332 6.99984C12.8332 3.77818 10.2215 1.1665 6.99984 1.1665C3.77818 1.1665 1.1665 3.77818 1.1665 6.99984C1.1665 10.2215 3.77818 12.8332 6.99984 12.8332Z"
                        stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M9 9L7.39067 7.793C7.1406 7.60551 7.00008 7.35119 7 7.086V4" stroke="white" stroke-width="1.5"
                        stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span class="small ms-1">1:15:30</span>
                  </div>
                 
                  <div class="desBox d-flex position-absolute p-3 w-100">
                    <div class="description">
                      <p class="small color_pink mb-1">Bondage</p>
                      <h6 class="fs18 text-truncate mb-0 desHeading">Innocent looking...</h6>
                    </div>
                    <div class="price_count text-end">
                      <p class="fs13 mb-1 opacity-50 text-uppercase">JUN 15, 2020</p>
                      <h6 class="mb-0 d-flex align-items-center justify-content-end"><i class="me-2"><img
                            src="assets/images/coin.svg" alt=""></i>
                            10</h6>
                    </div>
                  </div>
              </div>
            </div>

            <div class="col-md-3 col-lg-3 mt-4">
              <div class="videoBox position-relative m-0">
              
                <div class="video_container position-relative latest_img">
                    <img src="assets/images/Vimg9.jpg" alt="" class="img-fluid"/>
                </div>
            
                  <div class="likeContainer d-flex align-items-center justify-content-center ms-auto me-3 cursor_pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="26.927" height="24" viewBox="0 0 26.927 24">
                      <g id="Icon_feather-heart" data-name="Icon feather-heart" transform="translate(-0.327 -2.497)"
                        fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path
                          d="M23.485,6.265a6.033,6.033,0,0,0-8.535,0L13.788,7.428,12.625,6.265A6.035,6.035,0,1,0,4.091,14.8l1.163,1.163L13.788,24.5l8.535-8.535L23.485,14.8a6.033,6.033,0,0,0,0-8.535Z"
                          stroke="none" />
                        <path
                          d="M 19.218017578125 4.497261047363281 C 17.67348861694336 4.497261047363281 16.12896156311035 5.086641311645508 14.95075130462646 6.265401840209961 L 13.78795146942139 7.428211212158203 L 12.62515163421631 6.265401840209961 C 10.26840209960938 3.908658981323242 6.447360992431641 3.908660888671875 4.090621948242188 6.265401840209961 C 1.733880996704102 8.622150421142578 1.733880996704102 12.443190574646 4.090621948242188 14.79993057250977 L 13.78795146942139 24.49726104736328 L 23.48528099060059 14.79993057250977 C 25.84280204772949 12.44351100921631 25.84280204772949 8.621820449829102 23.48528099060059 6.265401840209961 C 22.30707168579102 5.086641311645508 20.76254463195801 4.497261047363281 19.218017578125 4.497261047363281 M 19.2180118560791 2.497261047363281 C 21.36453247070312 2.497261047363281 23.38237190246582 3.333351135253906 24.89982223510742 4.851520538330078 C 26.41733169555664 6.368310928344727 27.25342178344727 8.386150360107422 27.25342178344727 10.53266143798828 C 27.25342178344727 12.67918109893799 26.41733169555664 14.69702053070068 24.89916229248047 16.21446990966797 L 15.20216178894043 25.91147041320801 C 14.82709121704102 26.28655052185059 14.31838130950928 26.49726104736328 13.78795146942139 26.49726104736328 C 13.2575216293335 26.49726104736328 12.74881172180176 26.28655052185059 12.37374114990234 25.91147041320801 L 2.676410675048828 16.21414184570312 C -0.4563579559326172 13.08137130737305 -0.4563694000244141 7.983970642089844 2.676401138305664 4.851190567016602 C 4.193981170654297 3.333610534667969 6.211702346801758 2.497840881347656 8.357881546020508 2.497840881347656 C 10.38759994506836 2.497840881347656 12.30235290527344 3.245304107666016 13.78789329528809 4.610126495361328 C 15.27305603027344 3.245044708251953 17.18797302246094 2.497261047363281 19.2180118560791 2.497261047363281 Z"
                          stroke="none" fill="#fff" />
                      </g>
                    </svg>
                  </div>
                  <div class="duration_box position-absolute">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                      <path
                        d="M6.99984 12.8332C10.2215 12.8332 12.8332 10.2215 12.8332 6.99984C12.8332 3.77818 10.2215 1.1665 6.99984 1.1665C3.77818 1.1665 1.1665 3.77818 1.1665 6.99984C1.1665 10.2215 3.77818 12.8332 6.99984 12.8332Z"
                        stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M9 9L7.39067 7.793C7.1406 7.60551 7.00008 7.35119 7 7.086V4" stroke="white" stroke-width="1.5"
                        stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span class="small ms-1">1:15:30</span>
                  </div>
                 
                  <div class="desBox d-flex position-absolute p-3 w-100">
                    <div class="description">
                      <p class="small color_pink mb-1">Bondage</p>
                      <h6 class="fs18 text-truncate mb-0 desHeading">Innocent looking...</h6>
                    </div>
                    <div class="price_count text-end">
                      <p class="fs13 mb-1 opacity-50 text-uppercase">JUN 15, 2020</p>
                      <h6 class="mb-0 d-flex align-items-center justify-content-end"><i class="me-2"><img
                            src="assets/images/coin.svg" alt=""></i>
                            10</h6>
                    </div>
                  </div>
              </div>
            </div>
        
            <div class="col-md-3 col-lg-3 mt-4">
              <div class="videoBox position-relative m-0">
                <div class="video_container position-relative latest_img">
                    <img src="assets/images/Vimg1.jpg" alt="" class="img-fluid"/>
                </div>
                  <div class="likeContainer d-flex align-items-center justify-content-center ms-auto me-3 cursor_pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="26.927" height="24" viewBox="0 0 26.927 24">
                      <g id="Icon_feather-heart" data-name="Icon feather-heart" transform="translate(-0.327 -2.497)"
                        fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path
                          d="M23.485,6.265a6.033,6.033,0,0,0-8.535,0L13.788,7.428,12.625,6.265A6.035,6.035,0,1,0,4.091,14.8l1.163,1.163L13.788,24.5l8.535-8.535L23.485,14.8a6.033,6.033,0,0,0,0-8.535Z"
                          stroke="none" />
                        <path
                          d="M 19.218017578125 4.497261047363281 C 17.67348861694336 4.497261047363281 16.12896156311035 5.086641311645508 14.95075130462646 6.265401840209961 L 13.78795146942139 7.428211212158203 L 12.62515163421631 6.265401840209961 C 10.26840209960938 3.908658981323242 6.447360992431641 3.908660888671875 4.090621948242188 6.265401840209961 C 1.733880996704102 8.622150421142578 1.733880996704102 12.443190574646 4.090621948242188 14.79993057250977 L 13.78795146942139 24.49726104736328 L 23.48528099060059 14.79993057250977 C 25.84280204772949 12.44351100921631 25.84280204772949 8.621820449829102 23.48528099060059 6.265401840209961 C 22.30707168579102 5.086641311645508 20.76254463195801 4.497261047363281 19.218017578125 4.497261047363281 M 19.2180118560791 2.497261047363281 C 21.36453247070312 2.497261047363281 23.38237190246582 3.333351135253906 24.89982223510742 4.851520538330078 C 26.41733169555664 6.368310928344727 27.25342178344727 8.386150360107422 27.25342178344727 10.53266143798828 C 27.25342178344727 12.67918109893799 26.41733169555664 14.69702053070068 24.89916229248047 16.21446990966797 L 15.20216178894043 25.91147041320801 C 14.82709121704102 26.28655052185059 14.31838130950928 26.49726104736328 13.78795146942139 26.49726104736328 C 13.2575216293335 26.49726104736328 12.74881172180176 26.28655052185059 12.37374114990234 25.91147041320801 L 2.676410675048828 16.21414184570312 C -0.4563579559326172 13.08137130737305 -0.4563694000244141 7.983970642089844 2.676401138305664 4.851190567016602 C 4.193981170654297 3.333610534667969 6.211702346801758 2.497840881347656 8.357881546020508 2.497840881347656 C 10.38759994506836 2.497840881347656 12.30235290527344 3.245304107666016 13.78789329528809 4.610126495361328 C 15.27305603027344 3.245044708251953 17.18797302246094 2.497261047363281 19.2180118560791 2.497261047363281 Z"
                          stroke="none" fill="#fff" />
                      </g>
                    </svg>
                  </div>
                 
                <div class="duration_box position-absolute">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                      <path
                        d="M6.99984 12.8332C10.2215 12.8332 12.8332 10.2215 12.8332 6.99984C12.8332 3.77818 10.2215 1.1665 6.99984 1.1665C3.77818 1.1665 1.1665 3.77818 1.1665 6.99984C1.1665 10.2215 3.77818 12.8332 6.99984 12.8332Z"
                        stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M9 9L7.39067 7.793C7.1406 7.60551 7.00008 7.35119 7 7.086V4" stroke="white" stroke-width="1.5"
                        stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span class="small ms-1">1:15:30</span>
                  </div>
                  <div class="desBox d-flex position-absolute p-3 w-100">
                    <div class="description">
                      <p class="small color_pink mb-1">Bondage</p>
                      <h6 class="fs18 text-truncate mb-0 desHeading">Innocent looking...</h6>
                    </div>
                    <div class="price_count text-end">
                      <p class="fs13 mb-1 opacity-50 text-uppercase">JUN 15, 2020</p>
                      <h6 class="mb-0 d-flex align-items-center justify-content-end"><i class="me-2"><img
                            src="assets/images/coin.svg" alt=""></i>
                            10</h6>
                    </div>
                  </div>
              </div>
            </div>

            <div class="col-md-3 col-lg-3 mt-4">
              <div class="videoBox position-relative m-0">
              
                <div class="video_container position-relative latest_img">
                    <img src="assets/images/Vimg2.jpg" alt="" class="img-fluid"/>
                </div>
            
                  <div class="likeContainer d-flex align-items-center justify-content-center ms-auto me-3 cursor_pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="26.927" height="24" viewBox="0 0 26.927 24">
                      <g id="Icon_feather-heart" data-name="Icon feather-heart" transform="translate(-0.327 -2.497)"
                        fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path
                          d="M23.485,6.265a6.033,6.033,0,0,0-8.535,0L13.788,7.428,12.625,6.265A6.035,6.035,0,1,0,4.091,14.8l1.163,1.163L13.788,24.5l8.535-8.535L23.485,14.8a6.033,6.033,0,0,0,0-8.535Z"
                          stroke="none" />
                        <path
                          d="M 19.218017578125 4.497261047363281 C 17.67348861694336 4.497261047363281 16.12896156311035 5.086641311645508 14.95075130462646 6.265401840209961 L 13.78795146942139 7.428211212158203 L 12.62515163421631 6.265401840209961 C 10.26840209960938 3.908658981323242 6.447360992431641 3.908660888671875 4.090621948242188 6.265401840209961 C 1.733880996704102 8.622150421142578 1.733880996704102 12.443190574646 4.090621948242188 14.79993057250977 L 13.78795146942139 24.49726104736328 L 23.48528099060059 14.79993057250977 C 25.84280204772949 12.44351100921631 25.84280204772949 8.621820449829102 23.48528099060059 6.265401840209961 C 22.30707168579102 5.086641311645508 20.76254463195801 4.497261047363281 19.218017578125 4.497261047363281 M 19.2180118560791 2.497261047363281 C 21.36453247070312 2.497261047363281 23.38237190246582 3.333351135253906 24.89982223510742 4.851520538330078 C 26.41733169555664 6.368310928344727 27.25342178344727 8.386150360107422 27.25342178344727 10.53266143798828 C 27.25342178344727 12.67918109893799 26.41733169555664 14.69702053070068 24.89916229248047 16.21446990966797 L 15.20216178894043 25.91147041320801 C 14.82709121704102 26.28655052185059 14.31838130950928 26.49726104736328 13.78795146942139 26.49726104736328 C 13.2575216293335 26.49726104736328 12.74881172180176 26.28655052185059 12.37374114990234 25.91147041320801 L 2.676410675048828 16.21414184570312 C -0.4563579559326172 13.08137130737305 -0.4563694000244141 7.983970642089844 2.676401138305664 4.851190567016602 C 4.193981170654297 3.333610534667969 6.211702346801758 2.497840881347656 8.357881546020508 2.497840881347656 C 10.38759994506836 2.497840881347656 12.30235290527344 3.245304107666016 13.78789329528809 4.610126495361328 C 15.27305603027344 3.245044708251953 17.18797302246094 2.497261047363281 19.2180118560791 2.497261047363281 Z"
                          stroke="none" fill="#fff" />
                      </g>
                    </svg>
                  </div>
                  <div class="duration_box position-absolute">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                      <path
                        d="M6.99984 12.8332C10.2215 12.8332 12.8332 10.2215 12.8332 6.99984C12.8332 3.77818 10.2215 1.1665 6.99984 1.1665C3.77818 1.1665 1.1665 3.77818 1.1665 6.99984C1.1665 10.2215 3.77818 12.8332 6.99984 12.8332Z"
                        stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M9 9L7.39067 7.793C7.1406 7.60551 7.00008 7.35119 7 7.086V4" stroke="white" stroke-width="1.5"
                        stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span class="small ms-1">1:15:30</span>
                  </div>
                 
                  <div class="desBox d-flex position-absolute p-3 w-100">
                    <div class="description">
                      <p class="small color_pink mb-1">Bondage</p>
                      <h6 class="fs18 text-truncate mb-0 desHeading">Innocent looking...</h6>
                    </div>
                    <div class="price_count text-end">
                      <p class="fs13 mb-1 opacity-50 text-uppercase">JUN 15, 2020</p>
                      <h6 class="mb-0 d-flex align-items-center justify-content-end"><i class="me-2"><img
                            src="assets/images/coin.svg" alt=""></i>
                            10</h6>
                    </div>
                  </div>
              </div>
            </div>


            <div class="col-md-3 col-lg-3 mt-4">
              <div class="videoBox position-relative m-0">
              
                <div class="video_container position-relative latest_img">
                    <img src="assets/images/Vimg3.jpg" alt="" class="img-fluid"/>
                </div>
            
                  <div class="likeContainer d-flex align-items-center justify-content-center ms-auto me-3 cursor_pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="26.927" height="24" viewBox="0 0 26.927 24">
                      <g id="Icon_feather-heart" data-name="Icon feather-heart" transform="translate(-0.327 -2.497)"
                        fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path
                          d="M23.485,6.265a6.033,6.033,0,0,0-8.535,0L13.788,7.428,12.625,6.265A6.035,6.035,0,1,0,4.091,14.8l1.163,1.163L13.788,24.5l8.535-8.535L23.485,14.8a6.033,6.033,0,0,0,0-8.535Z"
                          stroke="none" />
                        <path
                          d="M 19.218017578125 4.497261047363281 C 17.67348861694336 4.497261047363281 16.12896156311035 5.086641311645508 14.95075130462646 6.265401840209961 L 13.78795146942139 7.428211212158203 L 12.62515163421631 6.265401840209961 C 10.26840209960938 3.908658981323242 6.447360992431641 3.908660888671875 4.090621948242188 6.265401840209961 C 1.733880996704102 8.622150421142578 1.733880996704102 12.443190574646 4.090621948242188 14.79993057250977 L 13.78795146942139 24.49726104736328 L 23.48528099060059 14.79993057250977 C 25.84280204772949 12.44351100921631 25.84280204772949 8.621820449829102 23.48528099060059 6.265401840209961 C 22.30707168579102 5.086641311645508 20.76254463195801 4.497261047363281 19.218017578125 4.497261047363281 M 19.2180118560791 2.497261047363281 C 21.36453247070312 2.497261047363281 23.38237190246582 3.333351135253906 24.89982223510742 4.851520538330078 C 26.41733169555664 6.368310928344727 27.25342178344727 8.386150360107422 27.25342178344727 10.53266143798828 C 27.25342178344727 12.67918109893799 26.41733169555664 14.69702053070068 24.89916229248047 16.21446990966797 L 15.20216178894043 25.91147041320801 C 14.82709121704102 26.28655052185059 14.31838130950928 26.49726104736328 13.78795146942139 26.49726104736328 C 13.2575216293335 26.49726104736328 12.74881172180176 26.28655052185059 12.37374114990234 25.91147041320801 L 2.676410675048828 16.21414184570312 C -0.4563579559326172 13.08137130737305 -0.4563694000244141 7.983970642089844 2.676401138305664 4.851190567016602 C 4.193981170654297 3.333610534667969 6.211702346801758 2.497840881347656 8.357881546020508 2.497840881347656 C 10.38759994506836 2.497840881347656 12.30235290527344 3.245304107666016 13.78789329528809 4.610126495361328 C 15.27305603027344 3.245044708251953 17.18797302246094 2.497261047363281 19.2180118560791 2.497261047363281 Z"
                          stroke="none" fill="#fff" />
                      </g>
                    </svg>
                  </div>
                  <div class="duration_box position-absolute">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                      <path
                        d="M6.99984 12.8332C10.2215 12.8332 12.8332 10.2215 12.8332 6.99984C12.8332 3.77818 10.2215 1.1665 6.99984 1.1665C3.77818 1.1665 1.1665 3.77818 1.1665 6.99984C1.1665 10.2215 3.77818 12.8332 6.99984 12.8332Z"
                        stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M9 9L7.39067 7.793C7.1406 7.60551 7.00008 7.35119 7 7.086V4" stroke="white" stroke-width="1.5"
                        stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span class="small ms-1">1:15:30</span>
                  </div>
                 
                  <div class="desBox d-flex position-absolute p-3 w-100">
                    <div class="description">
                      <p class="small color_pink mb-1">Bondage</p>
                      <h6 class="fs18 text-truncate mb-0 desHeading">Innocent looking...</h6>
                    </div>
                    <div class="price_count text-end">
                      <p class="fs13 mb-1 opacity-50 text-uppercase">JUN 15, 2020</p>
                      <h6 class="mb-0 d-flex align-items-center justify-content-end"><i class="me-2"><img
                            src="assets/images/coin.svg" alt=""></i>
                            10</h6>
                    </div>
                  </div>
              </div>
            </div>
            <div class="col-md-3 col-lg-3 mt-4">
              <div class="videoBox position-relative m-0">
              
                <div class="video_container position-relative latest_img">
                    <img src="assets/images/Vimg4.jpg" alt="" class="img-fluid"/>
                </div>
            
                  <div class="likeContainer d-flex align-items-center justify-content-center ms-auto me-3 cursor_pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" width="26.927" height="24" viewBox="0 0 26.927 24">
                      <g id="Icon_feather-heart" data-name="Icon feather-heart" transform="translate(-0.327 -2.497)"
                        fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path
                          d="M23.485,6.265a6.033,6.033,0,0,0-8.535,0L13.788,7.428,12.625,6.265A6.035,6.035,0,1,0,4.091,14.8l1.163,1.163L13.788,24.5l8.535-8.535L23.485,14.8a6.033,6.033,0,0,0,0-8.535Z"
                          stroke="none" />
                        <path
                          d="M 19.218017578125 4.497261047363281 C 17.67348861694336 4.497261047363281 16.12896156311035 5.086641311645508 14.95075130462646 6.265401840209961 L 13.78795146942139 7.428211212158203 L 12.62515163421631 6.265401840209961 C 10.26840209960938 3.908658981323242 6.447360992431641 3.908660888671875 4.090621948242188 6.265401840209961 C 1.733880996704102 8.622150421142578 1.733880996704102 12.443190574646 4.090621948242188 14.79993057250977 L 13.78795146942139 24.49726104736328 L 23.48528099060059 14.79993057250977 C 25.84280204772949 12.44351100921631 25.84280204772949 8.621820449829102 23.48528099060059 6.265401840209961 C 22.30707168579102 5.086641311645508 20.76254463195801 4.497261047363281 19.218017578125 4.497261047363281 M 19.2180118560791 2.497261047363281 C 21.36453247070312 2.497261047363281 23.38237190246582 3.333351135253906 24.89982223510742 4.851520538330078 C 26.41733169555664 6.368310928344727 27.25342178344727 8.386150360107422 27.25342178344727 10.53266143798828 C 27.25342178344727 12.67918109893799 26.41733169555664 14.69702053070068 24.89916229248047 16.21446990966797 L 15.20216178894043 25.91147041320801 C 14.82709121704102 26.28655052185059 14.31838130950928 26.49726104736328 13.78795146942139 26.49726104736328 C 13.2575216293335 26.49726104736328 12.74881172180176 26.28655052185059 12.37374114990234 25.91147041320801 L 2.676410675048828 16.21414184570312 C -0.4563579559326172 13.08137130737305 -0.4563694000244141 7.983970642089844 2.676401138305664 4.851190567016602 C 4.193981170654297 3.333610534667969 6.211702346801758 2.497840881347656 8.357881546020508 2.497840881347656 C 10.38759994506836 2.497840881347656 12.30235290527344 3.245304107666016 13.78789329528809 4.610126495361328 C 15.27305603027344 3.245044708251953 17.18797302246094 2.497261047363281 19.2180118560791 2.497261047363281 Z"
                          stroke="none" fill="#fff" />
                      </g>
                    </svg>
                  </div>
                  <div class="duration_box position-absolute">
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                      <path
                        d="M6.99984 12.8332C10.2215 12.8332 12.8332 10.2215 12.8332 6.99984C12.8332 3.77818 10.2215 1.1665 6.99984 1.1665C3.77818 1.1665 1.1665 3.77818 1.1665 6.99984C1.1665 10.2215 3.77818 12.8332 6.99984 12.8332Z"
                        stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M9 9L7.39067 7.793C7.1406 7.60551 7.00008 7.35119 7 7.086V4" stroke="white" stroke-width="1.5"
                        stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <span class="small ms-1">1:15:30</span>
                  </div>
                 
                  <div class="desBox d-flex position-absolute p-3 w-100">
                    <div class="description">
                      <p class="small color_pink mb-1">Bondage</p>
                      <h6 class="fs18 text-truncate mb-0 desHeading">Innocent looking...</h6>
                    </div>
                    <div class="price_count text-end">
                      <p class="fs13 mb-1 opacity-50 text-uppercase">JUN 15, 2020</p>
                      <h6 class="mb-0 d-flex align-items-center justify-content-end"><i class="me-2"><img
                            src="assets/images/coin.svg" alt=""></i>
                            10</h6>
                    </div>
                  </div>
              </div>
            </div> -->

    </div>
    <div *ngIf="latestReleases?.length==0" class="no_data_found text-center">
      <h1>No Data found</h1>
    </div>

    <!-- <div class="mt-5 w-25 mx-auto">
      <button _ngcontent-ght-c131="" role="button" type="button"
        class="btn w-75 purple_btn_outline btn70 fw-bold mx-auto fs20 mt-5">Load More</button>
    </div> -->

  </div>
</section>

<!-- <section class="studio_sec sec_padding">
  <div class="container-fluid">
    <div class="center_heading text-center mb-4 position-relative">
      <h3 class="ff_kalam fw-regular display-4">Studios</h3>
    </div>
    <div class="studio_container position-relative">
      <div class="studio_slider">
        <div class="studioBox color_dk_greyBG p-3 m-2" [routerLink]="['/studios']" *ngFor="let studio of studios">
          <img class="w-100 h-100" [src]="studioUrl + studio.logo" alt="">
        </div>

       
      </div>

      <div class="arrowSmall position-absolute w-100">
        <ul class="list-unstyled d-flex align-items-center justify-content-between m-0">
          <li class="prev"></li>
          <li class="next"></li>
        </ul>
      </div>

    </div>

  </div>
</section> -->


<app-footer></app-footer>