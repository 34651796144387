<app-header></app-header>

<section class="breadcrumb-banner">
    <div class="container">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
              <li class="breadcrumb-item ps-0 active" [routerLink]="['/home']">Home</li>
              <li class="breadcrumb-item active" [routerLink]="['/my-profile']">My Profile</li>
              <li class="breadcrumb-item">Edit Email</li>
            </ol>
        </nav>
    </div>
</section>

<section class="edit-cmn-sec email pt-4 sec_padding_bottom">
    <div class="container">
        <div class="content-sec mx-auto text-center">
            <div class="img-wrap mb-5">
                <img src="assets/images/edit-email-img.svg">
            </div>
            <h4 class="fw-medium mb-4 pb-3">Edit Your Email Address</h4>
            <form [formGroup]="editEmail">
                <div class="mb-4 pb-3">
                    <label class="form-label text-start w-100 fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Email Address</label>
                    <input type="email" class="form-control fs18 cmn-input input_60 ff_Roboto" (keydown)="checkEvent($event)" formControlName="email" placeholder="Enter email address">
                </div>
                <button class="btn w-100 purple_btn btn70 fw-bold" [routerLink]="['/verify-email']">Verify</button>
                <button class="btn go-back-btn fw-bold text-white shadow-none" [routerLink]="['/my-profile']"><img class="me-3" src="assets/images/gobackbtn.svg">Go Back</button>
            </form>
        </div>
    </div>
</section>

<app-footer></app-footer>

