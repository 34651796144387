import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import { IVideoConfig } from "ngx-video-list-player";

@Component({
  selector: 'app-landing-page-video',
  templateUrl: './landing-page-video.component.html',
  styleUrls: ['./landing-page-video.component.scss']
})
export class LandingPageVideoComponent implements OnInit {
  config: IVideoConfig = {
    sources: [
    {
      src: 'assets/videos/demovedio.mp4',
    },
    {
      src: 'assets/videos/demovedio1.mp4',
    }]          
};

  constructor(public dialogRef: MatDialogRef<LandingPageVideoComponent>) { }

  ngOnInit(): void {
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
