import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/common/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/message/messagehandling.service';

@Component({
  selector: 'app-add-card',
  templateUrl: './add-card.component.html',
  styleUrls: ['./add-card.component.scss']
})
export class AddCardComponent implements OnInit {
  addCardForm!: FormGroup
  constructor(private fb: FormBuilder, private api: ApiService, private message: MessagehandlingService, private router: Router) {
    this.addCardForm = this.fb.group({
      cardNumber: '',
      expDate: '',
      cardHolderName: '',
      // cvv: '',
      address: '',
      city: '',
      state: '',
      country: '',
      zipcode: ''
    })
  }
  ngOnInit(): void {
  }

  numberOnly(event: any): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }

  checkEvent(event: any) {
    if (event.target.value == '' && event.which == 32) {
      event.preventDefault();
    }
  }

  saveDetails() {
    // if (this.addCardForm.value.cardNumber == '' || this.addCardForm.value.expDate == '' || this.addCardForm.value.cardHolderName == '') {
    //   this.message.errorMessage('please fill all details', 2000)
    // }
    // else {
    //   let fd = new FormData
    //   fd.append('cardNumber', this.addCardForm.value.cardNumber)
    //   fd.append('expDate', this.addCardForm.value.expDate)
    //   fd.append('cardHolderName', this.addCardForm.value.cardHolderName)
    if (this.addCardForm.value.cardNumber == '' || this.addCardForm.value.expDate == ''
      || this.addCardForm.value.cardHolderName == ''
      || this.addCardForm.value.address == '' || this.addCardForm.value.city == '' || this.addCardForm.value.state == '' || this.addCardForm.value.zipcode == '' || this.addCardForm.value.country == '') {
      this.message.errorMessage('please fill all details', 2000)
    }
    else {
      let fd = new FormData
      fd.append('cardNumber', this.addCardForm.value.cardNumber)
      fd.append('expDate', this.addCardForm.value.expDate)
      fd.append('cardHolderName', this.addCardForm.value.cardHolderName)
      fd.append('cvv', this.addCardForm.value.cvv)
      fd.append('address', this.addCardForm.value.address)
      fd.append('city', this.addCardForm.value.city)
      fd.append('state', this.addCardForm.value.state)
      fd.append('zipcode', this.addCardForm.value.zipcode)
      fd.append('country', this.addCardForm.value.country)
      this.api.addCard(fd).subscribe((res: any) => {
        this.message.sucessMessage(res.message, 2000)
        this.router.navigate(['/add-token'])
      })
    }
  }

}
