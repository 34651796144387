import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/common/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/message/messagehandling.service';
declare const $: any;
@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss']
})
export class CategoryListComponent implements OnInit {
  id: any
  clipList: any;
  userImage: any;
  clipname: any;
  constructor(private api: ApiService, private router: Router, private activeroute: ActivatedRoute, private message:MessagehandlingService) {
    this.activeroute.params.subscribe((params: any) => {
      this.id = params['id'];
      if (this.id) {
        this.categoryBasedOnClip(this.id)
        // need to change 
      } else {
        this.router.navigate(['/']);
      }
    })
  }

  ngOnInit(): void {
    this.userImage = this.api.clipsImageUrl
  }

 

  fav(id: any, isFav: any) {
    
    let fd = new FormData
   
    fd.append('CID', id)
    if (isFav==1) {
      fd.append('status', "0")
      $(".likeContainer").toggleClass("heart_filled");
    }
    else {
      fd.append('status', "1")

      $(".likeContainer").removeClass("heart_filled");

    }
    this.api.addFavourite(fd).subscribe((res: any) => {
      this.message.sucessMessage(res.message, 1000)
       this.categoryBasedOnClip(this.id)

    })
    // $(".likeContainer").toggleClass("heart_filled");
  }

  clipDetail(data: any) {
    // [routerLink]="['/video-detail']"
    this.router.navigate([`/video-detail/${data.id}`])
  }

  categoryBasedOnClip(id: any) {
    let fd = new FormData
    fd.append('id', id)
    fd.append('pageno', '0')
    this.api.clipsBasedOnCategory(fd).subscribe((res: any) => {
      this.clipList = res.data
      // setTimeout(() => {
        this.clipname = res.data[0].name
      // }, 1000);
    })

  }
}
