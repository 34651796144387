import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from 'src/app/common/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/message/messagehandling.service';
import { DeleteModelComponent } from '../model/delete-model/delete-model.component';
import { ImageCropperComponent } from '../image-cropper/image-cropper.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-edit-model',
  templateUrl: './edit-model.component.html',
  styleUrls: ['./edit-model.component.scss']
})
export class EditModelComponent implements OnInit {
  gendervalue: any = "Gender"
  editModal: FormGroup
  value: any;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  modelData: any[] = [];
  profile: any;
  licenceData: any;
  availCustom: boolean = false
  profileImage: any;
  Id: any
  constructor(private route: ActivatedRoute,private dialog: MatDialog, private api: ApiService, private router: Router, private message: MessagehandlingService) {
    this.route.params.subscribe((res: any) => {
      (!res)
        ? this.router.navigate(['/']) :
        this.getDetail(res)
    })

    this.editModal = new FormGroup({
      username: new FormControl(''),
      firstname: new FormControl(''),
      profileImage: new FormControl(''),
      tags: new FormControl(''),
      bio: new FormControl(''),
      gendervalue: new FormControl(''),
      availCustom: new FormControl(''),

    })
  }

  ngOnInit(): void {

  }
  genderClass(event: any) {
    this.gendervalue = event;
  }


  checkEvent(event: any) {
    if (event.target.value == '' && event.which == 32) {
      event.preventDefault();
    }
  }
  getDetail(id: any) {
    this.Id = sessionStorage.getItem('id')
    this.api.getModelDetail(this.Id).subscribe((res: any) => {
      this.editModal.patchValue({
        username: res.data.username,
        firstname: res.data.name,
        // tags: '',
        bio: res.data.bio,
        gendervalue: res.data.gender,
        availCustom: res.data.isCustom

      })
      let tags = res.data.tags
      tags.forEach((element: any, index: any) => {
        if (element.name == tags[index].name) {
          this.modelData.push(element)
        }
      });
      if (res.data.profile == '' || res.data.profile == null || res.data.profile == undefined) {
        this.profileImage = '';
      }
      else {
        setTimeout(() => {
          this.profileImage = this.api.modelImageUrl + res.data.profile
        }, 1000);
      }

    })
  }


  customChange(event: any) {
    console.log(this.availCustom, event)
  }


  add(event: MatChipInputEvent): void {
    const input = event.input;
    this.value = event.value;
    if ((this.value || '').trim()) {
      this.modelData.push({ id: "0", name: this.value.trim() });
    }
    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(model: any): void {
    const index = this.modelData.indexOf(model);
    if (index >= 0) {
      this.modelData.splice(index, 1);
      this.api.deleteTag(model.id).subscribe((ele: any) => {
        this.message.sucessMessage(ele.message, 2000)
      })
    }
  }

  updateModelData() {
    if (this.editModal.value.username === '' || this.editModal.value.firstname === '' || this.editModal.value.gendervalue === '' || this.editModal.value.gendervalue === 'Gender' || this.profileImage === '') {
      alert("Please add Image and Enter Name , Username and Gender. These fields are mandatory ")
    } else {
      let fd = new FormData
      fd.append('id', this.Id)
      if (this.profile == undefined || this.profile == null) {
        this.profile = ''
      }
      fd.append('profile', this.profile)
      fd.append('username', this.editModal.value.username)
      fd.append('name', this.editModal.value.firstname)
      fd.append('bio', this.editModal.value.bio)
      fd.append('gender', this.editModal.value.gendervalue)
      fd.append('isCustom', this.editModal.value.availCustom == false ? '0' : '1')
      fd.append('tags', JSON.stringify(this.modelData))
      this.api.updateModelDetail(fd).subscribe((ele: any) => {
         this.message.sucessMessage(ele.message, 2000)
        this.router.navigate(['/studio-models'])

      })
    }
  }

  removeImage() {
    this.profileImage = ''
  }

  // onFileChange(event: any): void {
  //   // if (event.target.files && event.target.files[0]) {
  //   //   const file = event.target.files[0];
  //   //   let reader = new FileReader();
  //   //   reader.onload = e => {
  //   //     this.profileImage = reader.result
  //   //   }
  //   //   reader.readAsDataURL(file);
  //   //   this.profile = file
  //   // }
    
  // }
  onFileChange(event: any, type: any): void {
    let dialogRef = this.dialog.open(ImageCropperComponent, {
      data: {
        imageUrl: event,
        type: type
      },
      panelClass: ['cmn_modal', 'img_croper'],
    });

    setTimeout(() => {
      event.target.value = '';
    }, 0);

    dialogRef.afterClosed().subscribe((res: any) => {
      if (res ) {
        console.log('Cropped Image Data:', res);
        this.profile = this.convertBase64ToFile(res.data);
        this.profileImage = res.data;
      } else {
        console.error('No data returned from the dialog');
      }
    });
  }

  convertBase64ToFile(base64Data: string) {
    const base64Image = base64Data.replace(/^data:image\/[a-z]+;base64,/, "");
    const byteCharacters = atob(base64Image);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'image/png' });
    const timestamp: any = Date.now();
    const randomNumber = Math.floor(Math.random() * 10000);
    const file = new File([blob], timestamp + '.png', { type: blob.type });
    return file;
  }


}
