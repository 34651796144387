import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-token-added',
  templateUrl: './token-added.component.html',
  styleUrls: ['./token-added.component.scss']
})
export class TokenAddedComponent implements OnInit {
  token: any
  constructor(private router:Router) { }

  ngOnInit(): void {
    this.token=sessionStorage.getItem('amount')
  }

  cart() {
      this.router.navigate(['/cart'])
    // window.location.href = 'https://chokechamberangular.harishparas.com/#/cart';
  }
    // http://chokechamberangular.harishparas.com/#/cart
    // this.router.navigate(['/cart'])
  

}
