import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ApiService } from 'src/app/common/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/message/messagehandling.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  memberForm: FormGroup
  theDate: any
  constructor(private formBuilder: FormBuilder, private router: Router, private api: ApiService, private message: MessagehandlingService) {

    this.memberForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, this.emailValidator.bind(this)]],
      password: ['', [Validators.required]],
      dob: ['', [Validators.required]],

      confirmPassword: [''],
      acceptCheckbox: ['', [Validators.required]]
    }, { validator: this.checkPasswords })

  }

  ngOnInit(): void {

  }


  emailValidator(control: FormControl) {
    if (!(control.value.toLowerCase().match('^[a-z0-9]+(\.[_a-z0-9]+)+(\.[a-z0-9-]+)+([.{1}])(\.[a-z]{1,15})$'))) {
      return { invalidEmail: true };
    }
    return
  }


  checkEvent(event: any) {
    if (event.target.value == '' && event.which == 32) {
      event.preventDefault();
    }
  }


  checkPasswords(control: FormControl) {
    const pass = control.get('password')?.value
    const confirmPass = control.get('confirmPassword')?.value
    if (pass != confirmPass) {
      control.get('confirmPassword')?.setErrors({ ConfirmPassword: true });
    } else {
      return null;
    }
    return
  }



  becomeMember() {
    if (!this.memberForm.value.acceptCheckbox)
      return this.message.errorMessage('Please agree with terms and conditions', 2000)
    var dob = new Date(this.memberForm.value.dob);
    var month_diff = Date.now() - dob.getTime();
    var age_dt = new Date(month_diff);
    var year = age_dt.getUTCFullYear();
    var age = Math.abs(year - 1970);
    if (age >= 18) {
      const dob = moment(this.memberForm.value.dob, 'MM-DD-YYYY'); // Assuming input is in 'dd-MM-yyyy' format
      const formattedDob = dob.format('YYYY-MM-DD');

      let formData = new FormData
      formData.append('name', this.memberForm.value.name)
      formData.append('email', this.memberForm.value.email)
      formData.append('password', this.memberForm.value.password)
      formData.append('dob', formattedDob)
      formData.append('deviceType', 'web')
      this.api.registerUser(formData).subscribe((res: any) => {
        this.message.sucessMessage(res.message, 2000)
        localStorage.setItem('studioToken', res.data.token)
        sessionStorage.setItem('membername', res.data.name)
        sessionStorage.setItem('emailUser', this.memberForm.value.email)
        sessionStorage.setItem('studioDetail', JSON.stringify(res.data))
        this.router.navigate(['/verify-email'])
      })
    } else {
      this.message.errorMessage(`You are atleast 18 years old.`, 2000)
    }



  }

  termsConditions() {
    this.router.navigate(['/terms-condition'])

  }

}
