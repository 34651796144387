<app-header></app-header>

<section class="breadcrumb-banner">
    <div class="container">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item ps-0 active" [routerLink]="['/home']">Home</li>
                <li class="breadcrumb-item">My Profile</li>
            </ol>
        </nav>
    </div>
</section>

<section class="my-profile-sec sec_padding_bottom">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="black-card">
                    <div class="user-details p-4 mb-3">
                        <h3 class="fs18 text-uppercase fw-medium mb-4">User DETAILS</h3>
                        <div class="info-row d-flex align-items-end justify-content-between mb-4 pb-2">
                            <div class="text-sec">
                                <h6 class="small color_grey fw-normal mb-2">Name</h6>
                                <p class="mb-0">{{profileData?.name}}</p>
                            </div>
                            <button class="btn grey_btn small fw-medium shadow-none px-3 py-2 lh1"
                                [routerLink]="['/edit-name']">Edit</button>
                        </div>

                        <div class="info-row d-flex align-items-end justify-content-between mb-4 pb-2">
                            <div class="text-sec">
                                <h6 class="small color_grey fw-normal mb-2">Email</h6>
                                <p class="mb-0">{{profileData?.email}}</p>
                            </div>
                        </div>

                        <div class="info-row d-flex align-items-end justify-content-between mb-3">
                            <div class="text-sec">
                                <h6 class="small color_grey fw-normal mb-2">Password</h6>
                                <p class="mb-0">*******</p>
                            </div>
                            <button class="btn grey_btn small fw-medium shadow-none px-3 py-2 lh1"
                                [routerLink]="['/change-password']">Change</button>
                        </div>
                    </div>
                    <!-- <div class="payment-details p-4" *ngIf="subsDetail.length!=0">
                        <h3 class="fs18 text-uppercase fw-medium mb-4">PLAN DETAILS</h3>
                        <h4 class="color_grey fs18 fw-normal d-flex align-items-center justify-content-between mb-4 ">
                            <span>Monthly</span><span class="fw-bold fw-bold">${{subsDetail?.amount}}</span>
                        </h4>

                        <div class="info-row d-flex align-items-end justify-content-between">
                            <div class="no-sec d-flex align-items-center justify-content-center">
                                <div class="card-type d-flex align-items-center justify-content-center p-1 me-3">
                                    <img class="img-fluid" src="assets/images/visaimg.svg">
                                </div>
                                <p class="mb-0 lh1">**** **** ****
                                    {{subsDetail?.cardNumber.slice(12, 16)}}</p>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>

            <!-- <div class="col-md-5">
                <div class="black-card p-4 mb-4">
                    <div class="balance d-flex align-items-center mb-3 py-2">
                        <div class="img-wrap me-4">
                            <img class="img-fluid" src="assets/images/coins.svg">
                        </div>
                        <div class="text-sec">
                            <p class="color_grey mb-3 lh1">Balance</p>
                            <h3 class="fw-regular mb-0">{{profileData?.totalToken}} Tokens</h3>
                        </div>

                        <div class="ms-auto"><span class="color_red ps-2 fw-bold ng-star-inserted cursor_p"
                                [routerLink]="['/token-list']">View Detail</span></div>

                    </div>
                    <button
                        class="btn grey_btn rounded-0 w-100 btn-lg shadow-none d-flex align-items-center justify-content-center fw-bold"
                        (click)="redirectToUrl('https://pay.chokechamber.com/#/add-token')">
                        <svg class="me-3" width="22" height="22" viewBox="0 0 22 22" fill="none">
                            <g clip-path="url(#clip0_719_1699)">
                                <path
                                    d="M11.9377 11.8772L11.9377 20.5398C11.9214 20.7508 11.826 20.9478 11.6707 21.0916C11.5154 21.2354 11.3116 21.3152 11.0999 21.3152C10.8883 21.3152 10.6845 21.2354 10.5292 21.0916C10.3739 20.9478 10.2785 20.7508 10.2622 20.5398L10.2622 11.8772L1.59364 11.8831C1.38265 11.8668 1.18558 11.7714 1.04182 11.6161C0.898068 11.4608 0.81821 11.257 0.81821 11.0454C0.81821 10.8337 0.898067 10.6299 1.04182 10.4746C1.18558 10.3193 1.38265 10.2239 1.59364 10.2076L10.2622 10.2017L10.2563 1.53313C10.2473 1.41767 10.2624 1.30161 10.3004 1.19224C10.3385 1.08286 10.3987 0.982533 10.4774 0.897547C10.556 0.812562 10.6514 0.744754 10.7575 0.698381C10.8636 0.652007 10.9782 0.62807 11.094 0.62807C11.2098 0.62807 11.3244 0.652008 11.4305 0.698382C11.5366 0.744755 11.632 0.812563 11.7106 0.897548C11.7893 0.982533 11.8495 1.08286 11.8876 1.19224C11.9256 1.30161 11.9407 1.41767 11.9317 1.53313L11.9377 10.2017H20.6003C20.8113 10.218 21.0084 10.3133 21.1521 10.4687C21.2959 10.624 21.3757 10.8278 21.3757 11.0394C21.3757 11.2511 21.2959 11.4549 21.1521 11.6102C21.0084 11.7655 20.8113 11.8608 20.6003 11.8772L11.9377 11.8772Z"
                                    fill="white" />
                            </g>
                            <defs>
                                <clipPath id="clip0_719_1699">
                                    <rect width="15.5565" height="15.5565" fill="white"
                                        transform="matrix(0.707107 0.707107 0.707107 -0.707107 0 11)" />
                                </clipPath>
                            </defs>
                        </svg>
                        Add More Tokens
                    </button>
                </div>

                <div class="black-card p-4" *ngIf="subsDetail.length!=0">
                    <h3 class="fs18 text-uppercase fw-medium mb-4">Membership details</h3>
                    <div class="d-flex mb-5 pb-1">
                        <div class="left-sec me-5">
                            <p class="fs18 fw-normal color_grey mb-2">Purchase date</p>
                            <h6 class="fs18 fw-bold mb-0">{{subsDetail?.startDate | date}}</h6>
                        </div>
                        <div class="right-sec">
                            <p class="fs18 fw-normal color_grey mb-2">Next billing date</p>
                            <h6 class="fs18 fw-bold mb-0">{{subsDetail?.nextDate| date}}</h6>
                        </div>
                    </div>
                    <button class="btn grey_btn rounded-0 w-100 btn-lg shadow-none fw-bold" (click)="cancelMemberShip()">
                        Cancel Membership
                    </button>
                </div>
            </div> -->
        </div>
    </div>
</section>

<app-footer></app-footer>