import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { ClipsComponent } from './components/clips/clips.component';
import { ModelsComponent } from './components/models/models.component';
import { ClipDetailsComponent } from './components/clip-details/clip-details.component';
import { AddClipComponent } from './components/add-clip/add-clip.component';
import { AddModelComponent } from './components/add-model/add-model.component';
import { ModelDetailsComponent } from './components/model-details/model-details.component';
import { RevenueHistoryDetailsComponent } from './components/revenue-history-details/revenue-history-details.component';
import { CompleteProfileComponent } from './components/complete-profile/complete-profile.component';
import { MyProfileComponent } from './components/my-profile/my-profile.component';
import { EditClipComponent } from './components/edit-clip/edit-clip.component';
import { EditModelComponent } from './components/edit-model/edit-model.component';
import { EditProfileComponent } from './components/edit-profile/edit-profile.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { ChangeAddressComponent } from './components/change-address/change-address.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { StudioVerifyOtpComponent } from './components/studio-verify-otp/studio-verify-otp.component';



const routes: Routes = [
  { path: 'studio-login', component: LoginComponent },
  { path: 'studio-home', component: HomeComponent },
  { path: 'studio-clips', component: ClipsComponent },
  { path: 'studio-models', component: ModelsComponent },
  { path: 'studio-clip-details/:id', component: ClipDetailsComponent },
  { path: 'studio-add-clip', component: AddClipComponent },
  { path: 'studio-add-model', component: AddModelComponent },
  { path: 'studio-model-details/:id', component: ModelDetailsComponent },
  { path: 'studio-revenue-details', component: RevenueHistoryDetailsComponent },
  { path: 'studio-complete-profile', component: CompleteProfileComponent },
  { path: 'studio-my-profile', component: MyProfileComponent },
  { path: 'studio-edit-clips/:id', component: EditClipComponent },
  { path: 'studio-edit-model/:id', component: EditModelComponent },
  { path: 'studio-edit-profile', component: EditProfileComponent },
  { path: 'studio-change-password', component: ChangePasswordComponent },
  { path: 'studio-change-address', component: ChangeAddressComponent },
  { path: 'studio-forgot-password', component: ForgotPasswordComponent },
  { path: 'studio-reset-password', component: ResetPasswordComponent },
  { path: 'studio-verify-otp', component: StudioVerifyOtpComponent },

  
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: false})],
  exports: [RouterModule]
})
export class StudioRoutingModule { } 