<app-header></app-header>

<section class="my-profile py-5">
    <div class="container">
        <button class="backbtn" [routerLink]="['/studio-my-profile']"><img src="assets/images/studiomodule/backbtn.svg"></button>
        <div class="row justify-content-center">
            <div class="col-md-7">
                <div class="form-sec">
                    <h4 class="mb-4">Change password</h4>
                    <form>
                        <div class="inner-sec ">
                            <div class="mb-4 pb-1">
                                <label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Old Password</label>
                                <input type="password" class="form-control fs18 cmn-input input_60 ff_Roboto" placeholder="Old Password" value="123456789">
                             </div>

                             <div class="mb-4 pb-1">
                                <label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">New Password</label>
                                <input type="password" class="form-control fs18 cmn-input input_60 ff_Roboto" placeholder="New Password" value="123456789">
                             </div>
                             
                             <div class="mb-4 pb-1">
                                <label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Confirm Password</label>
                                <input type="password" class="form-control fs18 cmn-input input_60 ff_Roboto" placeholder="Confirm Password">
                             </div>
                             

                            <div class="text-center mt-5">
                                <button class="btn  purple_btn btn70 fw-bold px-5">Change password</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>

<app-studio-footer> </app-studio-footer> 