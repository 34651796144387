<div mat-dialog-content class="p-0">
    <div class="content_sec text-center">
        <img src="assets/images/studiomodule/delete_model.svg" alt="" class="img-fluid mb-3">
        <h3>Are you sure!</h3>
        <p class="fw-light">You want to delete this Model?</p>
    </div>
</div>
<div mat-dialog-actions class="d-flex align-items-center gap-4 pb-4 pt-4 justify-content-center">
    <button class="btn dark_btn btn60 shadow-none" mat-dialog-close (click)="cancel()">Cancel</button>
    <button class="btn pink_btn shadow-none btn60 shadow-none" (click)="delete()">Delete</button>
</div>