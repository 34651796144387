<section class="login-sec d-flex align-items-center justify-content-center p-4">
    <div class="content-box">
        <div class="top-sec d-block lg-d-flex align-items-center justify-content-between mb-4">
            <div class="top-sec d-flex align-items-center mb-4">
                <button class="backbtn" [routerLink]="['/landingpage']">
                    <svg width="34" height="24" viewBox="0 0 34 24" fill="none">
                        <path d="M32 12L3.42857 12" stroke="white" stroke-width="3" stroke-linecap="round" />
                        <path d="M10.5714 2L2 12L10.5714 22" stroke="white" stroke-width="3" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
                </button>
                <h2 class="ff_kalam font-normal text-white mb-0">Create Profile</h2>
            </div>
            <div class="right-sec">
                <p class="opacity-50 mb-2 fw-normal">Already have an account?</p>
                <h5 role="button" class="fs18 fw-medium mb-0 d-flex align-items-center"
                    [routerLink]="['/studio-login']">
                    <span class="pe-2">Login</span>
                     <svg class="ms-1" width="20" height="14" viewBox="0 0 20 14"
                        fill="none">
                        <path d="M1 7L18.1429 7" stroke="white" stroke-width="2" stroke-linecap="round" />
                        <path d="M13.8571 13L19 7L13.8571 1" stroke="white" stroke-width="2" stroke-linecap="round"
                            stroke-linejoin="round" />
                    </svg>
                </h5>
            </div>
        </div>
        <mat-stepper #stepper class="bg-transparent">
            <form [formGroup]="createProfileForm">

                <mat-step>
                    <ng-template matStepLabel>Personal Information</ng-template>
                    <div class="row mt-4">
                       
                        <div class="col-md-6">
                            <div class="mb-0 pb-1">
                                <label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Studio
                                    Name</label>
                                <input type="text" formControlName="name" (keydown)="checkEvent($event)"
                                    class="form-control fs18 cmn-input input_60 ff_Roboto"
                                    placeholder="Enter studio name">
                            </div>
                            <small *ngIf="!createProfileForm.controls?.['name']?.valid &&
                            createProfileForm.controls?.['name']?.dirty" class="error_main_div">
                                <small *ngIf="createProfileForm.controls?.['name']?.hasError('required')"
                                    class="error_message">This field is
                                    Mandatory !</small>
                            </small>
                        </div>
                        <div class="col-md-6">
                            <div class="mb-0 pb-1">
                                <label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto ">Studio
                                    Description</label>
                                <input type="text" formControlName="description"  (keydown)="checkEvent($event)"
                                    class="form-control fs18 cmn-input input_60 ff_Roboto"
                                    placeholder="Enter studio description">
                                <!-- Use no space & special chars -->
                            </div>
                            <small *ngIf="!createProfileForm.controls?.['description']?.valid &&
                            createProfileForm.controls?.['description']?.dirty" class="error_main_div">
                                <div *ngIf="createProfileForm.controls?.['description']?.hasError('required')"
                                    class="error_message">This field is
                                    Mandatory !</div>
                            </small>
                        </div>
                        <div class="col-md-6">
                            <div class="mt-4 pb-1">
                                <label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Phone
                                    Number</label>
                                <input type="number" formControlName="phoneNumber" maxlength="10"
                                    onKeyPress="if(this.value.length==10) return false;"
                                    class="form-control fs18 cmn-input input_60 ff_Roboto"
                                    placeholder="Enter phone number">
                            </div>

                            <small *ngIf="!createProfileForm.controls?.['phoneNumber']?.valid &&
                            createProfileForm.controls?.['phoneNumber']?.dirty" class="error_main_div">
                                <small *ngIf="createProfileForm.controls?.['phoneNumber']?.hasError('required')"
                                    class="error_message">This field is
                                    Mandatory !
                                </small>
                            </small>
                        </div>
                        <div class="col-md-6">
                            <div class="mt-4 pb-1">
                                <label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Email
                                    Address</label>
                                <input type="email" formControlName="email" (keydown)="checkEvent($event)"
                                    class="form-control fs18 cmn-input input_60 ff_Roboto"
                                    placeholder="example@gmail.com">
                            </div>
                            <small *ngIf="!createProfileForm.controls?.['email']?.valid &&
                            createProfileForm.controls?.['email']?.dirty">
                                <small
                                    *ngIf="createProfileForm.controls?.['email']?.hasError('invalidEmail') && createProfileForm.controls?.['email']?.value"
                                    class="error_message">Invalid email !
                                </small>


                                <small class="error_message"
                                    *ngIf="createProfileForm.controls?.['email']?.hasError('required')">This field is
                                    Mandatory !
                                </small>
                            </small>
                        </div>


                        <div class="col-md-6">
                            <div class="mt-4 pb-1">
                                <label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Password</label>
                                <input type="password" formControlName="password" 
                                    (keydown)="checkEvent($event)"
                                    class="form-control fs18 cmn-input input_60 ff_Roboto" placeholder="Enter password">
                            </div>
                            <small *ngIf="!createProfileForm.controls?.['password']?.valid &&
                            createProfileForm.controls?.['password']?.dirty" class="error_main_div">
                                <small *ngIf="createProfileForm.controls?.['password']?.hasError('required')"
                                    class="error_message">This field
                                    is
                                    Mandatory !
                                </small>
                            </small>

                        </div>
                        <div class="col-md-6">
                            <div class="mt-4">
                                <label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Confirm
                                    Password</label>
                                <input type="password" formControlName="confirmPassword"
                                    (keydown)="checkEvent($event)"
                                    class="form-control fs18 cmn-input input_60 ff_Roboto"
                                    placeholder="Enter confirm password">
                            </div>
                            <small *ngIf="!createProfileForm.controls?.['confirmPassword']?.valid &&
                            createProfileForm.controls?.['confirmPassword']?.dirty" class="error_main_div">
                                <small
                                    *ngIf="createProfileForm.controls?.['confirmPassword']?.invalid && createProfileForm.controls?.['confirmPassword']?.errors?.['ConfirmPassword']"
                                    class="error_message">Password and confirm password is not match !</small>
                                <small *ngIf="createProfileForm.controls?.['confirmPassword']?.hasError('required')"
                                    class="error_message">This field
                                    is Mandatory !
                                </small>
                            </small>
                        </div>
                    </div>
                    <div class=" mt-5 d-flex justify-content-center">
                        <button mat-button matStepperNext
                            class="purple_btn btn70 fw-bold d-flex align-items-center justify-content-center btn px-5">Next</button>
                    </div>
                    <!-- </form> -->
                </mat-step>
                <mat-step>
                    <!-- <form [formGroup]="secondFormGroup"> -->
                    <ng-template matStepLabel>Address</ng-template>
                    <div class="row mt-4">
                        <div class="col-md-6">
                            <div class="mb-0 pb-1">
                                <label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">SSN/EIN
                                    Number</label>
                                <input type="number" formControlName="ssn" (keydown)="checkEvent($event)"
                                    class="form-control fs18 cmn-input input_60 ff_Roboto"
                                    placeholder="Enter SSN/EIN  Number">
                            </div>
                            <small *ngIf="!createProfileForm.controls?.['ssn']?.valid &&
                            createProfileForm.controls?.['ssn']?.dirty" class="error_main_div">
                                <small *ngIf="createProfileForm.controls?.['ssn']?.hasError('required')"
                                    class="error_message">This field is
                                    Mandatory !
                                </small>
                            </small>
                        </div>
                        <div class="col-md-6">
                            <div class="mb-0 pb-1">
                                <label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Legal Business Name</label>
                                <input type="tesxt" formControlName="fax" (keydown)="checkEvent($event)"
                                    class="form-control fs18 cmn-input input_60 ff_Roboto" placeholder="Enter Legal Business Name">
                            </div>
                            <small *ngIf="!createProfileForm.controls?.['fax']?.valid &&
                            createProfileForm.controls?.['fax']?.dirty" class="error_main_div">
                                <small *ngIf="createProfileForm.controls?.['fax']?.hasError('required')"
                                    class="error_message">This field is
                                    Mandatory !
                                </small>
                            </small>
                        </div>
                        <div class="col-md-6">
                            <div class="mt-4 pb-1">
                                <label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Address</label>
                                <input type="text" formControlName="address" (keydown)="checkEvent($event)"
                                    class="form-control fs18 cmn-input input_60 ff_Roboto"
                                    placeholder="Enter studio address">
                            </div>
                            <small *ngIf="!createProfileForm.controls?.['address']?.valid &&
                            createProfileForm.controls?.['address']?.dirty" class="error_main_div">
                                <small *ngIf="createProfileForm.controls?.['address']?.hasError('required')"
                                    class="error_message">This field is
                                    Mandatory !
                                </small>
                            </small>
                        </div>
                        <div class="col-md-6">
                            <div class="mt-4 pb-1">
                                <label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Country</label>
                                <input type="text" formControlName="country" (keydown)="checkEvent($event)"
                                    class="form-control fs18 cmn-input input_60 ff_Roboto" placeholder="Enter Country">
                            </div>
                            <small *ngIf="!createProfileForm.controls?.['country']?.valid &&
                            createProfileForm.controls?.['country']?.dirty" class="error_main_div">
                                <small *ngIf="createProfileForm.controls?.['country']?.hasError('required')"
                                    class="error_message">This field is
                                    Mandatory !
                                </small>
                            </small>
                        </div>

                        <div class="col-md-6">
                            <div class="mt-4 pb-1">
                                <label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">State</label>
                                <input type="text" formControlName="state" (keydown)="checkEvent($event)"
                                    class="form-control fs18 cmn-input input_60 ff_Roboto" placeholder="Enter State">
                            </div>
                            <small *ngIf="!createProfileForm.controls?.['state']?.valid &&
                            createProfileForm.controls?.['state']?.dirty" class="error_main_div">
                                <small *ngIf="createProfileForm.controls?.['state']?.hasError('required')"
                                    class="error_message">This field is
                                    Mandatory !
                                </small>
                            </small>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="mt-4 pb-1">
                                        <label
                                            class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">City</label>
                                        <input type="text" formControlName="city"
                                            (keydown)="checkEvent($event)"
                                            class="form-control fs18 cmn-input input_60 ff_Roboto"
                                            placeholder="Enter City">
                                    </div>
                                    <small *ngIf="!createProfileForm.controls?.['city']?.valid &&
                                    createProfileForm.controls?.['city']?.dirty" class="error_main_div">
                                        <small *ngIf="createProfileForm.controls?.['city']?.hasError('required')"
                                            class="error_message">This field is
                                            Mandatory !
                                        </small>
                                    </small>
                                </div>
                                <div class="col-md-6">
                                    <div class="mt-4 pb-1">
                                        <label
                                            class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Zip</label>
                                        <input type="number" formControlName="zipcode"
                                            (keydown)="checkEvent($event)"
                                            class="form-control fs18 cmn-input input_60 ff_Roboto"
                                            placeholder="Enter Zip">
                                    </div>
                                    <small *ngIf="!createProfileForm.controls?.['zipcode']?.valid &&
                                    createProfileForm.controls?.['zip']?.dirty" class="error_main_div">
                                        <small *ngIf="createProfileForm.controls?.['zipcode']?.hasError('required')"
                                            class="error_message">This field is
                                            Mandatory !
                                        </small>
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center mt-5">
                        <button mat-button matStepperNext
                            class="purple_btn btn70 fw-bold d-flex align-items-center justify-content-center btn px-5">Next</button>
                    </div>
                    <!-- </form> -->
                </mat-step>
                <mat-step>
                    <ng-template matStepLabel>Upload File</ng-template>
                    <div class="row mt-4">
                        <div class="col-md-6">
                            <div class="inner-sec inner-block">
                                <div class="licence-sec">
                                    <div
                                        class="img-wrap d-flex align-items-center justify-content-center flex-column position-relative">
                                        <img *ngIf="logoData" class="fit-img" [src]="logoData">

                                        <img *ngIf="!logoData" class="img-fluid" src="assets/images/uploadicn.svg">
                                        <p class="fs18 text-center mb-0 mt-3 pt-1" *ngIf="!logoData">Upload Studio Logo
                                        </p>
                                        <input type="file" formControlName="logo" class="upload_input"
                                            (change)="onFileChange($event,'logo')" autofocus="false">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="inner-sec inner-block">
                                <div class="licence-sec">
                                    <div
                                        class="img-wrap d-flex align-items-center justify-content-center flex-column position-relative">
                                        <!-- <img class="img-fluid" [src]="licence==''?'../assets/images/uploadicn.svg':licence"> -->
                                        <img *ngIf="licenceData" class="fit-img" [src]="licenceData">

                                        <img *ngIf="!licenceData" class="img-fluid" src="assets/images/uploadicn.svg">
                                        <!-- <img class="img-fluid" src="assets/images/uploadicn.svg"> -->
                                        <p class="fs18 text-center mb-0 mt-3 pt-1" *ngIf="!licenceData">Upload Photo ID/ Driving License
                                        </p>
                                        <input type="file" formControlName="licence" class="upload_input"
                                            (change)="onFileChange($event,'licence')" autofocus="false">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex justify-content-center">
                        <!-- href="http://designer.harishparas.com/vhhProduction/#/home" -->
                        <button target='_blank' mat-button type="submit"
                            class="purple_btn btn70 fw-bold d-flex align-items-center justify-content-center btn px-5 mt-5"
                            (click)="submitProfileData()" [disabled]="!createProfileForm.valid">Submit</button>
                    </div>

                </mat-step>
            </form>
        </mat-stepper>


        <!-- <form>
        <div class="row">
            <div class="col-lg-5">
                <div class="inner-sec pe-lg-5 pe-0">
                    <div class="logo-sec text-center">
                        <div class="img-wrap mx-auto d-flex align-items-center justify-content-center">
                            <img class="img-fluid" src="assets/images/dummyimg.svg">
                        </div>
                        <p class="mt-4 upload-sec fs18 mb-0">
                            <span class="position-relative d-inline-block">
                                Upload Studio Logo
                                <input type="file" class="upload_input">
                            </span>
                        </p>
                    </div>
                    <div class="licence-sec">
                        <div class="img-wrap d-flex align-items-center justify-content-center flex-column position-relative">
                            <img class="img-fluid" src="assets/images/uploadicn.svg">
                            <p class="fs18 text-center mb-0 mt-3 pt-1">Upload Licence</p>
                            <input type="file" class="upload_input">
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-7">
                <div class="inner-sec ">
                    <div class="mb-4 pb-1">
                        <label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Studio Name</label>
                        <input type="text" class="form-control fs18 cmn-input input_60 ff_Roboto" placeholder="Enter studio name">
                     </div>
                     <div class="mb-4 pb-1">
                       <label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Email Address</label>
                       <input type="email" class="form-control fs18 cmn-input input_60 ff_Roboto" placeholder="example@gmail.com">
                     </div>
            
                     <div class="mb-4 pb-1">
                       <label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Phone Number</label>
                       <input type="tel" class="form-control fs18 cmn-input input_60 ff_Roboto" placeholder="Enter phone number">
                     </div>

                     <div class="mb-4 pb-1">
                        <label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Password</label>
                        <input type="password" class="form-control fs18 cmn-input input_60 ff_Roboto" placeholder="Enter phone number">
                      </div>
            
                     <div class="mb-5">
                        <label class="form-label fs18 mb-2 pb-1 text-white fw-normal ff_Roboto">Confirm Password</label>
                        <input type="password" class="form-control fs18 cmn-input input_60 ff_Roboto" placeholder="Enter confirm password">
                    </div>
            
                     <a class="btn w-100 purple_btn btn70 fw-bold d-flex align-items-center justify-content-center" target="_blank" href="http://designer.harishparas.com/vhhProduction">Save</a>
                </div>
            </div>
        </div>
       </form> -->
    </div>
</section>