import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/common/api-service/api.service';
import { MessagehandlingService } from 'src/app/common/message/messagehandling.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
resetForm:FormGroup
constructor(private formBuilder: FormBuilder, private router: Router, private api: ApiService, private message: MessagehandlingService) {

  this.resetForm = this.formBuilder.group({
    password: ['', [Validators.required]],
    confirmPassword: [''],

  }, { validator: this.checkPasswords })

}

  ngOnInit(): void {
  }

  
  checkEvent(event:any){
    if(event.target.value=='' && event.which==32){
      event.preventDefault();
    }
  }

  checkPasswords(control: FormControl) {
    const pass = control.get('password')?.value
    const confirmPass = control.get('confirmPassword')?.value
    if (pass != confirmPass) {
      control.get('confirmPassword')?.setErrors({ ConfirmPassword: true });
    } else {
      return null;
    }
    return
  }

  reset(){
    let fd =new FormData
    fd.append('password',this.resetForm.value.password)
    this.api.resetPassword(fd).subscribe((res:any)=>{
      if(res)
      this.message.sucessMessage(res.message,2000)
    this.router.navigate(['/studio-login'])

    })
    // [routerLink]="['/studio-home']"
    // this.router.navigate(['/studio-login'])
  }


}
