<!-- Modal -->
<div mat-dialog-content>
    <div class="close_video cursor_pointer mb-3 text-end" (click)="closeDialog()">
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
            <g>
            <g clip-path="url(#clip0_383_7647)">
            <path d="M14.5622 12.5687L24.4059 2.72492C24.6271 2.4666 24.7427 2.13432 24.7296 1.79448C24.7165 1.45464 24.5756 1.13227 24.3351 0.891788C24.0946 0.651305 23.7723 0.510424 23.4324 0.497297C23.0926 0.484171 22.7603 0.599766 22.502 0.820982L12.6582 10.6647L2.81448 0.807479C2.55616 0.586263 2.22388 0.470669 1.88404 0.483795C1.5442 0.496922 1.22183 0.637802 0.98135 0.878285C0.740868 1.11877 0.599986 1.44114 0.58686 1.78098C0.573733 2.12082 0.689328 2.4531 0.910544 2.71141L10.7543 12.5687L0.897041 22.4124C0.755688 22.5335 0.640885 22.6824 0.559835 22.85C0.478785 23.0175 0.433238 23.2 0.426055 23.3859C0.418872 23.5719 0.450207 23.7573 0.518093 23.9306C0.58598 24.1039 0.688952 24.2613 0.820546 24.3928C0.95214 24.5244 1.10951 24.6274 1.28279 24.6953C1.45607 24.7632 1.64151 24.7945 1.82748 24.7873C2.01344 24.7802 2.19591 24.7346 2.36344 24.6536C2.53096 24.5725 2.67992 24.4577 2.80098 24.3164L12.6582 14.4726L22.502 24.3164C22.7603 24.5376 23.0926 24.6532 23.4324 24.64C23.7723 24.6269 24.0946 24.486 24.3351 24.2455C24.5756 24.0051 24.7165 23.6827 24.7296 23.3429C24.7427 23.003 24.6271 22.6707 24.4059 22.4124L14.5622 12.5687Z" fill="white"/>
            </g>
            </g>
            <defs>
            <clipPath id="clip0_383_7647">
            <rect width="25" height="25" fill="white" transform="translate(0.000244141)"/>
            </clipPath>
            </defs>
            </svg>
    </div>
<!-- 16:9 aspect ratio -->
<div class="embed-responsive embed-responsive-4by3">
    <ngx-video-list-player [config]="config"></ngx-video-list-player>
</div>

  </div>
