import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/common/api-service/api.service';

@Component({
  selector: 'app-purchased-token-history',
  templateUrl: './purchased-token-history.component.html',
  styleUrls: ['./purchased-token-history.component.scss']
})
export class PurchasedTokenHistoryComponent implements OnInit {
  tokenDetail: any = []
  constructor(private api: ApiService) { }

  ngOnInit(): void {
    this.purchasedTokenList()
  }

  purchasedTokenList() {
    let fd = new FormData
    fd.append('pageNo', '0')
    this.api.purchaseToken(fd).subscribe((res: any) => {
      this.tokenDetail = res.data
    })
  }

}
